import React from "react";
import StackedCards from "../StackedCards";
import { RightArrowIcon } from "@/assets/Icons";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { fallbackImage } from "@/utils/fallbackImage";
import { IVehicleOrder } from "@/DmxPages/StoreListing/DeliveryApproval";
import { cn } from "@/lib/utils";
import Image from "../ui/image";

interface VehiclePendingActionCardProps {
  showTwoCards: boolean;
  heading: React.ReactNode;
  showImagePendingNotification?: boolean;
  data: IVehicleOrder[];
  handleCardClick?: (item: any) => void;
  onCTAClick: () => void;
  CTA: React.ReactNode;
}

const VehiclePendingActionCard = ({
  heading,
  showImagePendingNotification = false,
  data,
  showTwoCards = false,
  handleCardClick,
  onCTAClick,
  CTA,
}: VehiclePendingActionCardProps) => {
  const { isMobile } = useScreenDetector();

  const cardWidthMapping = {
    1: "w-[25rem]",
    2: "w-[40rem]",
  };

  const modifyWidth =
    (!isMobile &&
      cardWidthMapping[data?.length as keyof typeof cardWidthMapping]) ||
    "";

  const numberOfCardsToShow = () => {
    if (showTwoCards) {
      return 2;
    } else {
      return 3;
    }
  };

  const renderDesktopView = () => {
    return (
      <div className="flex gap-2 w-full my-3">
        {data?.slice(0, numberOfCardsToShow()).map((item) => (
          <div
            className="flex items-center gap-2 w-full py-3 px-3 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.1),_0_1px_5px_rgba(0,0,0,0.1)] select-none"
            key={item?.vehicleId}
            onClick={() => handleCardClick && handleCardClick(item)}
          >
            {!showImagePendingNotification && (
              <Image
                src={item?.imagePaths[0] ?? fallbackImage(item?.vehicleType)}
                className="w-[4.5rem] h-[3rem] object-cover rounded-md"
                transform="f-webp"
                alt={`${item?.vehicleName} cover`}
              />
            )}

            <div className="flex flex-col gap-y-1 w-full">
              <p className="text-base font-medium text-[#2F2F2F] line-clamp-1">
                {item?.vehicleName}
              </p>
              <p className="text-xs text-neutral300 line-clamp-1">
                {item?.registrationNumber}
                {item?.kmDriven?.displayValue &&
                  `•${item?.kmDriven?.displayValue} KMs`}
                {item?.manufactureYear && `•${item?.manufactureYear}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={cn(
        "bg-white rounded-lg p-4 border border-neutralsurface100 mb-4",
        modifyWidth,
      )}
    >
      {heading}
      {isMobile ? (
        <StackedCards
          data={data}
          onCTAClick={onCTAClick}
          showImagePendingNotification={showImagePendingNotification}
        />
      ) : (
        renderDesktopView()
      )}

      <div
        className="flex items-center justify-center gap-2 cursor-pointer"
        onClick={onCTAClick}
      >
        {CTA}
        <RightArrowIcon width={6} height={11} fill="#E63C32" />
      </div>
    </div>
  );
};

export default VehiclePendingActionCard;
