import { PhoneIcon } from "@/assets/Icons";
import { Button } from "@/components/ui/button";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { DMX_CUSTOMER_SUPPORT_NUMBER } from "@/utils/constants";

const DealerNotFoundComponent = ({ userDetails }: { userDetails: string }) => {
  const { isDesktop } = useScreenDetector();

  return (
    <div>
      <div className="max-w-md p-4 rounded-2xl border border-solid bg-gradient-to-t from-[rgba(255,201,0,0)] via-[rgba(255,201,0,0)_40.09%] to-[rgba(236,88,44,0.25)] shadow-sm text-base mb-[130px]  md:mb-0 mt-5">
        <div className="flex gap-3 flex-col">
          <div className="w-8 h-8 bg-white rounded-full flex items-center justify-center">
            <PhoneIcon />
          </div>
          <p className="text-gray-900 text-base">
            The phone number{" "}
            <span className="font-medium">+91 {userDetails}</span> is not
            registered onto DMX. Please contact our support team to get access.
          </p>
          {isDesktop && (
            <p className="pb-3">
              Contact us on{" "}
              <span className="bg-gradient-to-r from-[#E63C32] to-[#F47823] bg-clip-text text-transparent">
                +91 {DMX_CUSTOMER_SUPPORT_NUMBER.slice(0, 5)}{" "}
                {DMX_CUSTOMER_SUPPORT_NUMBER.slice(5)}
              </span>{" "}
              to get registered
            </p>
          )}
        </div>
      </div>
      {!isDesktop && (
        <Button
          variant="default"
          className="w-full py-6 text-lg font-semibold h-12 bg-gradient-to-r from-red-500 to-orange-400 hover:bg-gradient-to-r hover:from-red-600 hover:to-orange-500 text-white"
          onClick={() =>
            (window.location.href = `tel:+91${DMX_CUSTOMER_SUPPORT_NUMBER}`)
          }
        >
          <div className="flex items-center gap-2">
            Call Support{" "}
            <PhoneIcon
              oneStopColor="#FFFFFF"
              twoStopColor="#FFFFFF"
              url="call_support_paint"
            />
          </div>
        </Button>
      )}
    </div>
  );
};

export default DealerNotFoundComponent;
