export const dmxWalkThroughDataFlow = [
  {
    heading: "We have changed for good",
    description: "Easier. Friendly. Welcoming",
    image:
      "https://ik.imagekit.io/drivex/DMX/ik_dmx_landingpage/walk_through_slide1.jpg",
  },
  {
    heading: "DMS comes to mobile",
    description: "Take it in your pocket",
    image:
      "https://ik.imagekit.io/drivex/DMX/ik_dmx_landingpage/walk_through_slide2.jpg",
  },
  {
    heading: "Selling and booking becomes easier",
    description: "Sell it in an instant",
    image:
      "https://ik.imagekit.io/drivex/DMX/ik_dmx_landingpage/walk_through_slide3.jpg",
  },
  {
    heading: "Manage your inventory on the go",
    description: "Check your stock anywhere, everywhere",
    image:
      "https://ik.imagekit.io/drivex/DMX/ik_dmx_landingpage/walk_through_slide4.jpg",
  },
];
