import CustomerDetail from "./SellReserveFlowFormSteps/CustomerDetail";
import Pricing from "./SellReserveFlowFormSteps/Pricing";
import Payment from "./SellReserveFlowFormSteps/Payment";
import Documents from "./SellReserveFlowFormSteps/Documents";

const useSellReserveFlowSteps = ({
  type,
  currentStep,
  dynamicFields,
  renderCustomComponent,
  formData,
  errors,
  handleInputChange,
  getDiscount,
  partnerType,
  orderDetails,
  handleDownload,
  productDetail,
  isLoading,
  otp,
  setOtp,
  modelName,
  commonEventProperties,
  finalSellingPrice,
  AmountToBeCollected,
  setIsOtpVerified,
  isOtpVerified,
  getAllPaymentFields,
  lastPaymentField,
  handleDelete,
  validateFields,
}: any) => {
  const amountData = [
    {
      label: "Selling Price",
      value: Number(formData?.selling_price?.replace(/,/g, "")) || 0,
    },
    { label: "Hypothecation", value: formData?.hypothecation },
    { label: "Notary", value: formData?.notary },
    { label: "Transit", value: formData?.transit },
    { label: "Booking amount", value: formData?.booking_amount },
    {
      label: "Discount",
      value: formData?.discount,
    },
  ];

  const commonProps = {
    dynamicFields,
    formData,
    errors,
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case "customer_details":
        return (
          <CustomerDetail
            renderCustomComponent={renderCustomComponent}
            handleInputChange={handleInputChange}
            partnerType={partnerType}
            orderDetails={orderDetails}
            otp={otp}
            setOtp={setOtp}
            modelName={modelName}
            commonEventProperties={commonEventProperties}
            setIsOtpVerified={setIsOtpVerified}
            isOtpVerified={isOtpVerified}
            validateFields={validateFields}
            {...commonProps}
          />
        );
      case "pricing":
        return (
          <Pricing
            handleInputChange={handleInputChange}
            partnerType={partnerType}
            finalSellingPrice={finalSellingPrice}
            AmountToBeCollected={AmountToBeCollected}
            orderDetails={orderDetails}
            getDiscount={getDiscount}
            productDetail={productDetail}
            {...commonProps}
          />
        );
      case "payment":
        return (
          <Payment
            type={type}
            currentStep={currentStep}
            renderCustomComponent={renderCustomComponent}
            finalSellingPrice={finalSellingPrice}
            amountData={amountData}
            getAllPaymentFields={getAllPaymentFields}
            lastPaymentField={lastPaymentField}
            handleDelete={handleDelete}
            {...commonProps}
          />
        );
      case "documents":
        return (
          <Documents
            orderDetails={orderDetails}
            handleDownload={handleDownload}
            productDetail={productDetail}
            isLoading={isLoading}
            finalSellingPrice={finalSellingPrice}
            amountData={amountData}
            formData={formData}
          />
        );
      default:
        return (
          <CustomerDetail
            renderCustomComponent={renderCustomComponent}
            handleInputChange={handleInputChange}
            partnerType={partnerType}
            orderDetails={orderDetails}
            otp={otp}
            setOtp={setOtp}
            modelName={modelName}
            commonEventProperties={commonEventProperties}
            setIsOtpVerified={setIsOtpVerified}
            {...commonProps}
          />
        );
    }
  };

  return {
    renderCurrentStep,
  };
};

export default useSellReserveFlowSteps;
