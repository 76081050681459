import { useEditVehicleDetailsMutation } from "@/store/services/Dmx/storeVehicles";
import { useEffect, useState } from "react";
import { useToast } from "../ui/use-toast";
import { DX_EDIT_DETAILS } from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import { track } from "@/utils/mixpanel/actions";
import { useSelector } from "react-redux";

export const editCOCOVehicleFormFields = [
  {
    name: "sellingPrice",
    label: "Selling Price",
    type: "number",
    placeholder: "Enter Selling Price",
    required: true,
    disabled: "coco",
  },
  {
    name: "kmDriven",
    label: "KMs Driven",
    type: "number",
    placeholder: "Enter KMs Driven",
    required: true,
  },
];

export const editFOFOVehicleFormFields = [
  {
    name: "purchasePrice",
    label: "Purchase Price",
    type: "number",
    placeholder: "Enter Purchase Price",
    required: true,
  },
  {
    name: "sellingPrice",
    label: "Selling Price",
    type: "number",
    placeholder: "Enter Selling Price",
    required: true,
  },
  {
    name: "refurbishmentCost",
    label: "Refurbishment Cost",
    type: "number",
    placeholder: "Enter Refurbishment Cost",
    required: true,
  },
  {
    name: "kmDriven",
    label: "KMs Driven",
    type: "number",
    placeholder: "Enter KMs Driven",
    required: true,
  },
  {
    name: "owner",
    label: "Ownership",
    type: "number",
    required: true,
    ownership: [
      {
        owner: "1st Owner",
        value: 1,
      },
      {
        owner: "2nd Owner",
        value: 2,
      },
      {
        owner: "3rd Owner",
        value: 3,
      },
    ],
  },
];

type CocoFormState = {
  sellingPrice: string;
  kmDriven: string;
};

type FofoFormState = CocoFormState & {
  refurbishmentCost: string;
  ownership: string;
  purchasePrice: string;
};

const fofoFormState: FofoFormState = {
  purchasePrice: "",
  sellingPrice: "",
  refurbishmentCost: "",
  kmDriven: "",
  ownership: "",
};

const cocoFormState: CocoFormState = {
  sellingPrice: "",
  kmDriven: "",
};

export const useEditVehicleDetails = ({
  store_type,
  productDetail,
  isVehicleAssured,
  refetchGetStoreVehicle,
  setOpenEditVehicleModal,
}: {
  store_type: StoreType;
  productDetail: IVehicleInfo;
  isVehicleAssured: string;
  refetchGetStoreVehicle: () => void;
  setOpenEditVehicleModal: (value: boolean) => void;
}) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const role = localStorage.getItem("role") || "";

  const [editVehicleFormData, setEditVehicleFormData] = useState<
    FofoFormState | CocoFormState
  >(store_type === "fofo" ? fofoFormState : cocoFormState);

  // this effect to update form data when store_type changes
  useEffect(() => {
    setEditVehicleFormData(
      store_type === "fofo" ? fofoFormState : cocoFormState,
    );
  }, [store_type]);

  const [editVehicleDetails, { isLoading: isEditVehicleLoading }] =
    useEditVehicleDetailsMutation();
  const { toast } = useToast();

  const handleEditVehicleDetails = async () => {
    const cleanNumber = (value: string) => Number(value?.replace(/,/g, "")); // remove commas from the number

    const basePayload = {
      kms_driven: cleanNumber(editVehicleFormData.kmDriven),
      sales_price: cleanNumber(editVehicleFormData.sellingPrice),
      store_type,
    };

    const payload =
      store_type === "fofo"
        ? {
            ...basePayload,
            purchase_price: cleanNumber(
              (editVehicleFormData as FofoFormState).purchasePrice,
            ),
            refurbishment_price: cleanNumber(
              (editVehicleFormData as FofoFormState).refurbishmentCost,
            ),
            ownership: Number((editVehicleFormData as FofoFormState).ownership),
          }
        : basePayload;

    const res: any = await editVehicleDetails({
      vehicle_id: productDetail?.vehicleId,
      payload,
    });

    if (res?.data?.status === "OK") {
      track(DX_EDIT_DETAILS, {
        role: role,
        user_id: user?.id,
        vehicle_name: productDetail?.vehicleName,
        vehicle_id: productDetail?.vehicleId,
        old_sale_price: productDetail?.price?.displayValue,
        new_sale_price: editVehicleFormData?.sellingPrice,
        old_km_driven: productDetail?.kmDriven?.displayValue,
        new_km_driven: editVehicleFormData?.kmDriven,
        inventory_type: isVehicleAssured,
        store_name: productDetail?.storeName,
        ...(store_type === "fofo" && {
          refurbishment_price: (editVehicleFormData as FofoFormState)
            .refurbishmentCost,
          ownership: (editVehicleFormData as FofoFormState).ownership,
        }),
      });

      toast({
        title: "Vehicle details updated successfully",
        variant: "success",
      });
      refetchGetStoreVehicle();
    }

    if (res?.error?.data?.status === "error") {
      toast({
        title: res?.error?.data?.message,
        variant: "destructive",
      });
    }

    setOpenEditVehicleModal(false);
  };

  const handleOwnerClick = (owner: number) => {
    setEditVehicleFormData({
      ...editVehicleFormData,
      ownership: owner.toString(),
    });
  };

  return {
    handleEditVehicleDetails,
    editVehicleFormData,
    setEditVehicleFormData,
    isEditVehicleLoading,
    handleOwnerClick,
  };
};
