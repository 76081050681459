import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import React, { useState } from "react";

const VehicleAdditionalDetails = ({
  vehicleListing,
  setVehicleListing,
  setErrors,
  errors,
}: any) => {
  const [vehicleDetails, setVehicleDetails] = useState({
    engine_no: "",
    chassis_no: "",
  });

  const InvalidEngineNo = errors?.engine_no && errors?.engine_no !== "";
  const InvalidChassisNo = errors?.chassis_no && errors?.chassis_no !== "";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(e.target.value)) return;

    setErrors({ ...errors, [e.target.name]: "" });
    setVehicleDetails({ ...vehicleDetails, [e.target.name]: e.target.value });
    setVehicleListing({
      ...vehicleListing,
      steps: {
        ...vehicleListing.steps,
        vehicle_additional_details: {
          ...vehicleDetails,
          [e.target.name]: e.target.value,
        },
      },
    });
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <p className="text-sm font-semibold">
          Engine No <span className="text-red-500">*</span>
        </p>
        <Input
          name="engine_no"
          className={cn(
            "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
            InvalidEngineNo && "bg-red-100",
          )}
          placeholder="Enter Engine Number"
          maxLength={20}
          value={
            vehicleDetails.engine_no?.toUpperCase() ||
            vehicleListing?.steps?.vehicle_additional_details?.engine_no?.toUpperCase()
          }
          onChange={handleInputChange}
        />
        {InvalidEngineNo && (
          <p className="text-sm font-medium text-red-500">{errors.engine_no}</p>
        )}
      </div>

      <div className="flex flex-col gap-3">
        <p className="text-sm font-semibold">
          Chassis No <span className="text-red-500">*</span>
        </p>
        <Input
          name="chassis_no"
          className={cn(
            "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
            InvalidChassisNo && "bg-red-100",
          )}
          placeholder="Enter Chassis Number"
          maxLength={20}
          value={
            vehicleDetails.chassis_no?.toUpperCase() ||
            vehicleListing?.steps?.vehicle_additional_details?.chassis_no?.toUpperCase()
          }
          onChange={handleInputChange}
        />
        {InvalidChassisNo && (
          <p className="text-sm font-medium text-red-500">
            {errors.chassis_no}
          </p>
        )}
      </div>
    </div>
  );
};

export default VehicleAdditionalDetails;
