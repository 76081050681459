import React from "react";
import { useRoleAccess } from "@/hooks/useRoleAccess";

interface RBACProps {
  enabledFor: Array<Roles>;
  children: React.ReactNode;
}

const RBAC: React.FC<RBACProps> = ({ enabledFor, children }) => {
  const { hasAccess: checkAccess } = useRoleAccess();
  const hasAccess = checkAccess(enabledFor);

  return hasAccess ? <>{children}</> : null;
};

export default RBAC;
