import { useRoles } from "@/context/useRoleContext";

export const useRoleAccess = () => {
  const roles = useRoles();

  const hasAccess = (allowedRoles: Roles[]) => {
    return roles?.some(
      (role) => role === "SuperAdmin" || allowedRoles.includes(role as Roles),
    );
  };

  return { hasAccess };
};
