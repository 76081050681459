import React from "react";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";
import { useSearchParams } from "react-router-dom";

const formType = [
  { label: "Book Vehicle", type: "reserve" },
  { label: "Sell Vehicle", type: "sell" },
];

interface PricingFieldProps {
  data: {
    label: string;
    description?: string;
    symbol: string;
    value?: number;
  };
}

const PricingField = ({ data }: PricingFieldProps) => {
  return (
    <div className="flex justify-between items-center">
      <label className="flex flex-col text-sm font-semibold gap-0.5">
        {data.label}
        {data.description && (
          <p className="text-neutral300 text-sm">{data.description}</p>
        )}
      </label>
      <p className="font-bold">
        {data.symbol}
        {data.value?.toLocaleString("en-IN")}
      </p>
    </div>
  );
};

const Pricing = ({
  dynamicFields,
  formData,
  errors,
  handleInputChange,
  partnerType,
  finalSellingPrice,
  AmountToBeCollected,
  orderDetails,
  productDetail,
}: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const bookingAmountField = dynamicFields.filter(
    (field: { name: string }) => field.name === "booking_amount",
  )?.[0];
  const pricingFields = dynamicFields.filter(
    (field: { stepType: string; name: string }) =>
      field.name !== "booking_amount" && field.stepType === "pricing",
  );
  const type = searchParams.get("type");
  const step = searchParams.get("step");
  const constantFields = [
    {
      label: "Selling Price",
      value: orderDetails?.selling_price?.value || productDetail?.price?.value,
      symbol: "₹",
      description: "(Including GST)",
    },
    {
      label: "Booking Amount",
      value: orderDetails?.booking_amount,
      symbol: "- ₹",
    },
  ];

  return (
    <div className="my-4 flex flex-col gap-5 overflow-auto no-scrollbar">
      {orderDetails?.order_status !== "RESERVED" && (
        <div className="flex flex-col gap-4">
          <p>Select Transaction Type</p>

          <RadioGroup
            onValueChange={(value) => {
              if (type === "reserve") formData.booking_amount = undefined;

              setSearchParams({
                type: value,
                step: step || "",
              });
            }}
            defaultValue={type || ""}
            className="flex justify-between w-full gap-2"
          >
            {formType.map((info, index) => (
              <label
                key={index}
                htmlFor={info.type}
                className={cn(
                  "flex gap-1 items-center w-full border rounded-md p-3 text-sm",
                  info.type === type && "bg-[#FEF2E9]",
                )}
              >
                <RadioGroupItem
                  id={info.type}
                  value={info.type}
                  className={cn(
                    "text-primaryA1",
                    info.type === type && "border-primaryA1",
                  )}
                />
                {info.label}
              </label>
            ))}
          </RadioGroup>
        </div>
      )}

      {orderDetails?.order_status === "RESERVED" ? (
        <div className="flex flex-col gap-5">
          {constantFields.map((field, index) => (
            <PricingField key={index} data={field} />
          ))}
        </div>
      ) : (
        <PricingField data={constantFields[0]} />
      )}

      {pricingFields?.map((field: any) => {
        return (
          <div key={field.name} className="flex flex-col gap-2 relative">
            <div className="flex justify-between">
              {field.label && (
                <div className="flex justify-between items-center">
                  <label
                    htmlFor={field.name}
                    className="text-sm font-semibold flex gap-0.5"
                  >
                    {field.label}
                    {field.required && <p className="text-red-600">*</p>}
                  </label>
                </div>
              )}

              <div
                className={cn(
                  "flex items-center px-4 rounded-md bg-neutral30 border-none",
                  errors[field.name] && "bg-red-100",
                  !field?.prefill && "none p-0",
                )}
              >
                {field?.prefill && (
                  <span className="text-sm">{field.prefill}</span>
                )}
                <Input
                  className={cn(
                    "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 text-right",
                    errors[field.name] && "bg-red-100",
                  )}
                  name={field.name}
                  placeholder={field.placeholder}
                  type={field.type}
                  required={field.required}
                  value={formData[field.name as keyof typeof formData] || ""}
                  onChange={(e) => handleInputChange(e, null, "number")}
                  disabled={partnerType === field.disabled}
                  maxLength={field.maxLength}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                />
              </div>
            </div>

            {typeof errors[field.name] === "string" && (
              <span className="text-xs text-red-400">{errors[field.name]}</span>
            )}
            {field.description && (
              <p className="text-neutral300 text-sm">{field.description}</p>
            )}
          </div>
        );
      })}

      <div
        className={cn(
          "flex flex-col gap-5 py-3",
          type === "sell" && "border-t",
          type === "reserve" && "border-y",
        )}
      >
        <div className="flex justify-between items-center">
          <label htmlFor="Amount to be collected">Amount to be collected</label>
          <p className="font-bold text-lg">
            ₹
            {orderDetails?.order_status === "RESERVED"
              ? finalSellingPrice?.toLocaleString("en-IN")
              : AmountToBeCollected?.toLocaleString("en-IN")}
          </p>
        </div>

        {type === "reserve" && bookingAmountField && (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center gap-3 ">
              <label htmlFor="Booking Amount" className="text-nowrap">
                Booking Amount
              </label>
              <Input
                className={cn(
                  "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 text-right",
                  errors[bookingAmountField.name] && "bg-red-100",
                )}
                name={bookingAmountField.name}
                placeholder={bookingAmountField.placeholder}
                type={bookingAmountField.type}
                required={bookingAmountField.required}
                value={
                  formData[bookingAmountField.name as keyof typeof formData] ||
                  ""
                }
                onChange={(e) => handleInputChange(e, null, "number")}
                disabled={partnerType === bookingAmountField.disabled}
                maxLength={bookingAmountField.maxLength}
                onWheel={(e) => (e.target as HTMLElement).blur()}
              />
            </div>

            {typeof errors[bookingAmountField.name] === "string" && (
              <span className="text-xs text-right text-red-400">
                {errors[bookingAmountField.name]}
              </span>
            )}
          </div>
        )}
      </div>

      {type === "reserve" && (
        <div className="flex justify-between items-center">
          <label htmlFor="Net Due Amount">Net Due Amount</label>
          <p className="font-bold text-lg">
            ₹{finalSellingPrice?.toLocaleString("en-IN")}
          </p>
        </div>
      )}
    </div>
  );
};

export default Pricing;
