import { ArrowStraightUpRightIcon, CrossIcon } from "@/assets/Icons";
import { getOwnerShipStatus } from "@/common/product";
import { Button } from "@/components/ui/button";
import { SheetClose, SheetHeader } from "@/components/ui/sheet";
import { DO_MY_SHOOT_APP_URL } from "@/utils/constants";

const DoMyShootModal = ({
  vehicleData,
  setOpenDoMyShoot,
}: {
  vehicleData?: any;
  setOpenDoMyShoot: (open: boolean) => void;
}) => {
  const openDoMyShootApp = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (isIOS) {
      window.location.href = DO_MY_SHOOT_APP_URL.ios;
    } else {
      window.location.href = DO_MY_SHOOT_APP_URL.android;
    }
  };

  return (
    <>
      <SheetHeader>
        <div className="flex justify-between items-center border-b pb-4">
          <p className="text-base font-medium">Add Vehicle Images</p>

          <SheetClose
            onClick={() => setOpenDoMyShoot(false)}
            className="outline-none"
          >
            <CrossIcon size={18} oneStopColor="#000" twoStopColor="#000" />
          </SheetClose>
        </div>
      </SheetHeader>

      <div className="flex flex-col gap-3 py-4">
        <p className="text-lg font-semibold">
          Add the <span className="text-primaryA2">Images</span> of the
          two-wheeler
        </p>

        {vehicleData && (
          <div className="flex flex-col gap-1 bg-neutral30 rounded-lg p-3">
            <p className="text-base font-semibold">
              {vehicleData?.vehicleName}
            </p>
            <p className="text-sm font-medium text-neutral300">
              {vehicleData?.registrationNumber}•
              {vehicleData?.kmDriven?.displayValue} KMs•
              {getOwnerShipStatus(vehicleData?.ownerShip)}•
              {vehicleData?.manufactureYear}
            </p>
          </div>
        )}

        <ul className="list-decimal list-inside font-normal text-sm">
          <li>
            Open <span className="font-semibold">Do My Shoot App</span> to click
            vehicle images
          </li>
          <li>Wait for the images to be processed(1-2 days)</li>
          <li>You will get notified on DMX when images are processed</li>
          <li>
            Your Vehicle will be{" "}
            <span className="font-semibold">listed directly</span> on the
            website
          </li>
        </ul>
      </div>

      <Button
        variant="outline"
        onClick={openDoMyShootApp}
        className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-4 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
      >
        Open Do My Shoot <ArrowStraightUpRightIcon size={13} fill="#E63C32" />
      </Button>
    </>
  );
};

export default DoMyShootModal;
