import { Button } from "@/components/ui/button";
import { DialogContent } from "@/components/ui/dialog";
import Image from "@/components/ui/image";
import React from "react";
import { dmxWalkThroughDataFlow } from "./data";
import { RightArrowIcon } from "@/assets/Icons";

const DmxWalkThrough = ({
  setOpenDmxWalkThroughDialog,
}: {
  setOpenDmxWalkThroughDialog: (show: boolean) => void;
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleNext = () => {
    if (currentIndex < dmxWalkThroughDataFlow.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
      setOpenDmxWalkThroughDialog(false);
      localStorage.setItem("dmxWalkThroughCompleted", "true");
    }
  };

  return (
    <DialogContent className="flex flex-col h-full lg:max-w-4xl lg:h-fit bg-white overflow-hidden p-0">
      <div className="relative w-full h-full overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {dmxWalkThroughDataFlow.map((item, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full h-full flex flex-col justify-center items-center gap-6"
            >
              <h2 className="text-4xl font-semibold text-center w-[45%] pt-[40px] pb-[16px]">
                {item.heading}
              </h2>
              <span className="text-base font-semibold text-neutral300 text-center w-3/4">
                {item.description}
              </span>
              <div className="w-full ">
                <Image
                  src={item.image}
                  transform="f-webp,q-50"
                  alt="DMX cover"
                  className="w-full h-auto"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Button
        className="w-[100px] text-lg font-semibold bg-[linear-gradient(83.02deg,#E63C32_0%,#F47823_100%)] hover:opacity-90 text-white mt-6 mx-auto absolute bottom-10 left-[45%]"
        onClick={handleNext}
      >
        <div className="flex items-center gap-2">
          Next
          <RightArrowIcon fill="#ffffff" />
        </div>
      </Button>
    </DialogContent>
  );
};

export default DmxWalkThrough;
