import { CrossIcon, DownloadIcon, FileIcon } from "@/assets/Icons";
import { financialReportData } from "./data";

const FinancialReportsModal = ({
  setOpenFinancialReportModal,
}: {
  setOpenFinancialReportModal: (value: boolean) => void;
}) => {
  const onCorporateAffairsBtnClick = (url: string, fileName: string) => {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", fileName);
        a.click();
      });
    });
  };

  return (
    <div className="relative w-full h-full overflow-hidden p-5">
      <div className="flex justify-between items-center pb-5">
        <span className="text-base text-[#0A0A0A]">Corporate Affairs</span>
        <span
          onClick={() => setOpenFinancialReportModal(false)}
          className="cursor-pointer"
        >
          <CrossIcon oneStopColor="black" twoStopColor="black" />
        </span>
      </div>

      <div className="flex flex-col gap-2">
        <span className="text-[#0A0A0A] text-base font-semibold">
          Download our Financial Reports
        </span>
        <div className="flex flex-col">
          {financialReportData.map((value, index) => (
            <div
              key={index}
              className="flex border rounded items-center justify-between mb-5 p-3"
            >
              <div className="flex items-center gap-2">
                <FileIcon />
                <span className="text-base">{value.heading}</span>
              </div>
              <span
                className="cursor-pointer"
                onClick={() =>
                  onCorporateAffairsBtnClick(value.link, `${value.heading}.pdf`)
                }
              >
                <DownloadIcon size={23} />
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinancialReportsModal;
