import { Sheet, SheetContent } from "@/components/ui/sheet";
import VehiclePendingActionCard from "@/components/VehiclePendingActionCard";
import { pluralize } from "@/utils/pluralize";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PhotoUploadPendingListing from "./PhotoPendingVehiclesListing";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { CameraIcon } from "@/assets/Icons";
import PendingActionCardLoader from "@/components/SkeletonLoaders/PendingActionCardLoader";

const PhotoUploadPending = ({
  showTwoCards,
  imagePendingVehiclesData,
  isImagePendingVehiclesFetching,
}: {
  showTwoCards: boolean;
  imagePendingVehiclesData: any;
  isImagePendingVehiclesFetching: boolean;
}) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openPhotoPendingVehiclesListing, setOpenPhotoPendingVehiclesListing] =
    useState(false);

  const data = imagePendingVehiclesData?.vehicles;

  const handleAddImagesCTA = () => {
    setOpenPhotoPendingVehiclesListing(true);
    navigate("?list=photo-pending-vehicles");
  };

  const handleBackButtonClick = () => {
    searchParams.delete("list");
    setSearchParams(searchParams);
    setOpenPhotoPendingVehiclesListing(false);
  };

  if (!data || data?.length <= 0) return null;

  return (
    <>
      {isImagePendingVehiclesFetching ? (
        <PendingActionCardLoader />
      ) : (
        data?.length > 0 && (
          <VehiclePendingActionCard
            heading={
              isMobile ? (
                <p className="text-base font-medium">
                  {data?.length} Vehicle {pluralize(data?.length, "Image")}{" "}
                  upload pending
                </p>
              ) : (
                <div className="flex items-center gap-1 bg-[#FFDADA] px-2 py-1 rounded-sm w-fit">
                  <CameraIcon />
                  <p className="text-xs font-semibold">
                    {data?.length} {pluralize(data?.length, "Vehicle")} Photo
                    Upload Pending
                  </p>
                </div>
              )
            }
            showTwoCards={showTwoCards}
            showImagePendingNotification={true}
            data={data}
            onCTAClick={handleAddImagesCTA}
            CTA={<p className="text-primaryA2 text-sm">Add Images</p>}
          />
        )
      )}

      <Sheet
        open={openPhotoPendingVehiclesListing}
        onOpenChange={handleBackButtonClick}
      >
        <SheetContent
          side="right"
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="h-dvh w-screen overflow-y-auto p-0"
        >
          <PhotoUploadPendingListing
            imagePendingVehiclesData={data}
            onClose={handleBackButtonClick}
          />
        </SheetContent>
      </Sheet>
    </>
  );
};

export default PhotoUploadPending;
