import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = import.meta.env.VITE_HOME_URL;

export const dmxApi = createApi({
  reducerPath: "dmxApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getStoreVehicles: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/vehicles/`,
        };
      },
    }),
    getStoreVehicle: builder.query({
      query: ({ vehicleId, filter_sold_vehicle }) => {
        return {
          url: `/hawk/api/vehicles/${vehicleId}`,
          params: { filter_sold_vehicle },
        };
      },
    }),
    getStores: builder.query({
      query: () => {
        return {
          url: `/hawk/api/stores/`,
        };
      },
    }),
    getIncomingVehicles: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/incoming-vehicles/`,
        };
      },
    }),
    updateVehicleDetails: builder.mutation({
      query: ({ vehicle_id, data }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getPreSignedURL: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/hawk/api/media/pre-signed-url/`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateOrders: builder.mutation({
      query: ({ store_id, data }) => {
        return {
          url: `/hawk/api/stores/${store_id}/orders/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getSoldVehicles: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/orders/`,
        };
      },
    }),
    getVehicleOrderDetails: builder.query({
      query: ({ vehicle_id }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/orders/`,
          method: "GET",
        };
      },
    }),
    cancelReservation: builder.mutation({
      query: ({ vehicle_id, status }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/orders/`,
          method: "PUT",
          body: { status },
        };
      },
    }),
    editVehicleDetails: builder.mutation({
      query: ({ vehicle_id, payload }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    getQRCodeUrl: builder.mutation({
      query: ({ vehicle_id }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/get-qr-code/`,
          method: "POST",
        };
      },
    }),
    getFofoVehicleInfo: builder.query({
      query: ({ registration_number, store_id }) => {
        return {
          url: `/hawk/api/vehicle-registration-info/${registration_number}?partner_id=${store_id}`,
          method: "GET",
        };
      },
    }),
    getServicePartsAuthToken: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/auth-token/`,
          method: "GET",
        };
      },
    }),
    submitFOFOVehicle: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/hawk/api/vehicle-registration-info/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getDresmaImageStatus: builder.query({
      query: ({ registration_number, partner_id, vehicle_id }) => {
        return {
          url: `/hawk/api/vehicle-image-status/${vehicle_id}/`,
          params: { registration_number, partner_id },
        };
      },
    }),
    getImagePendingVehicles: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/pending-images-vehicles/`,
        };
      },
    }),
    getColorVariants: builder.query({
      query: () => {
        return {
          url: `/hawk/api/vehicles/colors-metadata/`,
        };
      },
    }),
    getAllBrands: builder.query({
      query: () => {
        return {
          url: `/hawk/api/vehicles/brands-metadata/`,
        };
      },
    }),
    getModelAndVariant: builder.query({
      query: ({ brandName }) => {
        return {
          url: `/hawk/api/vehicles/brands-metadata/${brandName}/variants-metadata/`,
        };
      },
    }),
  }),
});

export const {
  useGetStoreVehiclesQuery,
  useGetStoresQuery,
  useGetStoreVehicleQuery,
  useGetIncomingVehiclesQuery,
  useUpdateVehicleDetailsMutation,
  useGetPreSignedURLMutation,
  useUpdateOrdersMutation,
  useGetVehicleOrderDetailsQuery,
  useEditVehicleDetailsMutation,
  useGetSoldVehiclesQuery,
  useCancelReservationMutation,
  useGetQRCodeUrlMutation,
  useLazyGetFofoVehicleInfoQuery,
  useSubmitFOFOVehicleMutation,
  useLazyGetServicePartsAuthTokenQuery,
  useLazyGetDresmaImageStatusQuery,
  useGetImagePendingVehiclesQuery,
  useLazyGetColorVariantsQuery,
  useLazyGetAllBrandsQuery,
  useLazyGetModelAndVariantQuery,
} = dmxApi;
