import { DestructiveToast } from "@/assets/Icons";
import { Button } from "@/components/ui/button";

const ImagePending = ({
  handleDoMyShootClick,
}: {
  handleDoMyShootClick: () => void;
}) => {
  return (
    <div className="flex flex-col gap-2 p-4 bg-[#FDECEB] rounded-md md:w-[25rem] mx-4 pt-2">
      <div className="flex gap-1 items-center">
        <DestructiveToast />
        <p className="text-red600 text-base font-semibold">
          Vehicle not listed on the website
        </p>
      </div>
      <p className="text-sm font-base">
        Please click vehicle images on Do My Shoot App to list the vehicle on
        the website. Images will be processed within 1-2 days of submission
      </p>

      <Button
        onClick={handleDoMyShootClick}
        variant="outline"
        className="flex-center items-center gap-2 bg-primaryA2 text-white text-sm w-full py-5 hover:bg-primaryA2 hover:text-white"
      >
        Add Vehicle Images
      </Button>
    </div>
  );
};

export default ImagePending;
