import Image from "@/components/ui/image.tsx";
import { Dialog } from "@/components/ui/dialog";
import ImageViewer from "@/pages/ProductDetail/ImageViewer";
import CarouselSection from "@/pages/ProductDetail/CarouselSection";
import ProductInfo from "@/pages/ProductDetail/ProductInfo";
import { cn } from "@/lib/utils";
import { trackImageViewerOpened } from "@/pages/ProductDetail/data";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  CAROUSEL_IMAGE_UPDATE_INTERVAL,
  DISCOUNT_MAX_VALUE,
  supportContactNumber,
  AADHAAR_LENGTH,
  PAN_LENGTH,
  PAN_REGEX,
  NUMBERS_ONLY_REGEX,
  ALPHABET_REGEX,
} from "@/utils/constants";
import TopNavigation from "@/pages/ProductDetail/TopNavigation";
import ProductCardSkeletonLoader from "@/components/SkeletonLoaders/ProductCardSkeletonLoader";
import CarouselImagePreview from "@/pages/ProductDetail/CarouselImagePreview";
import { Button } from "@/components/ui/button";
import { ChevronLeft as LeftIcon, LoaderIcon } from "lucide-react";
import Sold from "./Sold";
import {
  useGetPreSignedURLMutation,
  useGetStoreVehicleQuery,
  useGetVehicleOrderDetailsQuery,
  useLazyGetDresmaImageStatusQuery,
  useUpdateOrdersMutation,
} from "@/store/services/Dmx/storeVehicles";
import { financierData, formFields, sellReserveFormSteps } from "./data";
import EditVehicleDetailsModal from "@/components/EditVehicleDetails/EditVehicleDetailsModal";
import { containsOnlyDigits } from "@/utils/numerics";
import DmxVehicleInfo from "./DmxVehicleInfo";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import UpdateVehicleDetail from "./UpdateVehicleDetail";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { handleShare } from "@/utils/share";
import { track } from "@/utils/mixpanel/actions";
import {
  DX_CUSTOMER_DETAILS_SUBMITTED,
  DX_DOCUMENT_DOWNLOADED,
  DX_DOCUMENT_SHARED,
  DX_PDP_VIEW,
} from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import Navbar from "@/components/Navbar";
import { fallbackImage } from "@/utils/fallbackImage";
import { deepCopy } from "@/utils/object";
import ProductLabel from "@/components/ProductCard/ProductLabel";
import { getLabel } from "@/utils/label";
import { getOwnerShipStatus } from "@/common/product";
import {
  editCOCOVehicleFormFields,
  editFOFOVehicleFormFields,
  useEditVehicleDetails,
} from "@/components/EditVehicleDetails/data";
import ImagePending from "../StoreListing/AddVehicleFlow/VehicleImageVerification/ImagePending";
import ImageProcessing from "../StoreListing/AddVehicleFlow/VehicleImageVerification/ImageProcessing";
import DoMyShootModal from "@/components/DoMyShootModal";
import DresmaImageStatusLoader from "@/components/SkeletonLoaders/DresmaImageStatusLoader";
import { isValidEmail } from "@/utils/validation";
import { useToast } from "@/components/ui/use-toast";

let orderData: any;
const DmxStoreDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCarouselImgPreview, setOpenCarouselImgPreview] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [openDoMyShoot, setOpenDoMyShoot] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [storeOrderDetails, setStoreOrderDetails] = useState(undefined);
  const [isCarouselAutoPlay, setIsCarouselAutoPlay] = useState(true);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<{
    [key: string]: boolean | string | { [key: string]: boolean | string };
  }>({});
  const [otp, setOtp] = useState("");
  const [openQRModal, setOpenQRModal] = useState(false);
  const [openEditVehicleModal, setOpenEditVehicleModal] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<any>(formFields);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState({
    paymentName: "",
    isLoading: false,
  });
  const [vehicleImageStatus, setVehicleImageStatus] =
    useState<IImageUploadStatus>();
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useScreenDetector();
  const params = useParams();
  const vehicleId = params.vehicleId;
  const type = searchParams.get("type");
  const step = searchParams.get("step");
  const location = useLocation();
  const { toast } = useToast();
  const isSoldPrevPage = location?.state?.isSoldVehiclesPage;
  const {
    refetch: refetchGetVehicleOrderDetail,
    data: order = {},
    isLoading: isOrderLoading,
  } = useGetVehicleOrderDetailsQuery({
    vehicle_id: vehicleId,
  });
  const [getPreSignedUrl] = useGetPreSignedURLMutation();
  const [updateOrder, { isLoading: isOrderUpdating, error: updateOrderError }] =
    useUpdateOrdersMutation();
  const [getDresmaImageStatus, { isFetching: isFetchingDresmaImageStatus }] =
    useLazyGetDresmaImageStatusQuery();
  const store: IStore = useSelector((state: any) => state.store.store);
  const user = useSelector((state: any) => state.user.currentUser);
  const orderDetails = order?.data?.order;
  const role = localStorage.getItem("role") || "";
  const {
    refetch: refetchGetStoreVehicle,
    data: product = {},
    isError,
  } = useGetStoreVehicleQuery({
    vehicleId,
    filter_sold_vehicle:
      isSoldPrevPage || orderDetails?.order_status === "COMPLETED"
        ? true
        : undefined,
  });

  const productDetail: IVehicleInfo = product.data;
  const isLoading = !productDetail && !isError;
  const isVehicleAvailable =
    productDetail?.availabilityStatus !== "unavailable";
  const isVehicleSold = productDetail?.status === "sold";

  const storeType = productDetail?.store_type;
  const isVehicleAssured = productDetail?.drivexAssured
    ? "Assured"
    : "Self_Procured";

  const {
    editVehicleFormData,
    setEditVehicleFormData,
    isEditVehicleLoading,
    handleEditVehicleDetails,
    handleOwnerClick,
  } = useEditVehicleDetails({
    store_type: storeType as StoreType,
    productDetail,
    isVehicleAssured,
    refetchGetStoreVehicle,
    setOpenEditVehicleModal,
  });

  const isOrderInReviewOrCompleted =
    (productDetail?.status === "reserved" &&
      orderDetails?.order_status === "IN_REVIEW") ||
    (productDetail?.status === "sold" &&
      orderDetails?.order_status === "COMPLETED");

  const totalAmount = Object.values(formData)
    .filter((item) => typeof item === "object" && item?.amount)
    .reduce((acc, item) => acc + parseFloat(item?.amount), 0);

  const getAdditonalChargeAmount = (type: string) => {
    return orderDetails?.additional_charges_info?.find(
      (charge: any) => charge?.reason === type,
    )?.amount;
  };

  // Check if valid images exist for the vehicle
  const imageFound = useMemo(() => {
    return (
      (productDetail?.image_upload_status === "Processed" ||
        !productDetail?.image_upload_status) &&
      Boolean(productDetail?.imagePaths?.length)
    );
  }, [productDetail?.image_upload_status, productDetail?.imagePaths]);

  useEffect(() => {
    const fetchDresmaImageStatus = async () => {
      try {
        const response = await getDresmaImageStatus({
          registration_number: productDetail?.registrationNumber,
          partner_id: store?.store_id,
          vehicle_id: vehicleId,
        });

        setVehicleImageStatus(response?.data?.message);
      } catch (error) {
        console.error("Failed to fetch dresma image status:", error);
      }
    };

    if (!imageFound && !isVehicleSold) {
      fetchDresmaImageStatus();
    }
  }, [productDetail, store?.store_id, vehicleId, imageFound]);

  const finalSellingPrice =
    (Number(formData?.selling_price?.replace(/,/g, "")) || 0) +
    Number(formData?.hypothecation ?? 0) +
    Number(formData?.notary ?? 0) +
    Number(formData?.transit ?? 0) -
    (Number(formData?.discount ?? 0) +
      ((orderDetails?.booking_amount ?? 0) ||
        Number(formData?.booking_amount ?? 0)));

  const AmountToBeCollected =
    finalSellingPrice + Number(formData?.booking_amount ?? 0);

  const remainingAmount = finalSellingPrice - totalAmount;
  const remainingBookingAmount = formData?.booking_amount - totalAmount;

  const selling_price = parseInt(
    productDetail?.price?.displayValue?.replace(/,/g, ""),
  );
  const minBookingAmount = 1500;
  const maxBookingAmount = selling_price - 1;

  const storeDetail = JSON.parse(
    localStorage.getItem("current_store_dmx") || "null",
  );

  const commonEventProperties = {
    user_id: user?.id,
    role: role,
    vehicle_name: productDetail?.vehicleName,
    vehicle_id: productDetail?.vehicleId,
    purchase_price: productDetail?.purchase_price?.displayValue,
    sale_price: productDetail?.price?.displayValue,
    km_driven: productDetail?.kmDriven?.displayValue,
    registration_number: productDetail?.registrationNumber,
    ownership: productDetail?.ownerShip,
    store_name: storeDetail?.store_name,
    store_id: storeDetail?.store_id,
    store_contact_number: storeDetail?.spoc_number,
    store_location: storeDetail?.city,
    manufacturing_year: productDetail?.manufactureYear,
    customer_name: formData?.customer_name,
    customer_phone_number: formData?.customer_phone_number,
    customer_email_id: formData?.customer_email,
  };

  useEffect(() => {
    if (productDetail) {
      track(DX_PDP_VIEW, {
        role: role,
        user_id: user?.id,
        vehicle_name: productDetail?.vehicleName,
        vehicle_id: productDetail?.vehicleId,
        purchase_price: productDetail?.purchase_price?.displayValue,
        sale_price: productDetail?.price?.displayValue,
        km_driven: productDetail?.kmDriven?.displayValue,
        registration_number: productDetail?.registrationNumber,
        ownership: productDetail?.ownerShip,
        manufacturing_year: productDetail?.manufactureYear,
        inventory_type: isVehicleAssured,
        store_name: productDetail?.storeName,
      });
    }

    setEditVehicleFormData({
      sellingPrice: productDetail?.price?.displayValue || "",
      kmDriven: productDetail?.kmDriven?.displayValue || "",
      ...(storeType === "fofo" && {
        ownership: productDetail?.ownerShip,
        purchasePrice: productDetail?.purchase_price?.displayValue || "",
        ...(!productDetail?.drivexAssured && {
          refurbishmentCost: productDetail?.refurbishment_price?.displayValue,
        }),
      }),
    });
  }, [productDetail]);

  useEffect(() => {
    setFormData({
      ...formData,
      selling_price: productDetail?.price?.displayValue,
      booking_amount: orderDetails?.booking_amount,
      discount: orderDetails?.discount_amount,
      hypothecation: getAdditonalChargeAmount("HYPOTHECATION"),
      notary: getAdditonalChargeAmount("NOTARY"),
      transit: getAdditonalChargeAmount("TRANSIT"),
      customer_name: orderDetails?.customer_name,
      customer_email: orderDetails?.customer_email_id,
      customer_pan_or_aadhaar:
        orderDetails?.customer_aadhaar || orderDetails?.customer_pan,
      customer_address: orderDetails?.address,
      customer_phone_number: orderDetails?.customer_phone_number,
    });
  }, [productDetail?.price, productDetail?.imagePaths, orderDetails]);

  useEffect(() => {
    const fetchUpdatedOrder = async () => {
      const payload: any = {
        user_id: parseInt(user?.id),
        vehicle_id: parseInt(vehicleId || ""),
        store_id: parseInt(store?.store_id.toString()),
        sales_price:
          parseInt(formData?.selling_price?.replace(/,/g, "")) ??
          orderDetails?.price?.value,
        customer_name: formData?.customer_name ?? orderDetails?.customer_name,
        customer_email_id:
          formData?.customer_email ?? orderDetails?.customer_email_id,
        customer_address: formData?.customer_address ?? orderDetails?.address,
        discount_amount: Number(formData?.discount) || 0,
        payments: getFormDataPaymentEntries(),
        additional_charges: getAdditionalChargeEntires(),
        customer_phone_number:
          formData?.customer_phone_number ??
          orderDetails?.customer_phone_number,
        is_booking_order: type === "reserve" ? true : false,
      };

      if (
        containsOnlyDigits(
          formData?.customer_pan_or_aadhaar ||
            orderDetails?.customer_aadhaar ||
            orderDetails?.customer_pan,
        )
      ) {
        payload.customer_aadhaar =
          formData?.customer_pan_or_aadhaar ?? orderDetails?.customer_aadhaar;
      } else {
        payload.customer_pan =
          formData?.customer_pan_or_aadhaar ?? orderDetails?.customer_pan;
      }

      try {
        const data: any = await updateOrder({
          store_id: store?.store_id,
          data: payload,
        });

        if (data?.error || updateOrderError) {
          setSearchParams({ type: type || "", step: "error" });
          return;
        }

        if (data) {
          orderData = data?.data?.data?.order;
          setStoreOrderDetails(orderData);
        }
      } catch (err) {
        console.log(err);
        setSearchParams({ type: type || "", step: "error" });
      }
    };

    if (
      step === "documents" &&
      Object.keys(formData).some((key) => key.startsWith("payment "))
    ) {
      // note: orderData is the orderDetail from POST api call
      // orderDetails is the orderDetail from GET api call

      orderData = orderData || orderDetails;

      if (type === "reserve" && orderData?.order_status === "RESERVED") {
        return;
      }

      if (
        type === "sell" &&
        ["IN_REVIEW", "COMPLETED"].includes(orderData?.order_status)
      ) {
        return;
      }

      fetchUpdatedOrder();
    }
  }, [step]);

  const getAdditionalChargeEntires = () => {
    const additonalCharges = [];

    if (formData?.hypothecation || getAdditonalChargeAmount("HYPOTHECATION")) {
      additonalCharges.push({
        reason: "HYPOTHECATION",
        amount:
          formData?.hypothecation || getAdditonalChargeAmount("HYPOTHECATION"),
      });
    }

    if (formData?.transit || getAdditonalChargeAmount("TRANSIT")) {
      additonalCharges.push({
        reason: "TRANSIT",
        amount: formData?.transit || getAdditonalChargeAmount("TRANSIT"),
      });
    }

    if (formData?.notary || getAdditonalChargeAmount("NOTARY")) {
      additonalCharges.push({
        reason: "NOTARY",
        amount: formData?.notary || getAdditonalChargeAmount("NOTARY"),
      });
    }

    return additonalCharges.length === 0 ? undefined : additonalCharges;
  };

  const getFormDataPaymentEntries = () => {
    const paymentEntries = Object.keys(formData)
      .filter((key) => key.startsWith("payment "))
      .map((key) => {
        const pof_file_index =
          formData[key]?.proof_of_payment?.name?.split("_");
        const lastFileNamePart = pof_file_index?.[pof_file_index?.length - 1];

        return {
          proof_of_payment:
            formData[key]?.proof_of_payment &&
            `${productDetail?.registrationNumber}_${type === "sell" ? "REGULAR" : "ADVANCE"}_${lastFileNamePart}.${formData[key]?.proof_of_payment?.type?.split("/")?.[1]}`,
          mode_of_payment:
            formData[key]?.mode_of_payment === "finance"
              ? formData[key]?.finance_details?.toUpperCase()
              : formData[key]?.mode_of_payment?.toUpperCase(),
          payment_reference_id: formData[key]?.payment_reference_id,
          amount: parseInt(formData[key]?.amount),
          payment_type: type === "reserve" ? "ADVANCE" : "REGULAR",
        };
      });

    return paymentEntries;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isCarouselAutoPlay) {
        clearInterval(intervalId);
        return;
      }

      if (currentImageIndex + 1 >= productDetail?.imagePaths?.length) {
        setIsCarouselAutoPlay(false);
        setCurrentImageIndex(0);
        return;
      }

      const nextIndex =
        (currentImageIndex + 1) % productDetail?.imagePaths?.length;

      setCurrentImageIndex(nextIndex);
    }, CAROUSEL_IMAGE_UPDATE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, productDetail?.imagePaths?.length]);

  const invokeS3Url = async (s3Url: string, method: string, data?: any) => {
    try {
      const response = await fetch(s3Url, {
        method: method,
        body: data,
        headers: {
          "Content-Type": "binary/octet-stream",
        },
      });

      return response;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return error;
    }
  };

  const handleInputChange = async (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLInputElement
    >,
    parentField: string,
    inputType: string,
  ) => {
    const { name, value } = e.target;

    const isNumberPresent = NUMBERS_ONLY_REGEX.test(value);

    if (inputType === "number" && !isNumberPresent) return;

    const files = (e.target as HTMLInputElement)?.files;

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }

    // Manage Error handling for all Payment fields
    if (parentField && parentField.startsWith("payment ")) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [parentField]: {
          ...prevErrors[parentField],
          [name]: false,
        },
      }));
    }

    if (name === "mode_of_payment") {
      const getField = dynamicFields.find(
        (field: any) => field.name === parentField,
      );

      if (value === "finance") {
        // Extract payment_reference_id field from dynamicFields to add finance_details field after it
        const paymentReferenceIdIndex = getField.fields.findIndex(
          (field: any) => field.name === "payment_reference_id",
        );

        let updatedFinancierOptions;

        const getFinanceModeWhileBooking = orderDetails?.payments_info.find(
          (payment: { mode_of_payment: string }) =>
            payment?.mode_of_payment?.startsWith("FINANCE_"),
        )?.mode_of_payment;

        if (
          type === "sell" &&
          orderDetails?.order_status === "RESERVED" &&
          getFinanceModeWhileBooking
        ) {
          // During sell flow, Keep only the financier option that was selected during booking flow (if booking exists)

          updatedFinancierOptions = financierData.options.filter(
            (finance) =>
              finance.value === getFinanceModeWhileBooking.toLowerCase(),
          );
        } else {
          updatedFinancierOptions = financierData.options;
        }

        // Add finance_details field after payment_reference_id field
        if (paymentReferenceIdIndex !== -1) {
          getField.fields.splice(paymentReferenceIdIndex + 1, 0, {
            ...financierData,
            options: updatedFinancierOptions,
          });
        }
      } else {
        // Remove finance_details field from dynamicFields when mode of payment is not finance
        const financeIndex = getField.fields.findIndex(
          (field: any) => field.name === "finance_details",
        );

        if (financeIndex !== -1) {
          getField.fields.splice(financeIndex, 1);

          setFormData((prevData: any) => {
            const updatedPaymentData = { ...prevData[parentField] };
            delete updatedPaymentData.finance_details;
            updatedPaymentData.mode_of_payment = value;

            return {
              ...prevData,
              [parentField]: updatedPaymentData,
            };
          });
          return;
        }
      }
    }

    if (name === "discount") {
      if (parseInt(value) > DISCOUNT_MAX_VALUE + 1 && storeType === "coco") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          discount: `Discount cannot be greater than ${DISCOUNT_MAX_VALUE}.`,
        }));
      }

      if (
        parseInt(value) >
        (orderDetails?.selling_price?.value || productDetail?.price?.value)
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          discount: "Discount cannot be greater than the selling price.",
        }));
      }

      if (!isNumberPresent || value.length > 6) return;
    }

    if (
      name === "customer_phone_number" &&
      (!isNumberPresent || value.length > 10)
    )
      return;

    if (name === "customer_pan_or_aadhaar") {
      const isAadhaar = NUMBERS_ONLY_REGEX.test(value);
      if (isAadhaar && value.length > AADHAAR_LENGTH) return;
      if (!isAadhaar && value.length > PAN_LENGTH) return;

      if (!isAadhaar) {
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: value.toUpperCase(),
        }));
        return;
      }
    }

    if (parentField) {
      const modifiedFile =
        files &&
        new File(
          [files[0]],
          `${files?.[0].name}_${(parentField as string).split(" ")?.[1]}`,
          { type: files?.[0].type },
        );

      if (files) {
        const getFileNameIndex = modifiedFile?.name.split("_");
        const lastFileNamePart =
          getFileNameIndex?.[getFileNameIndex.length - 1];

        const data: any = await getPreSignedUrl({
          data: {
            file_names: [
              `${productDetail?.registrationNumber}_${type === "sell" ? "REGULAR" : "ADVANCE"}_${lastFileNamePart}.${files[0].type.split("/")[1]}`,
            ],
            doc_type: "proof_of_payment",
            meta_data: "",
          },
        });
        if (data) {
          const s3Url = data?.data?.data[0].url;
          if (s3Url) {
            setIsFileUploadLoading({
              paymentName: parentField,
              isLoading: true,
            });

            const fileResponse: any = await invokeS3Url(s3Url, "PUT", files[0]);

            setIsFileUploadLoading({
              paymentName: "",
              isLoading: false,
            });

            if (!fileResponse?.ok) {
              toast({
                title: "File upload failed, try again later",
                variant: "destructive",
              });
              return;
            }
          }
        }
      }

      setFormData((prevData: any) => ({
        ...prevData,
        [parentField]: {
          ...formData[parentField],
          [name]: modifiedFile || value,
        },
      }));
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    }
  };

  const validateFields = () => {
    const newErrors: any = {};

    const fields = deepCopy(dynamicFields);

    // Error handling for all Payment fields
    if (step === "payment") {
      Object.keys(formData).forEach((key) => {
        if (key.startsWith("payment ")) {
          const paymentField = formData[key];
          if (!paymentField) {
            newErrors[key] = {
              mode_of_payment: true,
              proof_of_payment: true,
              payment_reference_id: true,
            };
          } else {
            const modeOfPaymentError = !paymentField?.mode_of_payment;
            const proofOfPaymentError = !paymentField?.proof_of_payment;
            const paymentReferenceId = !paymentField?.payment_reference_id;
            const financierError =
              formData[key].mode_of_payment === "finance" &&
              !paymentField?.finance_details;

            newErrors[key] = {
              mode_of_payment: modeOfPaymentError,
              proof_of_payment: proofOfPaymentError,
              payment_reference_id: paymentReferenceId,
              ...(financierError && { finance_details: financierError }),
            };

            if (!modeOfPaymentError) {
              delete newErrors[key]?.mode_of_payment;
            }

            if (!proofOfPaymentError) {
              delete newErrors[key]?.proof_of_payment;
            }

            if (!paymentReferenceId) {
              delete newErrors[key]?.payment_reference_id;
            }

            if (!financierError) {
              delete newErrors[key]?.finance_details;
            }

            if (Object.keys(newErrors[key]).length === 0) {
              delete newErrors[key];
            }
          }
        }
      });
    }

    // error handling for other fields
    fields.forEach(
      (field: { required: any; name: string; stepType: string | null }) => {
        if (
          field.required &&
          !formData[field.name] &&
          field.stepType === step
        ) {
          newErrors[field.name] = true;
        }

        // Booking amount validation
        if (
          field.name === "booking_amount" &&
          Number(formData.booking_amount) > maxBookingAmount &&
          field.stepType === step
        ) {
          newErrors[field.name] =
            `Booking amount should not be greater than ${maxBookingAmount.toLocaleString("en-IN")}`;
        }

        if (
          field.name === "booking_amount" &&
          Number(formData.booking_amount) < minBookingAmount &&
          field.stepType === step
        ) {
          newErrors[field.name] =
            `Booking amount should not be less than ${minBookingAmount.toLocaleString("en-IN")}`;
        }

        if (field.name === "discount") {
          if (
            Number(formData.discount) > DISCOUNT_MAX_VALUE + 1 &&
            storeType === "coco"
          ) {
            newErrors[field.name] =
              `Discount cannot be greater than ${DISCOUNT_MAX_VALUE}.`;
          }

          if (
            Number(formData.discount) >
            (orderDetails?.selling_price?.value || productDetail?.price?.value)
          ) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              discount: "Discount cannot be greater than the selling price.",
            }));
          }

          const isNumberPresent = /^\d*$/.test(formData.discount);

          if (!isNumberPresent || formData.discount.length > 6) return;
        }
        if (
          field.name === "customer_email" &&
          formData[field.name] &&
          !isValidEmail(formData[field.name])
        ) {
          newErrors[field.name] = "Please enter a valid email address.";
        }
        // Add Aadhaar/PAN validation
        if (
          field.name === "customer_pan_or_aadhaar" &&
          formData[field.name] &&
          field.stepType === step
        ) {
          const value = formData[field.name];
          const isAadhaar = NUMBERS_ONLY_REGEX.test(value);

          if (
            (isAadhaar && value.length !== AADHAAR_LENGTH) ||
            (!isAadhaar && !PAN_REGEX.test(value))
          ) {
            newErrors[field.name] = isAadhaar
              ? `Please enter a valid ${AADHAAR_LENGTH}-digit Aadhaar number`
              : "Please enter a valid PAN in format ABCDE1234F";
          }
        }
        if (
          field.name === "customer_name" &&
          formData[field.name] &&
          !ALPHABET_REGEX.test(formData[field.name])
        ) {
          newErrors[field.name] = "Please enter a valid name.";
        }
      },
    );

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextClick = () => {
    if (validateFields()) {
      const currentIndex = sellReserveFormSteps.findIndex(
        (stepType) => stepType.type === step,
      );

      // Check if the current step is valid and get the next step index
      if (
        currentIndex !== -1 &&
        currentIndex < sellReserveFormSteps.length - 1
      ) {
        const nextStep = sellReserveFormSteps[currentIndex + 1].type;

        // Update the URL with the next step
        setSearchParams(
          { type: type === "reserve" ? "reserve" : "sell", step: nextStep },
          { preventScrollReset: true, replace: true },
        );
      }
    }

    track(DX_CUSTOMER_DETAILS_SUBMITTED, commonEventProperties);
  };

  const handleDownload = async (
    type?: string,
    share = false,
    shareMessage?: string,
    extension = "pdf",
    fullFilename = null,
  ) => {
    const events = {
      user_id: user?.id,
      role: role,
      vehicle_name: productDetail?.vehicleName,
      vehicle_id: productDetail?.vehicleId,
      store_name: productDetail?.storeName,
      type: type,
    };
    const fileName =
      fullFilename || `${type}_${orderDetails.registrationNumber}.${extension}`;
    const data: any = await getPreSignedUrl({
      data: {
        file_names: [`${fileName}`],
        doc_type: type,
        prefix: "",
        operation: "get",
      },
    });

    if (data) {
      const s3Url = data?.data?.data[0].url;

      if (s3Url) {
        const file = await invokeS3Url(s3Url, "GET");

        if (share) {
          track(DX_DOCUMENT_SHARED, events);
          handleShare({
            text: shareMessage,
            url: s3Url,
          });
          return;
        }

        track(DX_DOCUMENT_DOWNLOADED, events);
        downloadToDevice(file, fileName);
      }
    }
  };

  const downloadToDevice = async (file: any, fileName: string) => {
    const blob = await file?.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const getRemainingAmount = () => {
    if (type === "sell") {
      return remainingAmount;
    }

    return parseInt(formData?.booking_amount ?? 0) - totalAmount;
  };

  const disableNext = () => {
    if (!isOtpVerified && orderDetails?.order_status !== "RESERVED")
      return true;

    if (step === "payment") {
      if (type === "reserve") {
        return totalAmount !== Number(formData.booking_amount || 0);
      }

      if (type === "sell") {
        return totalAmount !== Number(finalSellingPrice || 0);
      }
    }
  };

  const displayRemainingAmount = () => {
    const isBookingAmountExceeded =
      type === "reserve" && totalAmount > formData?.booking_amount;
    const isTotalRemainingAmountNegative =
      type === "sell" && remainingAmount < 0;

    if (isBookingAmountExceeded || isTotalRemainingAmountNegative) {
      const excessAmount = Math.abs(
        type === "reserve" ? remainingBookingAmount : remainingAmount,
      );
      return (
        <div className="text-red600 border rounded-md border-red100 bg-[#FDECEB] px-2 py-1 font-medium text-sm">
          ₹{excessAmount.toLocaleString("en-IN")} extra
        </div>
      );
    } else {
      const remainingAmount = getRemainingAmount();
      return `₹${remainingAmount?.toLocaleString("en-IN")} remaining`;
    }
  };

  const removePaymentFronDynamicFields = () => {
    // Filter out fields starting with 'payment ' except 'payment 1'
    const newDynamicFields = dynamicFields.filter((field: { name: string }) => {
      return !(field.name.startsWith("payment ") && field.name !== "payment 1");
    });
    setDynamicFields(newDynamicFields);
  };

  const resetPaymentFields = () => {
    const newFormData = { ...formData };
    Object.keys(newFormData).forEach((key) => {
      if (key.startsWith("payment ")) {
        delete newFormData[key];
      }
    });
    setFormData(newFormData);
    removePaymentFronDynamicFields();
  };

  const handleDoMyShootClick = () => {
    setOpenDoMyShoot(true);
  };

  const renderImageStageStatus = (imageState: IImageUploadStatus) => {
    switch (imageState) {
      case "Upload Pending":
        return <ImagePending handleDoMyShootClick={handleDoMyShootClick} />;
      case "Processing":
        return <ImageProcessing />;

      default:
        return null;
    }
  };

  const renderButtons = () => {
    switch (step) {
      case "error":
        return (
          <Button
            variant="outline"
            className="bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white"
            onClick={() => {
              window.location.href = `tel:${supportContactNumber.replaceAll("-", "")}`;
              searchParams.delete("step");
              searchParams.delete("type");
              setSearchParams(searchParams);
            }}
          >
            Call
          </Button>
        );

      case "documents":
        return (
          <Button
            onClick={() => {
              resetPaymentFields();
              navigate(`/dealer/vehicles/${vehicleId}`, {
                preventScrollReset: true,
                replace: true,
              });
              refetchGetVehicleOrderDetail();
              refetchGetStoreVehicle();
            }}
            variant="outline"
            className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
          >
            Finish
          </Button>
        );

      default:
        return (
          <>
            {step === "payment" && (
              <div className="flex flex-col flex-center gap-1 p-2">
                <p className="font-semibold text-base">
                  ₹{totalAmount?.toLocaleString("en-IN")}
                  /₹
                  {type === "sell"
                    ? finalSellingPrice?.toLocaleString("en-IN")
                    : parseInt(formData?.booking_amount ?? 0)?.toLocaleString(
                        "en-IN",
                      )}
                </p>
                <p className="text-neutral300 text-xs">
                  {displayRemainingAmount()}
                </p>
              </div>
            )}

            <div className="flex w-full gap-2 items-center">
              <Button
                onClick={() => {
                  if (
                    !["RESERVED", "IN_REVIEW", "COMPLETED"].includes(
                      orderDetails?.order_status,
                    )
                  ) {
                    setFormData({
                      selling_price: productDetail?.price?.displayValue,
                    });
                  }
                  resetPaymentFields();
                  searchParams.delete("step");
                  searchParams.delete("type");
                  setSearchParams(searchParams, {
                    preventScrollReset: true,
                    replace: true,
                  });
                }}
                variant="outline"
                className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
              >
                Cancel
              </Button>

              <Button
                onClick={handleNextClick}
                variant="outline"
                disabled={disableNext()}
                className={cn(
                  "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
                  disableNext() && "bg-neutral50 text-neutral200",
                )}
              >
                Next
              </Button>
            </div>
          </>
        );
    }
  };

  return (
    <>
      {!isMobile && (
        <Navbar
          backgroundColor="bg-white"
          showLocation={false}
          showStores={true}
          showDmxLogo={true}
          showRecentSearches={false}
          showPopularSearches={false}
          showPopularBrands={false}
          showLinks={false}
          disableStore={true}
          showSearchbar={false}
        />
      )}
      <div
        className={cn(
          "max-w-7xl w-full mx-auto mb-24",
          !isMobile && "pt-[129px]",
          isDesktop && "pt-[80px]",
        )}
      >
        <Dialog open={imageFound && openCarouselImgPreview}>
          <CarouselImagePreview
            isVehicleAvailable={isVehicleAvailable}
            productDetail={productDetail}
            setOpenCarouselImgPreview={setOpenCarouselImgPreview}
            showWishList={false}
            showShare={false}
            showTestRide={false}
          />
        </Dialog>

        {isLoading && <ProductCardSkeletonLoader />}

        {isDesktop && productDetail && (
          <div className="text-neutral-900 mx-4 my-6 lg:sticky lg:top-24">
            <span className="text-primaryA1">{store?.store_name}</span> /{" "}
            {productDetail?.vehicleName}
          </div>
        )}
        {productDetail && (
          <div className="flex flex-col lg:flex-row gap-4 relative">
            <div className="flex flex-col justify-end md:flex-row-reverse md:h-[400px] lg:sticky lg:top-36">
              <span className="w-full relative md:w-[628px]">
                <TopNavigation
                  isVehicleAvailable={isVehicleAvailable}
                  productDetail={productDetail}
                  showWishList={false}
                  showShare={false}
                  customBackClick={() => {
                    if (isSoldPrevPage) {
                      navigate("/dealer/sold-vehicles");
                    } else {
                      navigate("/dealer/vehicles");
                    }
                  }}
                />
                {!isMobile && (
                  <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                    {isFetchingDresmaImageStatus ? (
                      <LoaderIcon
                        className="animate-spin"
                        color="#F47725"
                        size={40}
                      />
                    ) : (
                      renderImageStageStatus(
                        vehicleImageStatus as IImageUploadStatus,
                      )
                    )}
                  </div>
                )}

                <ProductLabel
                  label={getLabel({ status: productDetail?.status })}
                />

                <Image
                  transform="q-10"
                  src={
                    productDetail.imagePaths?.[currentImageIndex] ??
                    fallbackImage(productDetail?.vehicleType)
                  }
                  alt={productDetail.vehicleName}
                  className={cn(
                    "w-full h-80 md:h-[400px] md:w-[628px] md:rounded-md ease-linear object-cover",
                    !isVehicleAvailable && "grayscale",
                    !isMobile && !imageFound && !isVehicleSold && "blur-sm",
                  )}
                  onClick={() => {
                    if (!imageFound) return;
                    setOpenImageViewer(true);
                    trackImageViewerOpened({
                      productDetail,
                      currentImageIndex,
                    });
                  }}
                />
                {imageFound && openImageViewer && (
                  <Dialog open={openImageViewer}>
                    <ImageViewer
                      productDetail={productDetail}
                      imagePaths={productDetail.imagePaths}
                      setOpenImageViewer={setOpenImageViewer}
                      activeImage={currentImageIndex}
                    />
                  </Dialog>
                )}
              </span>

              {imageFound && (
                <CarouselSection
                  isVehicleAvailable={isVehicleAvailable}
                  imagePaths={productDetail.imagePaths}
                  setOpenCarouselImgPreview={setOpenCarouselImgPreview}
                  productDetail={productDetail}
                />
              )}
            </div>

            {isMobile &&
              (isFetchingDresmaImageStatus ? (
                <DresmaImageStatusLoader />
              ) : (
                renderImageStageStatus(vehicleImageStatus as IImageUploadStatus)
              ))}

            {openDoMyShoot && (
              <Sheet open={openDoMyShoot} onOpenChange={setOpenDoMyShoot}>
                <SheetContent
                  className="rounded-t-lg"
                  side={isMobile ? "bottom" : "right"}
                >
                  <DoMyShootModal
                    vehicleData={productDetail}
                    setOpenDoMyShoot={setOpenDoMyShoot}
                  />
                </SheetContent>
              </Sheet>
            )}

            <ProductInfo
              isVehicleAvailable={isVehicleAvailable}
              productInfo={productDetail}
              showEmi={false}
              showCallStore={false}
              showStoreName={false}
              showPrice={false}
              showDmxVehicleInfo={isDesktop && true}
              showTestRide={isDesktop && false}
              showUpdateVehicleDetail={isDesktop && !isOrderInReviewOrCompleted}
              setOpenEditVehicleModal={setOpenEditVehicleModal}
              openQRModal={openQRModal}
              setOpenQRModal={setOpenQRModal}
              handleDownload={handleDownload}
              orderDetails={orderDetails}
              refetchGetVehicleOrderDetail={refetchGetVehicleOrderDetail}
              imageFound={imageFound}
            />

            {!isDesktop && (
              <DmxVehicleInfo
                store={store}
                productDetail={productDetail}
                orderDetails={orderDetails}
                storeOrderDetails={storeOrderDetails}
                openQRModal={openQRModal}
                setOpenQRModal={setOpenQRModal}
                handleDownload={handleDownload}
                imageFound={imageFound}
              />
            )}
          </div>
        )}

        <Sheet
          open={step !== null}
          onOpenChange={() => {
            if (step) {
              searchParams.delete("step");
              searchParams.delete("sold");
              setSearchParams(searchParams, {
                preventScrollReset: true,
                replace: true,
              });
            }
          }}
        >
          <SheetContent
            className={cn("max-w-full flex flex-col h-dvh p-0")}
            side={isMobile ? "bottom" : "right"}
          >
            <SheetHeader className="bg-white border-neutral50 border-b absolute top-0 right-0 left-0 w-full py-4 px-3 z-10 max-w-7xl mx-auto">
              <div className="flex gap-2">
                <SheetClose
                  onClick={() => {
                    if (otp) {
                      setOtp("");
                    }

                    if (
                      !["RESERVED", "IN_REVIEW", "COMPLETED"].includes(
                        orderDetails?.order_status,
                      )
                    ) {
                      setFormData({
                        selling_price: productDetail?.price?.displayValue,
                      });
                    }

                    resetPaymentFields();

                    searchParams.delete("step");
                    searchParams.delete("type");
                    setSearchParams(searchParams, {
                      preventScrollReset: true,
                      replace: true,
                    });
                  }}
                >
                  <LeftIcon />
                </SheetClose>

                <div className="flex flex-col gap-2">
                  <p className="text-start">{productDetail?.vehicleName}</p>

                  <p className="text-neutral300 text-sm font-semibold flex text-start">
                    {productDetail?.kmDriven?.displayValue}{" "}
                    {productDetail?.kmDriven?.unit}s •{" "}
                    {getOwnerShipStatus(productDetail?.ownerShip)}•{" "}
                    {productDetail?.manufactureYear} •{" "}
                    {productDetail?.registrationNumber}
                  </p>
                </div>
              </div>
            </SheetHeader>

            <Sold
              formData={formData}
              errors={errors}
              handleInputChange={handleInputChange}
              partnerType={store?.partner_type}
              setFormData={setFormData}
              orderDetails={storeOrderDetails ?? orderDetails}
              handleDownload={handleDownload}
              productDetail={productDetail}
              isLoading={isOrderLoading || isOrderUpdating}
              otp={otp}
              setOtp={setOtp}
              modelName={productDetail?.modelName}
              commonEventProperties={commonEventProperties}
              finalSellingPrice={finalSellingPrice}
              AmountToBeCollected={AmountToBeCollected}
              dynamicFields={dynamicFields}
              setDynamicFields={setDynamicFields}
              setIsOtpVerified={setIsOtpVerified}
              isOtpVerified={isOtpVerified}
              getRemainingAmount={getRemainingAmount}
              validateFields={validateFields}
              isFileUploadLoading={isFileUploadLoading}
            />

            <div
              className={cn(
                "bg-white border-t absolute bottom-0 right-0 left-0 w-full py-2 px-3 flex flex-col justify-between gap-2 items-center",
                step === "customer_details" &&
                  type === "sell" &&
                  "flex flex-col gap-2",
              )}
            >
              {renderButtons()}
            </div>
          </SheetContent>
        </Sheet>

        {productDetail && (
          <EditVehicleDetailsModal
            open={openEditVehicleModal}
            onClose={() => setOpenEditVehicleModal(false)}
            inputFields={
              storeType === "coco"
                ? editCOCOVehicleFormFields
                : productDetail?.drivexAssured
                  ? editFOFOVehicleFormFields.filter(
                      (field) => field.name !== "refurbishmentCost",
                    )
                  : editFOFOVehicleFormFields
            }
            editVehicleFormData={editVehicleFormData}
            isEditVehicleLoading={isEditVehicleLoading}
            setEditVehicleFormData={setEditVehicleFormData}
            handleEditVehicleSaveButton={handleEditVehicleDetails}
            handleOwnerClick={handleOwnerClick}
          />
        )}

        {!isDesktop && !isOrderInReviewOrCompleted && (
          <UpdateVehicleDetail
            productDetail={productDetail}
            setOpenEditVehicleModal={setOpenEditVehicleModal}
            orderStatus={orderDetails?.order_status}
            refetchGetVehicleOrderDetail={refetchGetVehicleOrderDetail}
          />
        )}
      </div>
    </>
  );
};

export default DmxStoreDetail;
