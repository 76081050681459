import { ComponentType, ReactElement, useState } from "react";
import SelectBrand from "./ManualFormSteps/SelectBrand";
import { SearchParams } from "@/pages/Sell/ManualSellFlow/data";
import SelectYear from "./ManualFormSteps/SelectYear";
import SelectModel from "./ManualFormSteps/SelectModel";
import SelectVariant from "./ManualFormSteps/SelectVariant";
import SelectKMDriven from "./ManualFormSteps/SelectKMDriven";
import SelectOwner from "./ManualFormSteps/SelectOwner";
import SelectPricing from "./ManualFormSteps/SelectPricing";
import {
  IVehicleListing,
  VehicleListingSteps,
  VehicleListingStepsType,
} from "./data";
import SelectColor from "./ManualFormSteps/SelectColor";
import SelectVehicleAdditionalDetails from "./ManualFormSteps/SelectVehicleAdditionalDetails";

type vehicleListingStepsProps = {
  vehicleListing: IVehicleListing;
  setVehicleListing: React.Dispatch<React.SetStateAction<IVehicleListing>>;
  setSearchParams: (params: SearchParams) => void;
  currentStep: VehicleListingSteps;
  type: VehicleListingStepsType;
};

function usePriceEstimateSteps({
  vehicleListing,
  setVehicleListing,
  setSearchParams,
  currentStep,
  type,
}: vehicleListingStepsProps) {
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [vehicleColorData, setVehicleColorData] = useState<string[]>([]);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [errors, setErrors] = useState<{
    [key: string]: boolean | string | { [key: string]: boolean | string };
  }>({});

  const CommonPropsHOC = (
    Component: ComponentType<Omit<vehicleListingStepsProps, "currentStep">>,
  ): ReactElement => (
    <Component
      setVehicleListing={setVehicleListing}
      setSearchParams={setSearchParams}
      vehicleListing={vehicleListing}
      type={type}
    />
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case "brand":
        return (
          <SelectBrand
            setVehicleListing={setVehicleListing}
            setSearchParams={setSearchParams}
            vehicleListing={vehicleListing}
            setModels={setModels}
            setVehicleColorData={setVehicleColorData}
            type={type}
          />
        );
      case "year":
        return CommonPropsHOC(SelectYear);
      case "color":
        return (
          <SelectColor
            setVehicleListing={setVehicleListing}
            vehicleColorData={vehicleColorData}
            setSearchParams={setSearchParams}
            vehicleListing={vehicleListing}
            setVariants={setVariants}
            models={models}
            type={type}
          />
        );
      case "model":
        return (
          <SelectModel
            setVehicleListing={setVehicleListing}
            setSearchParams={setSearchParams}
            vehicleListing={vehicleListing}
            setVariants={setVariants}
            models={models}
            type={type}
          />
        );
      case "variant":
        return (
          <SelectVariant
            setVehicleListing={setVehicleListing}
            setSearchParams={setSearchParams}
            vehicleListing={vehicleListing}
            variants={variants}
            type={type}
          />
        );
      case "no_of_owner":
        return CommonPropsHOC(SelectOwner);
      case "vehicle_additional_details":
        return (
          <SelectVehicleAdditionalDetails
            setVehicleListing={setVehicleListing}
            vehicleListing={vehicleListing}
            setErrors={setErrors}
            errors={errors}
          />
        );
      case "km_driven":
        return (
          <SelectKMDriven
            setVehicleListing={setVehicleListing}
            vehicleListing={vehicleListing}
            setErrors={setErrors}
            errors={errors}
          />
        );
      case "pricing":
        return (
          <SelectPricing
            setVehicleListing={setVehicleListing}
            setSearchParams={setSearchParams}
            setSubmitClicked={setSubmitClicked}
            vehicleListing={vehicleListing}
            submitClicked={submitClicked}
            setErrors={setErrors}
            errors={errors}
          />
        );
      default:
        return (
          <SelectBrand
            setVehicleListing={setVehicleListing}
            setSearchParams={setSearchParams}
            vehicleListing={vehicleListing}
            setModels={setModels}
            setVehicleColorData={setVehicleColorData}
            type={type}
          />
        );
    }
  };

  return {
    renderCurrentStep,
    setSubmitClicked,
    setErrors,
    errors,
  };
}

export default usePriceEstimateSteps;
