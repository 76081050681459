import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { LoaderIcon } from "lucide-react";
import { InputFields } from "../data";

interface IProps {
  dealersFormData: {
    name: string;
    store_location: string;
    additional_details: string;
  };
  errors: any;
  handleFormInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleSubmitButton: () => void;
  isFormButtonClicked: boolean;
}

const DealerForm = ({
  dealersFormData,
  errors,
  handleFormInputChange,
  handleSubmitButton,
  isFormButtonClicked,
}: IProps) => {
  return (
    <div className="flex flex-col gap-4 bg-white p-4 rounded-lg">
      {InputFields.map((field) => (
        <div key={field.name} className="flex flex-col gap-2">
          <label htmlFor="name" className="text-sm font-semibold">
            {field.title}{" "}
            {field.required && <span className="text-red-600">*</span>}
          </label>

          {field.inputStyle === "input" && (
            <Input
              className={cn(
                "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                isFormButtonClicked &&
                  errors[field.name as keyof typeof dealersFormData] &&
                  "bg-red-100",
              )}
              name={field.name}
              maxLength={field.maxLength}
              onChange={handleFormInputChange}
              value={
                dealersFormData[field.name as keyof typeof dealersFormData]
              }
              id={field.name}
              placeholder={field.placeholder}
              type="text"
            />
          )}

          {field.inputStyle === "textArea" && (
            <Textarea
              className="bg-neutral30 h-24 focus-visible:ring-0 focus-visible:ring-offset-0"
              name={field.name}
              onChange={handleFormInputChange}
              value={dealersFormData?.additional_details}
              placeholder={field.placeholder}
              maxLength={field.maxLength}
            />
          )}

          {isFormButtonClicked && errors[field.name] && (
            <p className="text-sm text-red-600">{errors[field.name]}</p>
          )}
        </div>
      ))}

      <Button
        variant="outline"
        className="bg-primaryA2 border-none text-white text-base flex gap-2 py-6 hover:bg-primaryA2 hover:text-white"
        onClick={handleSubmitButton}
        disabled={isFormButtonClicked}
      >
        Become a DriveX Dealer
        {isFormButtonClicked && Object.keys(errors).length === 0 && (
          <LoaderIcon className="animate-spin" />
        )}
      </Button>
    </div>
  );
};

export default DealerForm;
