import { cn } from "@/lib/utils";
import { useCallback } from "react";

function SelectColour({
  vehicleListing,
  vehicleColorData,
  setVehicleListing,
  setSearchParams,
  type,
}: any) {
  const handleColorClick = useCallback(
    async (selectedColour: string) => {
      setVehicleListing({
        ...vehicleListing,
        steps: {
          ...vehicleListing.steps,
          color: selectedColour,
        },
      });
      setSearchParams({ step: "no_of_owner", type });
    },
    [type, vehicleListing],
  );
  return (
    <div className="mt-4 flex flex-col gap-4">
      {vehicleColorData.map((color: string) => {
        return (
          <div
            key={color}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              vehicleListing.steps.color === color
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleColorClick(String(color))}
          >
            {color}
          </div>
        );
      })}
    </div>
  );
}

export default SelectColour;
