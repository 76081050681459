import { LeftArrowIcon } from "@/assets/Icons";
import { pluralize } from "@/utils/pluralize";
import { useState } from "react";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import DoMyShootModal from "@/components/DoMyShootModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import VehicleMiniCard from "@/components/Dmx/VehicleMiniCard";

const PhotoUploadPendingListing = ({
  imagePendingVehiclesData,
  onClose,
}: {
  imagePendingVehiclesData: any;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDoMyShoot, setOpenDoMyShoot] = useState(false);

  const [selectedVehicleData, setSelectedVehicleData] = useState(null);

  const handleAddVehicleImagesClick = (item: any) => {
    navigate(`?list=photo-pending-vehicles&id=${item?.vehicleId}`);
    setSelectedVehicleData(item);
    setOpenDoMyShoot(true);
  };

  const handleCloseDoMyShoot = () => {
    searchParams.delete("id");
    setSearchParams(searchParams);
    setOpenDoMyShoot(false);
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      <div
        onClick={onClose}
        className="flex items-center gap-3 border-b pb-4 cursor-pointer"
      >
        <LeftArrowIcon
          size={20}
          oneStopColor="#0A0A0A"
          twoStopColor="#0A0A0A"
        />
        <p className="text-base font-medium">
          Images Pending for {imagePendingVehiclesData?.length}{" "}
          {pluralize(imagePendingVehiclesData?.length, "Vehicle")}
        </p>
      </div>

      <div className="flex flex-col gap-4 overflow-y-auto">
        {imagePendingVehiclesData?.map((data: any, index: number) => (
          <VehicleMiniCard
            key={index}
            data={data}
            CTAtext="Add Vehicle Images"
            handleCTAClick={handleAddVehicleImagesClick}
          />
        ))}
      </div>

      {openDoMyShoot && selectedVehicleData && (
        <Sheet open={openDoMyShoot} onOpenChange={handleCloseDoMyShoot}>
          <SheetContent
            className="rounded-t-lg"
            side={isMobile ? "bottom" : "right"}
          >
            <DoMyShootModal
              vehicleData={selectedVehicleData}
              setOpenDoMyShoot={setOpenDoMyShoot}
            />
          </SheetContent>
        </Sheet>
      )}
    </div>
  );
};

export default PhotoUploadPendingListing;
