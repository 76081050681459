import CustomerDetail from "@/components/CustomerDetail";
import VehicleQR from "@/components/VehicleQR";
import { calculatePercentageDifference } from "@/utils/calculatePercentageDifference";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import {
  BikeIcon,
  KeyIcon,
  BookedIcon,
  PriceNote,
  WrenchIcon,
  DiscountIcon,
} from "@/assets/Icons";
import PaymentModes from "./PaymentModes";

interface IProps {
  store: any;
  productDetail: IVehicleInfo;
  orderDetails?: any;
  storeOrderDetails?: any;
  openQRModal?: boolean | any;
  setOpenQRModal?: React.Dispatch<React.SetStateAction<boolean>> | any;
  imageFound: boolean;
  handleDownload?: () => void;
}

const DmxVehicleInfo = ({
  store,
  productDetail,
  orderDetails,
  storeOrderDetails,
  openQRModal,
  setOpenQRModal,
  imageFound,
  handleDownload,
}: IProps) => {
  const { isDesktop } = useScreenDetector();

  const isFOFOStore = store?.partner_type === "fofo";

  const finalPurchasePrice = productDetail?.refurbishment_price?.value
    ? productDetail?.refurbishment_price?.value +
      productDetail?.purchase_price?.value
    : productDetail?.purchase_price?.value;
  const sellPurchaseDifferencePercentage = calculatePercentageDifference(
    finalPurchasePrice,
    productDetail?.price?.value,
  );

  return (
    <div
      className={cn(
        "flex flex-col px-4 mt-[-30px] mb-[40px]",
        isDesktop && "px-0 py-2 mb-0",
      )}
    >
      <div className="border-t w-full"></div>
      <div className="grid grid-cols-2 gap-5 gap-x-3 mt-5">
        {isFOFOStore && (
          <div className="flex flex-col gap-2 ">
            <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
              <KeyIcon
                size={16}
                oneStopColor="#606060"
                twoStopColor="#606060"
              />{" "}
              <span className="ml-1">Purchase Price</span>
            </div>
            <p className="text-2xl font-medium">
              ₹{productDetail?.purchase_price?.displayValue}
            </p>
          </div>
        )}

        <div className="flex flex-col gap-2">
          <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
            <BikeIcon size={16} oneStopColor="#606060" twoStopColor="#606060" />
            <span className="ml-1">
              {productDetail?.status === "available"
                ? "Website Price"
                : "Selling Price"}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <p className="text-2xl font-medium">
              ₹{productDetail?.price?.displayValue}
            </p>{" "}
            {isFOFOStore && (
              <div
                className={cn(
                  "rounded p-1 w-fit h-fit text-xs",
                  parseInt(sellPurchaseDifferencePercentage) >= 0
                    ? "bg-[#f0f9ee] text-[#5FA74E]"
                    : "bg-[#FDECEB] text-red600",
                )}
              >
                {parseInt(sellPurchaseDifferencePercentage) >= 0 && "+"}
                {sellPurchaseDifferencePercentage}%
              </div>
            )}
          </div>
        </div>

        {orderDetails?.booking_amount > 0 && (
          <div className="flex flex-col gap-2 ">
            <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
              <BookedIcon color="#606060" />
              <span className="ml-1">Booking Amount</span>
            </div>
            <p className="text-2xl font-medium">
              ₹{orderDetails?.booking_amount?.toLocaleString("en-IN")}
            </p>
          </div>
        )}

        {isFOFOStore &&
          !productDetail?.drivexAssured &&
          productDetail?.refurbishment_price?.displayValue && (
            <div className="flex flex-col gap-2 ">
              <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
                <WrenchIcon
                  size={16}
                  oneStopColor="#606060"
                  twoStopColor="#606060"
                />
                <span className="ml-1">Refurbishment Price</span>
              </div>
              <p className="text-2xl font-medium">
                ₹{productDetail?.refurbishment_price?.displayValue}
              </p>
            </div>
          )}

        {orderDetails?.discount_amount > 0 && (
          <div className="flex flex-col gap-2 ">
            <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
              <DiscountIcon />
              <span className="ml-1">Discount Amount</span>
            </div>
            <p className="text-2xl font-medium">
              ₹{orderDetails?.discount_amount?.toLocaleString("en-IN")}
            </p>
          </div>
        )}

        {orderDetails?.additional_charges_info?.length > 0 && (
          <div className="flex flex-col gap-2 ">
            <div className="bg-neutral30 p-2 text-neutral400 text-sm w-fit rounded-md flex-center">
              <PriceNote
                size={18}
                oneStopColor="#919191"
                twoStopColor="#919191"
                url="dmx_sold_vehicles"
              />
              <span className="ml-1">Extra Charges</span>
            </div>
            <p className="text-2xl font-medium">
              ₹
              {orderDetails?.additional_charges_info
                ?.reduce(
                  (accumulator: any, item: { amount: any }) =>
                    accumulator + item.amount,
                  0,
                )
                ?.toLocaleString("en-IN")}
            </p>
          </div>
        )}
      </div>

      <div className="py-2">
        {productDetail?.status !== "available" && (
          <CustomerDetail
            orderDetails={storeOrderDetails ?? orderDetails}
            productDetail={productDetail}
            handleDownload={handleDownload}
          />
        )}

        {productDetail?.status === "available" && imageFound && (
          <VehicleQR
            openQRModal={openQRModal}
            productDetail={productDetail}
            setOpenQRModal={setOpenQRModal}
          />
        )}
      </div>

      {orderDetails?.payments_info?.length > 0 && (
        <PaymentModes
          orderDetails={orderDetails}
          handleDownload={handleDownload}
        />
      )}
    </div>
  );
};

export default DmxVehicleInfo;
