import { useState, useEffect } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { ChevronDown, ChevronUp } from "lucide-react";

interface SubChecks {
  firstSubCheckbox: boolean;
  secondSubCheckbox: boolean;
}

interface IProps {
  subChecks: SubChecks;
  setSubChecks: React.Dispatch<React.SetStateAction<any>>;
}

type MainCheckedState = boolean | "indeterminate";

export default function AuthTermsAndConditions({
  subChecks,
  setSubChecks,
}: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isParentCheckboxChecked, setIsParentCheckboxChecked] =
    useState<MainCheckedState>(false);

  // Update main checkbox based on sub-checkboxes
  useEffect(() => {
    if (subChecks.firstSubCheckbox && subChecks.secondSubCheckbox) {
      setIsParentCheckboxChecked(true);
    } else if (!subChecks.firstSubCheckbox && !subChecks.secondSubCheckbox) {
      setIsParentCheckboxChecked(false);
    } else {
      setIsParentCheckboxChecked("indeterminate");
    }
  }, [subChecks]);

  // Update sub-checkboxes when main checkbox is clicked
  const handleMainCheckbox = (): void => {
    const newValue = !(isParentCheckboxChecked === true);
    setIsParentCheckboxChecked(newValue);
    setSubChecks({
      firstSubCheckbox: newValue,
      secondSubCheckbox: newValue,
    });
  };

  return (
    <div className="w-full max-w-xl space-y-2">
      <div className="flex items-start space-x-3">
        <Checkbox
          id="terms"
          checked={isParentCheckboxChecked}
          onCheckedChange={handleMainCheckbox}
          className="mt-1 h-5 w-5 border-2 border-orange-500 data-[state=checked]:border-orange-500 data-[state=checked]:bg-primaryA2"
        />
        <div className="space-y-1">
          <label htmlFor="terms" className="text-sm text-neutral300">
            I agree to all the Terms & Conditions and to be contacted by DriveX
            for marketing purpose
          </label>
          <Collapsible open={isOpen} onOpenChange={setIsOpen}>
            <CollapsibleTrigger className="text-orange-500 text-sm flex items-center">
              Details{" "}
              {isOpen ? (
                <ChevronUp className="h-4 w-4 ml-1" />
              ) : (
                <ChevronDown className="h-4 w-4 ml-1" />
              )}
            </CollapsibleTrigger>
            <CollapsibleContent className="space-y-4 mt-4">
              <div className="flex items-start space-x-3">
                <Checkbox
                  id="subterms"
                  checked={subChecks.firstSubCheckbox}
                  onCheckedChange={(checked: boolean) =>
                    setSubChecks((prev: any) => ({
                      ...prev,
                      firstSubCheckbox: checked,
                    }))
                  }
                  className="mt-1 h-5 w-5 border-2 border-orange-500 data-[state=checked]:border-orange-500 data-[state=checked]:bg-primaryA2"
                />
                <label htmlFor="subterms" className="text-sm text-neutral300">
                  By registering here, I agree to DriveX Mobility Pvt Ltd{" "}
                  <a href="/terms" target="_blank" className="text-primaryA2">
                    Terms & Conditions
                  </a>{" "}
                  &{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    className="text-primaryA2"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>
              <div className="flex items-start space-x-3">
                <Checkbox
                  id="secondSubCheckbox"
                  checked={subChecks.secondSubCheckbox}
                  onCheckedChange={(checked: boolean) =>
                    setSubChecks((prev: any) => ({
                      ...prev,
                      secondSubCheckbox: checked,
                    }))
                  }
                  className="mt-1 h-5 w-5 border-2 border-orange-500 data-[state=checked]:border-orange-500 data-[state=checked]:bg-primaryA2"
                />
                <label
                  htmlFor="secondSubCheckbox"
                  className="text-sm text-neutral300"
                >
                  I authorize - DriveX Mobility Pvt Ltd or its partners to
                  contact me via SMS, E-mail, WhatsApp and any other modes of
                  communication for marketing purposes.
                </label>
              </div>
            </CollapsibleContent>
          </Collapsible>
        </div>
      </div>
    </div>
  );
}
