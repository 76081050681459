import { isDealerPath } from "@/utils/url";
import { useEffect } from "react";
import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

const PrivateRoutes = () => {
  const location = useLocation();
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isUserLoggedIn && searchParams.get("login")) {
      searchParams.delete("login");
      setSearchParams(searchParams);
    }
  }, [isUserLoggedIn]);

  // If user is authenticated, render the protected route
  if (isUserLoggedIn) {
    return <Outlet />;
  }

  // Handle unauthenticated users
  const loginRedirectPath = isDealerPath()
    ? "/dealer"
    : `${location.pathname}?login=true`;

  return <Navigate to={loginRedirectPath} />;
};

export default PrivateRoutes;
