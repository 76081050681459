import Brand from "@/components/Brand";
import { useCallback, useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";
import {
  useLazyGetAllBrandsQuery,
  useLazyGetColorVariantsQuery,
  useLazyGetModelAndVariantQuery,
} from "@/store/services/Dmx/storeVehicles";
import { IBrand } from "@/pages/Sell/HeroSection/Vahan/data";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentBrands } from "@/store/features/allBrandsSlice";

type IProps = {
  vehicleListing: {
    steps: {
      brand?: string;
    };
  };
  setVehicleListing: any;
  setSearchParams: any;
  type: string;
  setModels: any;
  setVehicleColorData: any;
};

function SelectBrand({
  vehicleListing,
  setVehicleListing,
  setSearchParams,
  type,
  setModels,
  setVehicleColorData,
}: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { allBrands } = useSelector((state: any) => state.allBrands);
  const dispatch = useDispatch();
  const dataFetchedRef = useRef(false);
  const [getAllBrands] = useLazyGetAllBrandsQuery({});
  const [getColorVariant] = useLazyGetColorVariantsQuery();
  const [getModelAndVariant] = useLazyGetModelAndVariantQuery();

  const getBrands = async () => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    setLoading(true);
    const res = await getAllBrands({});

    if (res?.data?.status === "OK") {
      const transformedArray = res?.data?.data?.map(
        (brand: { display_name: string; icon_url: string }) => {
          return {
            name: brand.display_name || "",
            img: brand.icon_url,
          };
        },
      );
      dispatch(setCurrentBrands(transformedArray));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!allBrands) {
      getBrands();
    }
  }, []);

  const handleBrandClick = useCallback(
    async (brandName: string) => {
      setLoading(true);

      // Fetch available color variants for vehicles
      const colorRes = await getColorVariant({});
      if (colorRes?.data?.status === "OK") {
        setVehicleColorData(colorRes?.data?.data);
      }

      // Fetch models and variants specific to the selected brand
      const modalAndVariantRes = await getModelAndVariant({ brandName });
      if (modalAndVariantRes?.data?.status === "OK") {
        setModels(modalAndVariantRes?.data?.data);
        setSearchParams({ step: "model", type });
        setVehicleListing({
          ...vehicleListing,
          steps: {
            ...vehicleListing.steps,
            brand: brandName,
          },
        });
      }
      setLoading(false);
    },
    [type, vehicleListing],
  );

  if (!allBrands && !loading) return;

  return (
    <div className="grid max-[250px]:flex flex-wrap max-[400px]:grid-cols-2 grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
      {loading
        ? Array(10)
            .fill(0)
            .map((_, index) => (
              <div
                key={`skeleton-${index}`}
                className="w-full h-28 border rounded-lg bg-gray-100 animate-pulse"
              />
            ))
        : allBrands.map((brand: IBrand, index: number) => (
            <Brand
              key={index}
              brand={brand}
              className={cn(
                "w-full border text-center",
                vehicleListing?.steps?.brand === brand.name
                  ? "border-primaryA1"
                  : "bg-white",
              )}
              handleClick={() => handleBrandClick(brand.name)}
            />
          ))}
    </div>
  );
}

export default SelectBrand;
