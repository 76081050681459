import { Button } from "@/components/ui/button";
import Image from "@/components/ui/image";
import { cn } from "@/lib/utils";
import { fallbackImage } from "@/utils/fallbackImage";

interface IProps {
  data: {
    vehicleId?: string;
    vehicleName?: string;
    vehicleType?: string;
    imagePaths?: string[];
    registrationNumber?: string;
    price?: { displayValue: string };
    manufactureYear?: string | number;
    kmDriven?: { displayValue: string };
  };
  className?: string;
  showCTA?: boolean;
  CTAtext?: string;
  handleCTAClick?: (item: any) => void;
}

const VehicleMiniCard = ({
  data,
  className,
  CTAtext,
  handleCTAClick,
  showCTA = true,
}: IProps) => {
  return (
    <div
      className={cn(
        "flex flex-col gap-4 bg-white border-b border-neutral50 pb-6",
        className,
      )}
    >
      <div className="flex items-center gap-2">
        {data?.imagePaths && (
          <Image
            className="w-[5.8rem] h-[3.8rem] object-cover rounded-lg"
            transform="f-webp"
            src={data.imagePaths[0] ?? fallbackImage(data?.vehicleType || "")}
            alt={data?.vehicleName || ""}
          />
        )}
        <div className="flex flex-col gap-1">
          <p className="text-lg font-medium text-[#2F2F2F] line-clamp-1 text-wrap">
            {data.vehicleName}
          </p>
          <p className="text-neutral300 text-xs">
            {data?.registrationNumber}
            {data?.kmDriven?.displayValue &&
              `•${data?.kmDriven?.displayValue} KMs`}
            {data?.manufactureYear && `•${data?.manufactureYear}`}
          </p>
          {data?.price?.displayValue && (
            <p className="text-base font-medium">
              ₹ {data?.price?.displayValue}
            </p>
          )}
        </div>
      </div>

      {showCTA && (
        <div className="w-full">
          <Button
            variant="outline"
            className="border-primaryA1 text-primaryA1 w-full hover:bg-white hover:text-primaryA1"
            onClick={() => handleCTAClick && handleCTAClick(data)}
          >
            {CTAtext}
          </Button>
        </div>
      )}
    </div>
  );
};

export default VehicleMiniCard;
