import {
  clearPendingAction,
  setPendingAction,
} from "@/store/features/pendingActionsSlice";
import { addOrRemoveWishlist } from "@/store/features/wishListSlice";
import { useDispatch } from "react-redux";

export const usePendingAction = () => {
  const dispatch = useDispatch();

  function handlePendingAction(pendingAction: any) {
    switch (pendingAction.actionType) {
      case "GET_PRICE_ESTIMATE":
        dispatch(
          setPendingAction({
            handleAction: pendingAction.method,
            payload: pendingAction.payload,
          }),
        );
        break;
      case "OPEN_VIDEO_TOUR":
        dispatch(setPendingAction({ openVideoTour: true }));
        break;
      case "OPEN_CALL_STORE":
        dispatch(setPendingAction({ openCallStore: true }));
        break;
      case "UPDATE_WISHLIST":
        dispatch(
          setPendingAction({
            handleAction: addOrRemoveWishlist,
            payload: pendingAction.payload,
          }),
        );
        break;
      case "BOOK_SERVICE_APPOINTMENT":
        dispatch(
          setPendingAction({
            handleAction: pendingAction.method,
            payload: pendingAction.payload,
          }),
        );
        break;
      case "SUBMIT_DEALER_QUERY":
        dispatch(
          setPendingAction({
            handleAction: pendingAction.method,
            payload: pendingAction.payload,
          }),
        );
        break;
      case "CLEAR_PENDING_ACTION":
        dispatch(clearPendingAction());
        break;
      // Add more cases as needed
    }
  }

  return { handlePendingAction };
};
