import React, { createContext, useContext, ReactNode } from "react";
import { useSelector } from "react-redux";

interface RoleContextValue {
  roles: Roles[];
}

const RoleContext = createContext<RoleContextValue | undefined>(undefined);

export const RoleProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const roles: Roles[] = user?.roles;

  return (
    <RoleContext.Provider value={{ roles }}>{children}</RoleContext.Provider>
  );
};

export const useRoles = () => {
  const context = useContext(RoleContext);
  if (!context) {
    throw new Error("useRoles must be used within a RoleProvider");
  }
  return context.roles;
};
