import { CheckCircle, ErrorCircle, LeftArrowIcon } from "@/assets/Icons";
import FetchVehicleDetails from "./FetchVehicleDetails";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validateIndianVehicleNumber } from "@/utils/validation";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useLazyGetFofoVehicleInfoQuery } from "@/store/services/Dmx/storeVehicles";

const AddVehicleFlow = () => {
  const navigate = useNavigate();
  const store = useSelector((state: any) => state.store.store);
  const [getFofoVehicleInfo, { isFetching: isFetchingFofoDetails }] =
    useLazyGetFofoVehicleInfoQuery();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [isValidVehicleNumber, setIsValidVehicleNumber] = useState("");
  const [isDataFetched, setIsDataFetched] = useState<object | any>({});
  const [apiResponseMsg, setApiResponseMsg] = useState({
    isVehicleAvailable: "",
    reason: "",
  });

  const isFofoStore = store?.partner_type === "fofo";

  useEffect(() => {
    if (!isFofoStore) {
      navigate("/dealer/vehicles", { replace: true });
    }
  }, [store]);

  const handleChangeRegistrationNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedValue = e.target.value.toUpperCase();
    setIsValidVehicleNumber("");
    setIsDataFetched({});
    setApiResponseMsg({
      isVehicleAvailable: "",
      reason: "",
    });

    setRegistrationNumber(updatedValue);
  };

  const renderSuccessOrErrorIcon = (successOrError: string) => {
    switch (successOrError) {
      case "valid":
        return <CheckCircle />;
      case "invalid":
        return <ErrorCircle />;
      case "":
        return "";
    }
  };

  const handleFetchVehicleDetails = async () => {
    const isValidVehicleNumber =
      validateIndianVehicleNumber(registrationNumber);

    if (!isValidVehicleNumber) {
      setIsValidVehicleNumber("invalid");
      return;
    }

    setIsValidVehicleNumber("valid");

    const res = await getFofoVehicleInfo({
      registration_number: registrationNumber,
      store_id: store?.store_id,
    });

    if (res.error && "status" in res.error && res.error.status === 500) {
      setApiResponseMsg({
        isVehicleAvailable: "unavailable",
        reason: "VehicleNotFound",
      });

      return;
    }

    const resMessage = res?.data?.message;

    if (resMessage === "Request successful") {
      setIsDataFetched(res?.data?.data);
      setApiResponseMsg({
        isVehicleAvailable: "available",
        reason: "",
      });

      return;
    } else {
      if (
        resMessage === "VEHICLE_NOT_FOUND" ||
        resMessage === "VEHICLE_NOT_A_TWO_WHEELER"
      ) {
        setIsDataFetched({});
      } else {
        setIsDataFetched(res?.data?.data);
      }

      setApiResponseMsg({
        isVehicleAvailable: "unavailable",
        reason: resMessage,
      });
    }
  };

  const handleClose = () => {
    setRegistrationNumber("");
    setIsDataFetched({});
    setApiResponseMsg({
      isVehicleAvailable: "",
      reason: "",
    });
  };

  const handleDataFound = () => {
    navigate("/dealer/add-vehicle?step=km_driven&type=automatic", {
      state: { automaticFlowData: isDataFetched },
    });
  };

  const handleDataNotFound = () => {
    navigate("/dealer/add-vehicle?step=brand&type=manual", {
      state: { manualFlowData: { registrationNumber } },
    });
  };

  const handleEditDetails = () => {
    navigate("/dealer/add-vehicle?step=brand&type=manual", {
      state: {
        manualFlowData: {
          registrationNumber,
          brand: isDataFetched.brand || "",
        },
      },
    });
  };

  const handleBackButtonClick = () => {
    navigate("/dealer/vehicles");
  };

  return (
    <Sheet open={true} onOpenChange={handleBackButtonClick}>
      <SheetContent
        side="right"
        className="flex flex-col gap-4 h-screen bg-neutral30 md:bg-white p-0 w-full"
      >
        <div className="flex justify-between items-center gap-2 p-4 md:border-b bg-white">
          <span
            className="flex items-center gap-2 w-fit cursor-pointer"
            onClick={handleBackButtonClick}
          >
            <LeftArrowIcon size={20} oneStopColor="#000" twoStopColor="#000" />
            <p className="text-base font-semibold">Add Vehicle</p>
          </span>

          <p className="text-xs font-medium text-neutral300">
            {store?.store_name}
          </p>
        </div>

        <div className="flex flex-col gap-4 p-4 h-full">
          <FetchVehicleDetails
            isDataFetched={isDataFetched}
            apiResponseMsg={apiResponseMsg}
            handleClose={handleClose}
            handleDataFound={handleDataFound}
            handleEditDetails={handleEditDetails}
            handleDataNotFound={handleDataNotFound}
            registrationNumber={registrationNumber}
            isValidVehicleNumber={isValidVehicleNumber}
            isFetchingFofoDetails={isFetchingFofoDetails}
            renderSuccessOrErrorIcon={renderSuccessOrErrorIcon}
            handleFetchVehicleDetails={handleFetchVehicleDetails}
            handleChangeRegistrationNumber={handleChangeRegistrationNumber}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default AddVehicleFlow;
