import { DMXLogo, SparkleIcon } from "@/assets/Icons";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import Image from "@/components/ui/image";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import Otp from "@/pages/Auth/Otp";
import {
  ALPHABET_REGEX,
  isDealerMode,
  MAX_PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_REGEX,
} from "@/utils/constants";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  createCode,
  doesPhoneNumberExist,
} from "supertokens-web-js/recipe/passwordless";
import DealerNotFoundComponent from "./DealerNotFoundComponent";
import {
  DX_USER_LOGIN_FAIL,
  DX_USER_LOGIN_SUCCESS,
} from "@/utils/mixpanel/Events/auth_clicked_events";
import { track } from "@/utils/mixpanel/actions";
import { Dialog } from "@/components/ui/dialog";
import DmxWalkThrough from "./DmxWalkThrough";
import { useNavigate } from "react-router-dom";

const DealerAuth = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [userDetails, setUserDetails] = useState({
    phoneNumber: "",
    name: "",
  });
  const [isOtpView, setIsOtpView] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const { isDesktop, isMobile } = useScreenDetector();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDealerNotFound, setIsDealerNotFound] = useState(false);
  const [openDmxWalkThroughDialog, setOpenDmxWalkThroughDialog] =
    useState(false);
  const [newWebsiteUser, setNewWebsiteUser] = useState(false);
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
  const user = useSelector((state: any) => state.user.currentUser);
  const checkUserDmxWalkFlow = localStorage.getItem("dmxWalkThroughCompleted");

  useEffect(() => {
    if (isUserLoggedIn) {
      if (isDealerMode) {
        window.location.href = "/dealer/vehicles";
      } else {
        window.location.href = "/";
      }
    }

    if (!checkUserDmxWalkFlow) {
      setOpenDmxWalkThroughDialog(true);
    }
  }, [isUserLoggedIn, user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMsg.length > 0) setErrorMsg("");

    const value = e.target.value;
    if (e.target.name === "phoneNumber" && PHONE_NUMBER_REGEX.test(value)) {
      setUserDetails({ ...userDetails, phoneNumber: value });
      if (value.length === MAX_PHONE_NUMBER_LENGTH) {
        setErrorMsg("");
      }
    }
    if (e.target.name === "name" && ALPHABET_REGEX.test(value)) {
      setUserDetails({ ...userDetails, name: value });
    }
  };

  async function sendOTP() {
    try {
      const response = await createCode({
        phoneNumber: `+91${userDetails.phoneNumber}`,
      });

      if (response.status === "OK") {
        setIsOtpView(true);
        setIsOtpSent(true);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        if (err.message === "Phone number is invalid")
          setErrorMsg("Invalid number");
      } else {
        toast({
          title: err.message,
          variant: "destructive",
        });
      }
    }
  }

  const handleProceed = async () => {
    if (userDetails.phoneNumber.length === MAX_PHONE_NUMBER_LENGTH) {
      try {
        if (newWebsiteUser) {
          sendOTP();
          return;
        }

        const response = await doesPhoneNumberExist({
          phoneNumber: `+91${userDetails.phoneNumber}`,
        });

        if (response.doesExist) {
          sendOTP();
        } else {
          setNewWebsiteUser(true);
        }
      } catch (err) {
        console.error("Error checking phone number:", err);
        toast({
          title: "Something went wrong",
          variant: "destructive",
        });
      }
    }
  };

  const customSuccessHandlerForOtp = (userData: IUser) => {
    setIsOtpVerified(true);
    if (userData?.roles?.length > 1) {
      localStorage.setItem("role", userData?.roles[0]);
      navigate("/dealer/vehicles");
      track(DX_USER_LOGIN_SUCCESS, {
        phone_number: userData?.phoneNumber,
        user_name: userData?.userName,
        role: userData?.roles[0],
        device_name: navigator.userAgent,
      });
    } else {
      setIsOtpView(false);
      setIsDealerNotFound(true);
      track(DX_USER_LOGIN_FAIL, {
        phone_number: userData?.phoneNumber,
        user_name: userData?.userName,
        role: userData?.roles[0],
        device_name: navigator.userAgent,
        status: "Failed",
      });
    }
  };

  const handleEdit = () => {
    if (errorMsg.length > 0) setErrorMsg("");
    if (otp.length > 0) setOtp("");
    setIsOtpView(false);
  };
  return (
    <div
      className="flex items-center justify-center bg-white md:h-screen md:w-screen"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="w-full flex flex-col md:flex-row bg-white overflow-hidden relative h-dvh md:h-auto">
        {/* Image Section */}
        <div className="w-[100%] h-[75%] md:h-screen md:w-[45%] relative">
          <Image
            src="https://ik.imagekit.io/drivex/DMX/ik_dmx_landingpage/dmx_login.jpg"
            transform="f-webp,q-50"
            alt="DMX cover"
            className="w-full h-full object-cover brightness-[0.8]"
          />
          <div className="absolute top-[50%] md:top-[50%] left-[50%] md:left-[40%] transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-2 w-[65%] md:w-[70%] justify-center">
            <h1 className="text-white text-[18px] md:text-[32px] font-semibold ">
              Welcome to the{" "}
              <span className="bg-gradient-to-r from-[#E63C32] to-[#F47823] bg-clip-text text-transparent relative">
                Brand New
                <span className="absolute top-0 right-0.8">
                  <SparkleIcon size={15} />
                </span>
              </span>{" "}
              Dealer Management System
            </h1>
            <div className="flex items-center justify-center gap-2 w-full text-center">
              <span className="border-t border-white w-[70px] md:w-[150px]"></span>
              <span className="text-white text-[10px] md:text-[16px] ">
                Which is now
              </span>
              <span className="border-t border-white w-[70px] md:w-[150px]"></span>
            </div>
            {isDesktop ? <DMXLogo /> : <DMXLogo size={100} />}
          </div>
          {isDesktop && (
            <span className="absolute bottom-2 left-4 text-white text-[16px] font-medium">
              Dealer Management System {"->"} Dealer Management Xperience
            </span>
          )}
        </div>

        {/* Form Section */}
        <div className="flex-1 flex justify-center items-center p-2 md:p-8 flex-col rounded-t-[12px] md:rounded-none z-10 border-t-2 border-x-2 absolute bottom-0 left-0 right-0 shadow-md md:relative bg-white ">
          {isDesktop && (
            <div className="absolute top-2 right-2">
              <DMXLogo color="black" size={150} />
            </div>
          )}

          <div className="flex flex-col items-start w-full p-2 md:p-0 border-gray-900 rounded-sm md:w-[65%]">
            <h2 className="text-2xl font-semibold text-[#0A0A0A] pb-4 md:pb-0">
              {isDealerNotFound
                ? "Number not registered"
                : isDesktop
                  ? "Welcome to DMX"
                  : "Login"}
            </h2>
            {isDealerNotFound ? (
              <DealerNotFoundComponent userDetails={userDetails.phoneNumber} />
            ) : (
              <Card
                className={cn("w-full mt-4 pt-3 space-y-8", {
                  "rounded-none border-none bg-card shadow-none": isMobile,
                })}
              >
                <CardContent className="pt-3 space-y-8  p-0 md:p-6">
                  {!isOtpView ? (
                    <>
                      <div className="space-y-1 flex flex-col gap-2">
                        <label
                          htmlFor="phoneNumber"
                          className="text-sm font-semibold leading-none"
                        >
                          Phone Number
                        </label>
                        <div
                          className={cn(
                            "flex items-center px-4 rounded-md bg-gray-100 h-12",
                            errorMsg && "bg-red-50 border-red-300 border",
                          )}
                        >
                          <span className="text-gray-600 text-lg">+91</span>
                          <Input
                            ref={inputRef}
                            id="phoneNumber"
                            className={cn(
                              "bg-transparent py-6 pl-2 border-none focus-visible:ring-0 focus-visible:ring-offset-0 text-lg h-full",
                              errorMsg && "bg-transparent",
                            )}
                            name="phoneNumber"
                            value={userDetails.phoneNumber}
                            onChange={handleChange}
                            pattern="[0-9]*"
                            type="text"
                            maxLength={10}
                            autoFocus
                          />
                        </div>
                        {newWebsiteUser && (
                          <>
                            <label
                              htmlFor="name"
                              className="text-sm font-semibold leading-none"
                            >
                              Name
                            </label>
                            <div
                              className={cn(
                                "flex items-center px-4 rounded-md bg-gray-100 h-12",
                                errorMsg && "bg-red-50 border-red-300 border",
                              )}
                            >
                              <Input
                                ref={inputRef}
                                id="phoneNumber"
                                className={cn(
                                  "bg-transparent py-6 pl-2 border-none focus-visible:ring-0 focus-visible:ring-offset-0 text-lg h-full",
                                  errorMsg && "bg-transparent",
                                )}
                                name="name"
                                value={userDetails.name}
                                onChange={handleChange}
                                type="text"
                              />
                            </div>
                          </>
                        )}

                        {errorMsg && (
                          <p className="text-sm text-red-600">{errorMsg}</p>
                        )}

                        <span className="text-gray-500 text-xs mt-0 pb-[50px]">
                          Login using your DriveX registered mobile number.
                        </span>

                        <Button
                          variant="default"
                          className={cn(
                            "w-full py-6 text-lg font-semibold h-12",
                            userDetails.phoneNumber.length === 10
                              ? "bg-gradient-to-r from-red-500 to-orange-400 hover:bg-gradient-to-r hover:from-red-600 hover:to-orange-500 text-white"
                              : "bg-gray-100 text-gray-400 cursor-not-allowed",
                          )}
                          disabled={userDetails.phoneNumber.length !== 10}
                          onClick={handleProceed}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col gap-5 mb-12 md:p-5">
                      {/* OTP View Section */}
                      <Otp
                        searchParams={new URLSearchParams()}
                        userDetails={userDetails}
                        errorMsg={errorMsg}
                        setErrorMsg={setErrorMsg}
                        otp={otp}
                        setOtp={setOtp}
                        isOnlyOTPVerification={false}
                        showEdit={true}
                        showResend={isOtpSent && !isOtpVerified}
                        customSuccessHandlerForOtp={(user) =>
                          customSuccessHandlerForOtp(user)
                        }
                        navigateOnLogin={false}
                        clearOtp={false}
                        inputOtpSlotClassName="!w-16"
                        handleEdit={handleEdit}
                        setIsOtpView={setIsOtpView}
                        setIsDealerNotFound={setIsDealerNotFound}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
      {isDesktop && (
        <Dialog open={openDmxWalkThroughDialog}>
          <DmxWalkThrough
            setOpenDmxWalkThroughDialog={setOpenDmxWalkThroughDialog}
          />
        </Dialog>
      )}
    </div>
  );
};

export default DealerAuth;
