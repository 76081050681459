import React, { useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetClose } from "../ui/sheet";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { CrossIcon } from "@/assets/Icons";
import { Input } from "../ui/input";
import { formatToIndianNumber } from "@/utils/numerics";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { useSelector } from "react-redux";

type EditVehicleDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  inputFields: any;
  handleOwnerClick?: (owner: number) => void;
  editVehicleFormData: any;
  isEditVehicleLoading?: boolean;
  setEditVehicleFormData: any;
  handleEditVehicleSaveButton?: () => void;
};

function EditVehicleDetailsModal({
  open,
  onClose,
  inputFields,
  handleOwnerClick,
  editVehicleFormData,
  isEditVehicleLoading,
  setEditVehicleFormData,
  handleEditVehicleSaveButton,
}: EditVehicleDetailsModalProps) {
  const store = useSelector((state: any) => state.store.store);
  const { isMobile } = useScreenDetector();
  const [errors, setErrors] = useState<{
    [key: string]: boolean | string | { [key: string]: boolean | string };
  }>({});

  const isDisabled = Object.values(editVehicleFormData).some(
    (value) => value === "" || !value,
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\D/g, "");

    const { name, value } = e.target;

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }

    setEditVehicleFormData({ ...editVehicleFormData, [name]: value });
  };

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className={cn(
          "flex flex-col gap-4 p-4 rounded-t-lg max-h-dvh",
          !isMobile && "h-dvh",
        )}
      >
        <SheetHeader>
          <div className="flex justify-between items-center">
            <p className="text-lg">Edit Bike Details</p>

            <SheetClose onClick={onClose} className="outline-none">
              <CrossIcon size={26} oneStopColor="#000" twoStopColor="#000" />
            </SheetClose>
          </div>
        </SheetHeader>

        <hr className=" border-t-[1px] border-neutral50 w-full" />

        <div className="flex flex-col gap-5 overflow-y-scroll">
          {inputFields.map((field: any) => {
            let value =
              editVehicleFormData[
                field.name as keyof typeof editVehicleFormData
              ];
            if (field.type === "number") {
              value = formatToIndianNumber(value);
            }
            return (
              <div key={field.name} className="flex flex-col gap-2">
                <div className="flex gap-0.5">
                  <label htmlFor={field.name} className="text-sm font-semibold">
                    {field.label}
                  </label>
                  {field.required && <p className="text-red-600">*</p>}
                </div>

                {!field.ownership && (
                  <Input
                    className={cn(
                      "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                      errors[field.name] && "bg-red-100",
                    )}
                    name={field.name}
                    placeholder={field.placeholder}
                    required={field.required}
                    value={value}
                    onChange={handleInputChange}
                    maxLength={10}
                    disabled={field.disabled === store?.partner_type}
                  />
                )}

                {field.ownership && (
                  <div className="flex gap-2">
                    {field?.ownership?.map(
                      (
                        owner: { owner: string; value: number },
                        index: number,
                      ) => {
                        const isActive =
                          String(editVehicleFormData.ownership) ===
                          owner.value.toString();
                        return (
                          <div
                            onClick={() => handleOwnerClick?.(owner.value)}
                            className={cn(
                              "py-1 px-2 border border-[#FAC19A] rounded-md cursor-pointer",
                              isActive && "border-orange-500",
                            )}
                            key={index}
                          >
                            <p
                              className={cn(
                                "text-sm",
                                isActive && "text-primaryA1",
                              )}
                            >
                              {owner.owner}
                            </p>
                          </div>
                        );
                      },
                    )}
                  </div>
                )}

                {typeof errors[field.name] === "string" && (
                  <p className="text-red-500 text-sm">
                    {errors[field.name] as string}
                  </p>
                )}
              </div>
            );
          })}
        </div>

        <div className="bg-white border-t pt-4 mt-auto flex justify-between gap-2 items-center">
          <Button
            variant="outline"
            className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="outline"
            className="bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white"
            disabled={
              isDisabled ||
              isEditVehicleLoading ||
              Object.values(errors).some((error) => error !== false)
            }
            onClick={handleEditVehicleSaveButton}
          >
            Save
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default EditVehicleDetailsModal;
