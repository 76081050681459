import CustomerDetail from "@/components/CustomerDetail";
import VehicleSoldSuccessSkeleton from "@/components/SkeletonLoaders/VehicleSoldSuccess";
import Image from "@/components/ui/image";
import { cn } from "@/lib/utils";
import { useSearchParams } from "react-router-dom";
import Paymentbreakdown from "../Paymentbreakdown";
import { convertUTCToDDMMYYYY } from "@/utils/date";
import { fallbackImage } from "@/utils/fallbackImage";

const Documents = ({
  orderDetails,
  handleDownload,
  isLoading,
  productDetail,
  amountData,
  finalSellingPrice,
  formData,
}: any) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const priceInfo = [
    {
      label: "Vehicle Booked for",
      value: formData?.booking_amount ?? orderDetails?.booking_amount,
    },
    {
      label: "Amount Remaining",
      value: finalSellingPrice,
    },
  ];

  if (isLoading) return <VehicleSoldSuccessSkeleton />;

  return (
    <div className={cn("flex flex-col gap-4 mt-[20px] px-1")}>
      {orderDetails?.order_status === "RESERVED" && (
        <div className="flex justify-between">
          {priceInfo.map((info, index) => (
            <div key={index} className="flex flex-col gap-2">
              <p>{info.label}</p>
              <p className="text-2xl font-medium">
                ₹{Number(info.value)?.toLocaleString("en-IN")}
              </p>
            </div>
          ))}
        </div>
      )}

      <div className="flex gap-4 items-center border-b py-4">
        <>
          <Image
            className="w-[92px] h-[64px] rounded-lg object-cover"
            src={
              orderDetails?.imagePaths?.[0] ??
              fallbackImage(
                orderDetails?.vehicleType?.toLowerCase()?.includes("scooter")
                  ? "scooter"
                  : "motorcycle",
              )
            }
            alt="Vehicle"
          />

          <div className="flex flex-col gap-1">
            <p className="text-sm">{orderDetails?.vehicleName}</p>
            <p className="text-xs text-neutral300">
              {orderDetails?.registrationNumber} •{" "}
              {orderDetails?.kmDriven?.displayValue} KMs •{" "}
              {orderDetails?.manufactureYear}
            </p>
            <p className="text-sm text-neutral300">
              {type === "reserve"
                ? `Booked on ${orderDetails?.payments_info[0]?.payment_date}`
                : `Sold on ${convertUTCToDDMMYYYY(orderDetails?.order_updated_at)}`}{" "}
            </p>
          </div>
        </>
      </div>

      <CustomerDetail
        orderDetails={orderDetails}
        productDetail={productDetail}
        handleDownload={handleDownload}
      />

      {type === "sell" && (
        <Paymentbreakdown
          finalSellingPrice={finalSellingPrice}
          amountData={amountData}
        />
      )}
    </div>
  );
};

export default Documents;
