import firstOwner from "@/assets/svg/Filters/1stOwner.svg";
import secondOwner from "@/assets/svg/Filters/2ndOwner.svg";
import thirdOwner from "@/assets/svg/Filters/3rdOwner.svg";
import { FOFO_PROCUREMENT_PRICE_WITH_MAX_LIMIT } from "@/utils/constants";

export type VehicleListingStepsType = "automatic" | "manual";

export type VehicleListingSteps =
  | "brand"
  | "model"
  | "variant"
  | "year"
  | "color"
  | "no_of_owner"
  | "vehicle_additional_details"
  | "km_driven"
  | "pricing";

export interface IVehicleListing {
  steps: {
    brand?: string;
    model?: string;
    variant?: string;
    year?: string;
    color?: string;
    no_of_owner?: string;
    vehicle_additional_details?: {
      engine_no: string;
      chassis_no: string;
    };
    km_driven: { value: string; image: any };
    pricing?: {
      purchase_price: string;
      refurbishment_cost: string;
      selling_price: string;
    };
  };
  registration_number?: string;
}

export type SearchParams = {
  step: VehicleListingSteps;
  type?: VehicleListingStepsType;
};

export type SelectedCurrentStepComponentProps = {
  vehicleListing: IVehicleListing;
  setVehicleListing: React.Dispatch<React.SetStateAction<IVehicleListing>>;
  setSearchParams: (params: SearchParams) => void;
  type?: VehicleListingStepsType;
};

export const initialDataNotFoundState = {
  steps: {
    brand: "",
    year: "",
    model: "",
    variant: "",
    color: "",
    no_of_owner: "",
    vehicle_additional_details: {
      engine_no: "",
      chassis_no: "",
    },
    km_driven: { value: "", image: "" },
    pricing: { purchase_price: "", refurbishment_cost: "", selling_price: "" },
  },
  registration_number: "",
};

export const initialDataFoundState = {
  steps: {
    km_driven: { value: "", image: "" },
    pricing: { purchase_price: "", refurbishment_cost: "", selling_price: "" },
  },
  registration_number: "",
};

export const vehicleListingSteps = [
  {
    title: "Brand",
    type: "brand",
  },
  {
    title: "Model",
    type: "model",
  },
  {
    title: "Variant",
    type: "variant",
  },
  {
    title: "Year",
    type: "year",
  },
  {
    title: "Color",
    type: "color",
  },
  {
    title: "Owner",
    type: "no_of_owner",
  },
  {
    title: "Vehicle Details",
    type: "vehicle_additional_details",
  },
  {
    title: "KMs Driven",
    type: "km_driven",
  },
  {
    title: "Pricing",
    type: "pricing",
  },
];

export const Title: {
  [key in VehicleListingSteps]: JSX.Element;
} = {
  brand: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Brand</span> of
      <br />
      your two-wheeler
    </p>
  ),
  model: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Model</span> of
      <br />
      your two-wheeler
    </p>
  ),
  variant: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Variant</span> of
      <br />
      your two-wheeler
    </p>
  ),
  year: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Year</span> of
      <br />
      your two-wheeler
    </p>
  ),
  color: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Colour</span> of
      <br />
      your two-wheeler
    </p>
  ),
  no_of_owner: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">No. of Owners</span> of
      <br />
      your two-wheeler
    </p>
  ),
  vehicle_additional_details: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      <span className="text-primaryA2">Vehicle</span> Details
    </p>
  ),
  km_driven: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Enter <span className="text-primaryA2">KMs Driven</span>
    </p>
  ),
  pricing: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Enter <span className="text-primaryA2">Pricing Details</span>
    </p>
  ),
};

export const noOfOwner: {
  displayValue: string;
  icon: any;
  value: string;
}[] = [
  {
    displayValue: "1st Owner",
    icon: firstOwner,
    value: "1",
  },
  {
    displayValue: "2nd Owner",
    icon: secondOwner,
    value: "2",
  },
  {
    displayValue: "3rd Owner",
    icon: thirdOwner,
    value: "3",
  },
];

export const getFofoVehicleListingSteps = ({
  type,
}: {
  type: VehicleListingStepsType;
}) => {
  const automaticSteps = ["km_driven", "pricing"];
  if (type === "automatic") {
    return vehicleListingSteps.filter((step) =>
      automaticSteps.includes(step.type),
    ); // Return  "KMs Driven & pricing" steps for automatic type
  } else {
    return vehicleListingSteps; // Return all steps for manual type
  }
};

export const parseSearchParams = ({
  currentStep,
  type,
  navigate,
}: {
  currentStep: VehicleListingSteps;
  type: VehicleListingStepsType;
  navigate: any;
}) => {
  const doesStepExists = vehicleListingSteps.some(
    (obj) => obj.type === currentStep,
  );
  const doesTypeExists = ["automatic", "manual"].includes(type);

  if (!doesStepExists || !doesTypeExists) {
    navigate("/dealer/vehicles");
  }
};

export const validatePricing = (
  pricing: {
    purchase_price: string;
    refurbishment_cost: string;
    selling_price: string;
  },
  setErrors: any,
) => {
  const validations = [
    {
      condition:
        pricing?.purchase_price === "" ||
        parseInt(pricing?.purchase_price || "0") <
          FOFO_PROCUREMENT_PRICE_WITH_MAX_LIMIT,
      errorKey: "purchase_price",
      errorMessage: "Purchase Price cannot be less than 5000",
    },
    {
      condition:
        parseInt(pricing?.refurbishment_cost || "0") >=
        parseInt(pricing?.purchase_price || "0"),
      errorKey: "refurbishment_cost",
      errorMessage: "Refurbishment Cost cannot be greater than Purchase Price",
    },
    {
      condition: pricing?.refurbishment_cost === "",
      errorKey: "refurbishment_cost",
      errorMessage: "Refurbishment Cost is required",
    },
    {
      condition:
        pricing?.selling_price === "" ||
        parseInt(pricing?.selling_price || "0") <
          FOFO_PROCUREMENT_PRICE_WITH_MAX_LIMIT,
      errorKey: "selling_price",
      errorMessage: "Selling Price cannot be less than 5000",
    },
    {
      condition:
        parseInt(pricing?.refurbishment_cost) >
        parseInt(pricing?.selling_price),
      errorKey: "selling_price",
      errorMessage:
        "Selling Price should be greater than Cost of Refurbishment",
    },
  ];

  let hasErrors = false;

  validations.forEach(({ condition, errorKey, errorMessage }) => {
    if (condition) {
      setErrors((prev: any) => ({
        ...prev,
        [errorKey]: errorMessage,
      }));
      hasErrors = true;
    }
  });

  return hasErrors;
};

export const validateVehicleAdditionalDetails = (
  vehicleAdditionalDetails: {
    engine_no: string;
    chassis_no: string;
  },
  setErrors: any,
) => {
  const validations = [
    {
      condition: vehicleAdditionalDetails?.engine_no === "",
      errorKey: "engine_no",
      errorMessage: "Engine Number is required",
    },
    {
      condition: vehicleAdditionalDetails?.chassis_no === "",
      errorKey: "chassis_no",
      errorMessage: "Chassis Number is required",
    },
  ];

  let hasErrors = false;

  validations.forEach(({ condition, errorKey, errorMessage }) => {
    if (condition) {
      setErrors((prev: any) => ({
        ...prev,
        [errorKey]: errorMessage,
      }));
      hasErrors = true;
    }
  });

  return hasErrors;
};
