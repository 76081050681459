import Navbar from "@/components/Navbar";
import { useEffect, useState } from "react";
import HeroGIFBanner from "../Static/components/HeroGIFBanner";
import Footer from "@/components/Footer";
import MediaSkeletonLoader from "@/components/SkeletonLoaders/Blogs";
import FAQs from "@/components/FAQs";
import { mediaFAQsData } from "@/components/FAQs/data";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useGetAllMediaQuery } from "@/store/services/media";
import MediaCard from "@/components/Media/MediaCard";
import { track } from "@/utils/mixpanel/actions.ts";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";
import { useSelector } from "react-redux";

function Media() {
  const user = useSelector((state: any) => state.user.currentUser);

  const { city } = useCurrentCity();

  const { data, isFetching } = useGetAllMediaQuery({
    params: {
      "populate[0]": "cover",
      "populate[1]": "article_source_icon",
    },
  });

  const [media, setMedia] = useState<IMedia[]>([]);

  useEffect(() => {
    if (data && data.data) {
      setMedia(data.data);
    }
  }, [data]);

  const skeletonLoader = Array.from({ length: 6 }, (_, index) => (
    <MediaSkeletonLoader key={index} />
  ));

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: "Media",
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  return (
    <section className="bg-neutral30">
      <Navbar showSearchbar={false} backgroundColor="bg-neutral900" />

      <div className="pt-[70px] relative">
        <HeroGIFBanner title="Press Release" showDriveXFilledIcon={false} />
      </div>

      <div className="w-full p-4 max-w-7xl mx-auto">
        <h1 className="text-xl font-semibold ">Latest news</h1>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-5 ">
          {isFetching
            ? skeletonLoader
            : media.map((media: IMedia) => (
                <MediaCard key={media.id} media={media} />
              ))}
        </div>
      </div>
      <div className="mt-4 p-4 pb-12">
        <FAQs content={mediaFAQsData} handleFAQClick={handleFAQClick} />
      </div>

      <div className=" bg-neutral900">
        <Footer />
      </div>
    </section>
  );
}

export default Media;
