import { ProcessingIcon } from "@/assets/Icons";
import Image from "@/components/ui/image";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";
import React from "react";

const ImageProcessing = () => {
  const domyShootImage = `${IMAGE_KIT_BASE_URL}/DMX/domyshoot.png`;

  return (
    <div className="flex flex-col gap-3 p-4 bg-violet50 rounded-md md:w-[25rem] mx-4 pt-2">
      <div className="flex gap-1 items-center bg-white rounded-md px-2 py-1 w-fit">
        <ProcessingIcon />
        <p className="text-sm font-semibold">Processing Clicked Images</p>
      </div>

      <div className="flex gap-2">
        <span className="w-[6rem] h-fit rounded-md">
          <Image
            transform="f-webp"
            src={domyShootImage}
            alt={`DoMyShoot image`}
            className="object-cover object-top w-fit h-fit"
          />
        </span>
        <p className="text-sm font-base">
          We are processing the images you captured on Do my Shoot. It will be
          done within 1-2 days and vehicle listed on the website
        </p>
      </div>
    </div>
  );
};

export default ImageProcessing;
