import { IS_CLIENT_SIDE } from "./constants";

export const parseQueryString = (queryString: string) => {
  if (!queryString.trim()) {
    return {};
  }

  const categories = queryString.split("::");
  const parsed: { [key: string]: string } = {};
  categories.forEach((category: string) => {
    const [key, value] = category.split(":");
    parsed[key] = value;
  });
  return parsed;
};

export const removeDuplicateSearchParams = (url: string) => {
  const [basePath, queryString] = url.split("?");

  if (!queryString) return url;

  const params = queryString.split("&");
  const queryParams = new Set([...params]);

  const updatedParams = Array.from(queryParams);
  const updatedQueryString = updatedParams.join("&");

  return `${basePath}?${updatedQueryString}`;
};

export const redirectionRoutes = [
  {
    from: "/used-vehicle-lists/Bangalore",
    to: "/Bangalore/buy-two-wheelers",
  },
  {
    from: "/used-vehicle-lists/Coimbatore",
    to: "/Coimbatore/buy-two-wheelers",
  },
  { from: "/used-vehicle-lists/Chennai", to: "/Chennai/buy-two-wheelers" },
];

export const checkPathname = (path: string) => {
  return IS_CLIENT_SIDE && window.location.pathname.includes(path);
};

export const getPlaceholder = () => {
  switch (true) {
    case checkPathname("/dealer/sold-vehicles"):
      return "Search sold vehicles";
    case checkPathname("/dealer/vehicles"):
      return "Search bikes in your store";
    default:
      return "Search by brand or model";
  }
};

// This array contains URLs that include 'dealer' in their paths but are part of ReachX.
const dealerPathExceptions = ["/become-a-dealer"];

export const isDealerPath = () => {
  return dealerPathExceptions.includes(location.pathname)
    ? null
    : location.pathname.includes("dealer") && "Dealer";
};
