import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import React from "react";
import { mode_of_payment } from "./data";
import { cn } from "@/lib/utils";
import { DownloadIcon } from "@/assets/Icons";

const PaymentModes = ({ orderDetails, handleDownload }: any) => {
  const paymentModesData = orderDetails?.payments_info?.map(
    (info: { mode_of_payment: string }) => {
      const getLabel = mode_of_payment.options.find(
        (option: { value: any }) => {
          if (option.value === "finance") {
            return info.mode_of_payment.toLowerCase().includes(option.value);
          } else {
            return option.value === info.mode_of_payment.toLowerCase();
          }
        },
      )?.label;

      return {
        ...info,
        label: getLabel,
      };
    },
  );

  return (
    <div className="flex flex-col gap-2">
      <label htmlFor="payments" className="text-neutral300 text-sm">
        Payments
      </label>

      <Accordion
        type="multiple"
        className="flex flex-col border rounded-md border-neutral50"
      >
        {paymentModesData?.map((mode: any, index: number) => (
          <AccordionItem
            key={index}
            value={`${index}`}
            className={cn(
              "flex flex-col rounded-md border-b last:border-b-0 bg-white flex-between py-0 mx-3.5",
            )}
          >
            <AccordionTrigger className="py-3">
              <div className="flex justify-between w-full text-sm">
                <p className="text-black">{mode.label}</p>
                <p className="text-black">
                  {" "}
                  ₹ {mode.amount.toLocaleString("en-IN")}
                </p>
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col w-full gap-2 overflow-auto">
              <div className="flex justify-between w-full">
                <label htmlFor="Total Amount" className="text-neutral300">
                  Total Amount
                </label>
                <p> ₹ {mode.amount.toLocaleString("en-IN")}</p>
              </div>

              <div className="flex justify-between w-full">
                <label htmlFor="Reference ID" className="text-neutral300">
                  Reference ID
                </label>
                <p>{mode.payment_reference_id}</p>
              </div>

              <div className="flex justify-between w-full">
                <label htmlFor="Payment Proof" className="text-neutral300">
                  Payment Proof
                </label>
                <p
                  onClick={() =>
                    handleDownload(
                      "proof_of_payment",
                      false,
                      "",
                      `${mode?.proof_of_payment_path?.split(".")[1]}`,
                      `${mode?.proof_of_payment_path}`,
                    )
                  }
                  className="flex gap-1 text-primaryA2 items-center"
                >
                  <DownloadIcon fill="#E63C32" size={16} /> Download
                </p>
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default PaymentModes;
