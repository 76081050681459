import { cn } from "@/lib/utils";
import { useCallback } from "react";
import { noOfOwner } from "../data";
import Image from "@/components/ui/image";

function SelectOwner({
  vehicleListing,
  setVehicleListing,
  setSearchParams,
  type,
}: any) {
  const handleOwnerClick = useCallback(
    (owner: string) => {
      setSearchParams({ step: "vehicle_additional_details", type });
      setVehicleListing({
        ...vehicleListing,
        steps: {
          ...vehicleListing.steps,
          no_of_owner: owner,
        },
      });
    },
    [type, vehicleListing],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {noOfOwner.map((owner) => {
        return (
          <div
            key={owner.displayValue}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center flex justify-between items-center",
              vehicleListing.steps.no_of_owner === owner.value
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleOwnerClick(owner.value)}
          >
            {owner.displayValue}
            <Image src={owner.icon} alt={owner.displayValue} />
          </div>
        );
      })}
    </div>
  );
}

export default SelectOwner;
