import { Button } from "@/components/ui/button";

type IProps = {
  productResult: {
    brand: string;
    model: string;
    manufactured_year: string;
    number_of_owners: string;
    engine_number: string;
    chassis_number: string;
  };
  handleDataFound: () => void;
  handleEditDetails: () => void;
};

const DataAvailable = ({
  productResult,
  handleDataFound,
  handleEditDetails,
}: IProps) => {
  const vehicleDetails = [
    { label: "Vehicle Brand", value: productResult?.brand },
    { label: "Make Model", value: productResult?.model },
    {
      label: "Registration Year",
      value: productResult?.manufactured_year,
    },
    { label: "No of Owners", value: productResult?.number_of_owners },
    { label: "Engine No", value: productResult?.engine_number },
    { label: "Chassis No", value: productResult?.chassis_number },
  ];

  return (
    <div className="flex flex-col gap-4 h-full md:justify-between">
      <div className="flex flex-col gap-4">
        {vehicleDetails.map((detail) => (
          <div key={detail.label} className="grid grid-cols-2">
            <p className="text-xs font-medium text-neutral300">
              {detail.label}
            </p>
            <p className="text-sm font-semibold">{detail.value}</p>
          </div>
        ))}
      </div>

      <div className="flex gap-2 md:mt-auto">
        <Button
          onClick={handleEditDetails}
          variant="outline"
          className="text-primaryA2 text-base w-full py-5 hover:text-primaryA2 border border-orange-500"
        >
          Edit Details
        </Button>
        <Button
          onClick={handleDataFound}
          variant="outline"
          className="bg-primaryA2 text-white text-base w-full py-5 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
        >
          Add Vehicle
        </Button>
      </div>
    </div>
  );
};

export default DataAvailable;
