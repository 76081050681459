import { isDealerMode } from "@/utils/constants";
import { isDealerPath, redirectionRoutes } from "@/utils/url";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectionHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: any) => state.user.currentUser);
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");

  useEffect(() => {
    const currentPath = location.pathname.replace(/\/$/, "");
    const rule = redirectionRoutes.find((r) => r.from === currentPath);

    if (rule && currentPath !== rule.to) {
      navigate(rule.to, { replace: true });
    }

    if (!isUserLoggedIn && !user && isDealerPath()) {
      navigate("/dealer");
    }

    // if user is in a reachx page & wants to go to a dealer route via url, This redirects to a dealer route and toggles dealer mode automatically.
    if (!isDealerMode && isDealerPath() && isUserLoggedIn) {
      if (user?.roles?.length > 1) {
        localStorage.setItem("role", user?.roles[0]);
        window.location.href = location.pathname;
      }
      return;
    }
  }, [location.pathname, user?.roles]);

  return null;
};

export default RedirectionHandler;
