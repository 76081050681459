import FlowStepper from "@/components/FlowStepper";
import { useCallback, useEffect, useState } from "react";
import {
  SearchParams,
  Title,
  getServiceFlowSteps,
  initialManualState,
  parseSearchParams,
} from "../data";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useServiceFlowSteps from "./useServiceFlowSteps";
import useNavigateWithFallback from "@/hooks/useNavigateWithFallback";
import { cn } from "@/lib/utils";
import { ChevronLeft as LeftIcon } from "lucide-react";
import { useScreenDetector } from "@/hooks/useScreenDetector";

const ServiceFlow = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setSearchParamsWithType = (params: SearchParams) => {
    setSearchParams(params);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useScreenDetector();
  const navigateWithFallback = useNavigateWithFallback();
  const locationState = location.state;
  const currentStep = searchParams.get("step") as ServiceFlowSteps;
  const type = searchParams.get("type") as ServiceFlowStepsType;
  const serviceFlowSteps = getServiceFlowSteps({ type, currentStep });

  const [serviceFlowData, setServiceFlowData] = useState(initialManualState);

  useEffect(() => {
    const store_id =
      locationState?.store_id ||
      locationState?.vehicleDetails?.store_id ||
      serviceFlowData.store_id;

    parseSearchParams({
      currentStep,
      type,
      navigateWithFallback,
      store_id,
    });
  }, [currentStep, type, locationState, serviceFlowData]);

  const { renderCurrentStep } = useServiceFlowSteps({
    type,
    currentStep,
    serviceFlowData,
    setServiceFlowData,
    setSearchParams,
  });

  const isCurrentStepComplete = (currentStep: ServiceFlowSteps) => {
    const stepData = serviceFlowData.steps[currentStep];
    if (typeof stepData === "object") {
      return Object.values(stepData).every((value) => value !== "");
    }
    return stepData !== "";
  };

  const handleBackClick = useCallback(() => {
    const currentStepIndex = serviceFlowSteps.findIndex(
      (p) => p.type === currentStep,
    );

    if (currentStep === serviceFlowSteps[0].type) {
      navigate("/service");
      setServiceFlowData(initialManualState);
    } else {
      const previousStep = serviceFlowSteps[currentStepIndex - 1];

      setSearchParamsWithType({
        step: previousStep.type,
        type,
      });
    }
  }, [currentStep, serviceFlowSteps, type]);

  const handlePillClick = (step: { type: ServiceFlowSteps }) => {
    if (!isCurrentStepComplete(step.type)) return;

    setSearchParamsWithType({ step: step.type, type });
    setServiceFlowData({
      steps: {
        ...serviceFlowData.steps,
      },
      store_id: serviceFlowData.store_id,
      store_address: serviceFlowData.store_address,
      ...(type === "automatic" && {
        registration_number: serviceFlowData.registration_number,
      }),
    });
  };

  return (
    <FlowStepper
      headerContent={
        <div
          className={cn(
            "flex gap-2 pt-4 px-4 md:px-0 max-w-screen-md mx-auto w-full cursor-pointer",
            isMobile ? "mt-0" : "mt-20",
          )}
          onClick={handleBackClick}
        >
          <LeftIcon />
          <p className="text-base text-neutral900">Book a service</p>
        </div>
      }
      onPillClick={(step) => {
        handlePillClick(step as { type: ServiceFlowSteps });
      }}
      isStepComplete={(step: string) =>
        isCurrentStepComplete(step as ServiceFlowSteps)
      }
      steps={serviceFlowSteps}
      activeStep={currentStep}
      stepTitles={Title}
      renderActiveStep={renderCurrentStep}
      showNavbar={!isMobile}
    />
  );
};

export default ServiceFlow;
