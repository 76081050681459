import { Skeleton } from "@/components/ui/skeleton";

const DresmaImageStatusLoader = () => {
  return (
    <div className="flex px-4 py-2 w-full">
      <Skeleton className="h-[8rem] w-full rounded-md shadow-sm bg-neutral-200" />
    </div>
  );
};

export default DresmaImageStatusLoader;
