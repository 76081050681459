import { Link, useLocation } from "react-router-dom";
import { DmxMoreOptions, moreOptions } from "./data";
import { cn } from "@/lib/utils";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useLazyGetServicePartsAuthTokenQuery } from "@/store/services/Dmx/storeVehicles";
import RBAC from "../RBAC";

interface IProps {
  dealerMode: boolean;
  dealerLeadsUrl?: string;
}

const MoreOptions = ({ dealerMode, dealerLeadsUrl }: IProps) => {
  const location = useLocation();
  const allOptions = dealerMode ? DmxMoreOptions : moreOptions;
  const store = useSelector((state: any) => state.store.store);
  const user = useSelector((state: any) => state.user.currentUser);
  const [getServicePartsUrlToken] = useLazyGetServicePartsAuthTokenQuery();

  const dmsAuthToken = JSON.parse(
    localStorage.getItem("dms_auth_token") || "null",
  );

  useEffect(() => {
    const fetchToken = async () => {
      const result = await getServicePartsUrlToken({
        store_id: store?.store_id,
      });

      if (result.data) {
        const servicePartsToken = result?.data?.data?.token;
        const payload = {
          storeId: store?.store_id,
          token: servicePartsToken,
        };
        localStorage.setItem("dms_auth_token", JSON.stringify(payload));
      }
    };

    if (dmsAuthToken?.storeId !== store?.store_id) fetchToken();
  }, [store?.store_id]);

  const handleNavigate = ({
    e,
    option,
  }: {
    e: React.MouseEvent;
    option: any;
  }) => {
    if (option.link != "") return;
    e.preventDefault();

    if (option.action) {
      // Perform individual action based on the selected type
      option.action({ store, user, dealerLeadsUrl });
    }
  };

  return (
    <>
      {allOptions
        .filter((option) => !(option.hide && option.hide(store)))
        .map((options, index) => {
          const isActive = location.pathname === options.link;
          return (
            <RBAC enabledFor={options.enabledFor || []} key={index}>
              <Link
                data-test-id={`sidebar-${options.text.replaceAll(" ", "-").toLowerCase()}`}
                className={cn(
                  "flex items-center gap-3 p-4 px-2",
                  isActive && dealerMode && "bg-neutral30 rounded-lg",
                )}
                onClick={(e) => {
                  handleNavigate({
                    e,
                    option: options,
                  });
                }}
                to={options.link || ""}
              >
                {dealerMode ? options.icon(isActive) : options.icon}
                <p className="font-semibold text-sm">{options.text}</p>
              </Link>
            </RBAC>
          );
        })}
    </>
  );
};

export default MoreOptions;
