import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import Otp from "@/pages/Auth/Otp";
import { createCode } from "supertokens-web-js/recipe/passwordless";
import { DX_CUSTOMER_VERIFICATION_SUCCESSFUL } from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import { useSearchParams } from "react-router-dom";

const CustomerDetail = ({
  dynamicFields,
  renderCustomComponent,
  formData,
  errors,
  handleInputChange,
  partnerType,
  otp,
  setOtp,
  modelName,
  commonEventProperties,
  setIsOtpVerified,
  isOtpVerified,
  orderDetails,
  validateFields,
}: any) => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");

  // Extract all the stepType of customer_details from the dynamicFields
  const customerDetailsFields = dynamicFields?.filter(
    (field: { stepType: string }) => field.stepType === "customer_details",
  );

  // Check if the name attribute of all required customer detail fields are present in formData and it has a value in it rather than being empty
  const areAllRequiredFieldsFilled = customerDetailsFields
    ?.filter((detail: { required: boolean }) => detail.required)
    ?.every(
      (field: any) =>
        formData?.[field?.name] && formData?.[field?.name].trim() !== "",
    );

  useEffect(() => {
    setIsOtpSent(false);
    setErrorMsg("");
  }, [formData?.customer_phone_number]);

  const customSuccessHandlerForOtp = () => {
    setIsOtpVerified(true);
    if (validateFields()) {
      setSearchParams({ type: type || "", step: "pricing" });
    }
  };

  async function sendOTP() {
    if (formData?.customer_phone_number?.length < 10) return;

    if (areAllRequiredFieldsFilled) {
      setShowOtp(true);
      setErrorMsg("");
    } else {
      setErrorMsg("Please fill out all required fields to proceed.");
      return;
    }

    try {
      const response = await createCode({
        phoneNumber: `+91${formData?.customer_phone_number}`,
        userContext: {
          modelName: modelName ? modelName : undefined,
          isOnlyOTPVerification: true,
        },
        options: {
          preAPIHook: async (context) => {
            const payload = context.userContext;
            let requestInit = context.requestInit;

            const body = {
              ...JSON.parse(requestInit.body?.toString() || "{}"),
              ...payload,
            };

            requestInit = {
              ...requestInit,
              body: JSON.stringify(body),
            };
            return { url: context.url, requestInit };
          },
        },
      });

      if (response.status === "OK") {
        setIsOtpSent(true);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        if (err.message === "Phone number is invalid")
          setErrorMsg("Invalid number");
      } else {
        console.log("err", err);
      }
    }
  }

  return (
    <div className="my-4 flex flex-col gap-5 overflow-auto no-scrollbar">
      {dynamicFields
        ?.filter(
          (field: { stepType: string }) =>
            field.stepType === "customer_details",
        )
        .map((field: any) => {
          return (
            <div key={field.name} className="flex flex-col gap-2 relative">
              {field.label && (
                <div className="flex justify-between">
                  <label
                    htmlFor={field.name}
                    className="text-sm font-semibold flex gap-0.5"
                  >
                    {field.label}
                    {field.required && <p className="text-red-600">*</p>}
                  </label>
                </div>
              )}
              {field.type === "textarea" ? (
                <Textarea
                  className={cn(
                    "bg-neutral30 h-24 mb-2 focus-visible:ring-0 focus-visible:ring-offset-0",
                    errors[field.name] && "bg-red-100",
                  )}
                  name={field.name}
                  placeholder={field.placeholder}
                  maxLength={field.maxLength}
                  value={formData[field.name as keyof typeof formData] || ""}
                  onChange={handleInputChange}
                />
              ) : field.type === "custom" ? (
                renderCustomComponent(field)
              ) : (
                <div
                  className={cn(
                    "flex items-center px-4 rounded-md bg-neutral30 border-none no-scrollbar",
                    errors[field.name] && "bg-red-100",
                    !field?.prefill && "none p-0",
                  )}
                >
                  {field?.prefill && (
                    <span className="text-sm">{field.prefill}</span>
                  )}
                  <Input
                    className={cn(
                      "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                      errors[field.name] && "bg-red-100",
                    )}
                    name={field.name}
                    placeholder={field.placeholder}
                    type={field.type}
                    required={field.required}
                    value={formData[field.name as keyof typeof formData] || ""}
                    onChange={handleInputChange}
                    disabled={
                      field.disabled &&
                      (partnerType === field.disabled ||
                        field.disabled === orderDetails?.order_status)
                    }
                    maxLength={field.maxLength}
                  />
                  {field?.prefill && (
                    <span
                      onClick={sendOTP}
                      className={cn(
                        "text-xs text-nowrap text-primaryA2",
                        isOtpSent && "text-neutral300 pointer-events-none",
                      )}
                    >
                      Send OTP
                    </span>
                  )}
                </div>
              )}
              {typeof errors[field.name] === "string" && (
                <span className="text-xs text-red-400">
                  {errors[field.name]}
                </span>
              )}
              {field.description && (
                <p className="text-neutral300 text-sm">{field.description}</p>
              )}
            </div>
          );
        })}

      {!isOtpSent && errorMsg !== "" && !areAllRequiredFieldsFilled && (
        <p className="text-red600 text-sm">{errorMsg}</p>
      )}

      {showOtp && orderDetails?.order_status !== "RESERVED" && (
        <div className="flex flex-col gap-5 mb-2">
          <Otp
            userDetails={{
              phoneNumber: formData?.customer_phone_number,
              name: formData?.customer_name,
            }}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            otp={otp}
            setOtp={setOtp}
            searchParams={searchParams}
            isOnlyOTPVerification={true}
            modelName={modelName}
            eventProperties={commonEventProperties}
            eventName={DX_CUSTOMER_VERIFICATION_SUCCESSFUL}
            handleEdit={() => {}}
            showEdit={false}
            showResend={isOtpSent && !isOtpVerified}
            customSuccessHandlerForOtp={customSuccessHandlerForOtp}
            navigateOnLogin={false}
            clearOtp={false}
            inputOtpSlotClassName={"!w-16"}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerDetail;
