import Navbar from "../Navbar";
import { cn } from "@/lib/utils";
import { ReactElement, useEffect } from "react";
import Pill from "../Pill";
import { TickIconFilled } from "@/assets/Icons";
import { Helmet } from "react-helmet-async";

type FlowStepperProps = {
  headerContent?: ReactElement;
  onPillClick: (step: { type: string; title: string }) => void;
  isStepComplete: (step: string) => boolean;
  showNavbar?: boolean;
  steps: {
    type: string;
    title: string;
  }[];
  activeStep: string;
  vehicleDetails?: ReactElement;
  customPillStyles?: string;
  customChildPillStyles?: string;
  stepTitles?: {
    [key: string]: ReactElement;
  };
  renderActiveStep: () => ReactElement;
  CTA?: ReactElement;
};

const FlowStepper = ({
  headerContent,
  onPillClick,
  isStepComplete,
  steps,
  activeStep,
  vehicleDetails,
  stepTitles,
  renderActiveStep,
  showNavbar = true,
  customPillStyles,
  customChildPillStyles,
  CTA,
}: FlowStepperProps) => {
  const activeStepIndex =
    steps.findIndex((step) => step.type === activeStep) + 1;

  const scrollToActivePill = (stepType: string) => {
    const activePillElement = document.getElementById(`step_${stepType}`);
    if (activePillElement) {
      activePillElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    scrollToActivePill(activeStep);
  }, [activeStep]);

  return (
    <section className="flex flex-col bg-neutral30 h-dvh">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      {showNavbar && (
        <Navbar
          showLocation={false}
          showSearchbar={false}
          showLinks={false}
          backgroundColor="bg-white"
        />
      )}

      {headerContent}

      <div
        className={cn(
          "flex overflow-x-scroll whitespace-nowrap mt-6 no-scrollbar px-4 md:px-0 max-w-screen-md mx-auto w-full min-h-[30px]",
          customPillStyles,
        )}
      >
        {steps.map((step) => {
          return (
            <Pill
              id={`step_${step.type}`}
              key={step.type}
              name={step.title}
              className={cn(
                "border shadow-sm px-4 py-2 mr-4 bg-white rounded-lg cursor-pointer",
                customChildPillStyles,
                activeStep === step.type && "border-primaryA1",
              )}
              childClassName={cn(
                "bg-white text-base font-semibold",
                activeStep === step.type || isStepComplete(step.type)
                  ? "text-primaryA1"
                  : "text-neutral300",
              )}
              rightHalf={isStepComplete(step.type) && <TickIconFilled />}
              handleClick={() => {
                onPillClick(step);
                scrollToActivePill(step.type);
              }}
            />
          );
        })}
      </div>

      {vehicleDetails}

      <div className="border m-4 px-4 pb-4 rounded-lg shadow-sm h-full bg-white overflow-y-scroll no-scrollbar relative md:max-w-screen-md md:mx-auto md:w-full flex flex-col gap-4">
        {stepTitles && (
          <div className="flex justify-between items-center sticky top-0 bg-white pt-4 z-10">
            {stepTitles?.[activeStep]}
            <p className="text-base text-neutral-90 font-semibold leading-6">
              {activeStepIndex}/{steps.length}
            </p>
          </div>
        )}

        {renderActiveStep()}
      </div>

      {CTA}
    </section>
  );
};

export default FlowStepper;
