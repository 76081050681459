import { EditIcon, SingleDocumentIcon } from "@/assets/Icons";
import PendingMessage from "../PendingMessage";
import { cn } from "@/lib/utils";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import DocumentItem from "./DocumentItem";
import { mode_of_payment } from "@/DmxPages/StoreDetail/data";
import { convertToDDMMYYYY, formatUTCDate } from "@/utils/date";
import { useSearchParams } from "react-router-dom";

const getPaymentModeLabel = (value: string) => {
  const option = mode_of_payment.options.find(
    (opt: { value: string }) => opt.value.toLowerCase() === value.toLowerCase(),
  );
  return option ? option.label : value;
};

const CustomerDetail = ({
  orderDetails,
  handleDownload,
  productDetail,
}: any) => {
  const { isDesktop } = useScreenDetector();
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");

  const isVehicleReserved =
    productDetail?.status === "reserved" &&
    ((orderDetails?.order_status === "RESERVED" &&
      orderDetails?.booking_receipt_path === null) ||
      orderDetails?.order_status === "IN_REVIEW");

  // to ensure we dont display duplicate payment modes
  const uniquePaymentModes = Array.from(
    new Set(
      orderDetails?.payments_info?.map((payment: { mode_of_payment: string }) =>
        getPaymentModeLabel(payment?.mode_of_payment),
      ),
    ),
  ).join(", ");

  const documents = [
    {
      isAvailable:
        ["RESERVED", "COMPLETED"].includes(orderDetails?.order_status) &&
        orderDetails?.booking_receipt_path,
      icon: SingleDocumentIcon,
      title: "Booking Receipt",
      size: "1.3 MB",
      handleDownload: () => handleDownload("booking_receipt"),
      shareMessage: () =>
        handleDownload(
          "booking_receipt",
          true,
          `Congrats! The ${orderDetails?.vehicleName} is booked. Here is your booking receipt:`,
        ),
    },
    {
      isAvailable:
        orderDetails?.order_status === "COMPLETED" &&
        orderDetails?.delivery_note_path,
      icon: EditIcon,
      title: "Delivery Note",
      size: "3.1 MB",
      handleDownload: () => handleDownload("delivery_note"),
      shareMessage: () =>
        handleDownload(
          "delivery_note",
          true,
          `Congrats! The ${orderDetails?.vehicleName} is officially yours. Here is your delivery note:`,
        ),
    },
    {
      isAvailable:
        orderDetails?.order_status === "COMPLETED" && orderDetails?.invoice_pdf,
      icon: SingleDocumentIcon,
      title: "Invoice",
      size: "194 KB",
      handleDownload: () => handleDownload("invoice"),
      shareMessage: () =>
        handleDownload(
          "invoice",
          true,
          `Congrats! The ${orderDetails?.vehicleName} is officially yours. Here is your purchase invoice:`,
        ),
    },
  ];

  const getCustomerDetails = () => {
    const date_of_booking = orderDetails?.payments_info?.find(
      (item: { payment_mode: string }) => item.payment_mode === "ADVANCE",
    )?.payment_date;

    const date_of_sale = orderDetails?.order_updated_at;

    const customerDetails = [
      { label: "Customer Name", value: orderDetails?.customer_name },
      {
        label: "Phone Number",
        value: `+91 ${orderDetails?.customer_phone_number}`,
      },
      {
        label: "Email ID",
        value: orderDetails?.customer_email_id,
        className: "break-words",
      },
      {
        label: "Payment Mode",
        value: uniquePaymentModes,
        className: "break-words",
      },
      { label: "Address", value: orderDetails?.address },
    ];

    // Find the index of the Address field
    const addressIndex = customerDetails.findIndex(
      (item) => item.label === "Address",
    );

    // Insert Date of Sale if it exists
    if (
      date_of_sale &&
      step !== "documents" &&
      orderDetails?.order_status === "COMPLETED"
    ) {
      customerDetails.splice(addressIndex, 0, {
        label: "Date of Sale",
        value: formatUTCDate(date_of_sale),
        className: "break-words",
      });
    }

    // Insert Date of Booking if it exists
    if (date_of_booking && step !== "documents") {
      customerDetails.splice(addressIndex, 0, {
        label: "Date of Booking",
        value: convertToDDMMYYYY(
          date_of_booking?.split("-")?.reverse()?.join("-") || "",
        ),
        className: "break-words",
      });
    }

    return customerDetails;
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-6 border-b py-4">
        {orderDetails &&
          getCustomerDetails()?.map((detail, index) => (
            <div key={index} className="flex flex-col">
              <p className="text-neutral300">{detail.label}</p>
              <p className={cn("text-base", detail.className)}>
                {detail.value}
              </p>
            </div>
          ))}
      </div>

      {/* documents */}
      <div className="py-4 flex flex-col gap-6">
        {documents.map(
          (doc, index) =>
            doc.isAvailable && (
              <DocumentItem
                key={index}
                icon={doc.icon}
                title={doc.title}
                size={doc.size}
                handleDownload={doc.handleDownload}
                shareMessage={doc.shareMessage}
              />
            ),
        )}

        {/* Check if the order status is "COMPLETED" and if any document other than "Booking Receipt" is unavailable */}
        {/* Reason: In the case of a direct sale (without reservation), the booking receipt might be null.  */}
        {/* However, we don't want to show a pending message for it because: */}
        {/* 1. When a vehicle is reserved and then sold, the booking receipt is always generated. */}
        {/* 2. When a dealer directly sells a vehicle without reservation, the booking receipt will be null  */}
        {/* but this is expected and should not trigger a pending message. */}
        {orderDetails?.order_status === "COMPLETED" &&
          documents
            .filter((doc) => doc.title !== "Booking Receipt") // Exclude "Booking Receipt" from the check
            .some((doc) => !doc.isAvailable) && ( // Check if any of the remaining documents are unavailable
            <PendingMessage
              orderDetails={orderDetails}
              description={`We’re generating your ${documents
                .filter(
                  (doc) => doc.title !== "Booking Receipt" && !doc.isAvailable,
                ) // Exclude "Booking Receipt" from the description
                .map((doc) => doc.title.toLowerCase())
                .join(", ")}. It’ll be ready shortly!`}
            />
          )}
      </div>

      {/* pending message */}
      {isVehicleReserved && (
        <PendingMessage
          orderDetails={orderDetails}
          className={cn("py-4 my-4", isDesktop && "mb-0")}
        />
      )}
    </>
  );
};

export default CustomerDetail;
