import { DriveXFilledIcon } from "@/assets/Icons";
import Video from "@/components/ui/video";
import { EngineOil } from "@/utils/constants";

const HeroGIFBanner = ({
  title,
  showDriveXFilledIcon = true,
}: {
  title: string;
  showDriveXFilledIcon?: boolean;
}) => {
  return (
    <div className="flex-center flex-col relative">
      <Video src={EngineOil} className="w-full lg:h-96 h-80 object-cover" />
      <div className="absolute inset-0 bg-[#000000B2]" />
      <div className="flex-center flex-col absolute">
        {showDriveXFilledIcon && <DriveXFilledIcon size={64} color="#684499" />}
        <h1 className="text-2xl font-semibold text-center max-w-80 text-white mt-4">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default HeroGIFBanner;
