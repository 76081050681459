import { ContactUsOptions } from "./data";
import { Button } from "@/components/ui/button";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { ContactUsSupportEmail, supportContactNumber } from "@/utils/constants";
import { useNavigate } from "react-router-dom";

const SubmitQuery = () => {
  const { isMobile, isTablet, isDesktop } = useScreenDetector();
  const navigate = useNavigate();
  const handleContactUsClick = (data: string) => {
    const URL =
      data === supportContactNumber
        ? `tel:${supportContactNumber}`
        : `mailto:${ContactUsSupportEmail}`;
    window.open(URL, "_blank");
  };
  return (
    <div className="flex flex-col md:flex-row md:justify-around md:items-center md:py-10 max-w-4xl m-auto gap-4 bg-white rounded-lg p-4">
      <div className="flex flex-col gap-4">
        <p className="text-center md:text-left text-xl">
          Have <span className="text-primaryA2">More Questions?</span>
        </p>

        <p className="text-neutral300 text-center text-sm font-medium">
          Ask away!
        </p>

        {isMobile && (
          <div className="flex justify-around">
            {ContactUsOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleContactUsClick(option.action)}
                className="flex flex-col items-center gap-2"
              >
                {option.icon}
                <p>{option.text}</p>
              </div>
            ))}
          </div>
        )}

        {isMobile && (
          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-4 bg-neutral100 border-0 dark:bg-gray-700" />
            <span className="absolute px-3 font-medium text-neutral300 text-sm -translate-x-1/2 bg-white left-1/2">
              OR
            </span>
          </div>
        )}

        <Button
          onClick={() => navigate("/contactus")}
          variant="outline"
          className="bg-primaryA2 border-none md:w-[200px] text-white text-xs hover:bg-primaryA2 hover:text-white"
        >
          Submit query
        </Button>
      </div>

      {(isDesktop || isTablet) && (
        <div className="flex justify-around w-[50%] gap-4">
          {ContactUsOptions.map((option, index) => (
            <div
              key={index}
              onClick={() => handleContactUsClick(option.action)}
              className="flex flex-col items-center gap-2"
            >
              {option.icon}
              <p>{option.text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubmitQuery;
