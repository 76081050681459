import { Heart, HeartFilled } from "@/assets/Icons";
import { motion } from "motion/react";

type AnimatedWishListProps = {
  product: IVehicleInfo;
  wishList: number[] | undefined;
};

const AnimatedWishList = ({ product, wishList }: AnimatedWishListProps) => {
  return (
    <motion.div
      whileTap={{ scale: 0.8 }}
      transition={{
        type: "spring",
        stiffness: 600,
        duration: 0.2,
      }}
      style={{ cursor: "pointer" }}
    >
      {product && wishList?.includes(product?.vehicleId) ? (
        <HeartFilled size={25} />
      ) : (
        <Heart size={25} />
      )}
    </motion.div>
  );
};

export default AnimatedWishList;
