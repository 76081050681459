import React from "react";

const DataUnavailable = ({
  header,
  body,
  callToAction,
}: {
  header: React.ReactNode;
  body: React.ReactNode;
  callToAction?: React.ReactNode;
}) => {
  return (
    <>
      <div className="flex flex-col gap-2 bg-[#FFF4DF] p-4 rounded-sm">
        <div className="flex items-center gap-1">{header}</div>
        {body}
      </div>

      {callToAction}
    </>
  );
};

export default DataUnavailable;
