import { cn } from "@/lib/utils";
import { getPreviousNYears } from "@/utils/date";
import { useCallback } from "react";
import { FOFO_PROCUREMENT_YEAR_LIMIT } from "@/utils/constants";

function SelectYear({
  vehicleListing,
  setVehicleListing,
  setSearchParams,
  type,
}: any) {
  const handleYearClick = useCallback(
    (year: string) => {
      setSearchParams({ step: "color", type });
      setVehicleListing({
        ...vehicleListing,
        steps: {
          ...vehicleListing.steps,
          year,
        },
      });
    },
    [type, vehicleListing],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {getPreviousNYears(FOFO_PROCUREMENT_YEAR_LIMIT).map((year) => {
        return (
          <div
            key={year}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              Number(vehicleListing.steps.year) === year
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleYearClick(String(year))}
          >
            {year}
          </div>
        );
      })}
    </div>
  );
}

export default SelectYear;
