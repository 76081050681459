import { hydrateRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import { DataProvider } from "./context/useDataContext";
import React from "react";
import ErrorPage from "./pages/Home/Error";
import Providers from "./store/provider";
import * as Flagsmith from "flagsmith/react";
import flagsmith from "flagsmith";
import { Toaster } from "@/components/ui/toaster";
import { RoleProvider } from "./context/useRoleContext";

let data;

declare global {
  interface Window {
    __data__: any;
    __isMobile__: boolean;
    __isDesktop__: boolean;
  }
}

if (typeof window !== "undefined") {
  data = window?.__data__;
}

hydrateRoot(
  document.getElementById("root") as HTMLElement,
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage onFallback={true} />}>
      <Providers>
        <RoleProvider>
          <HelmetProvider>
            <Flagsmith.FlagsmithProvider
              options={{
                environmentID: `${import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID}`,
                api: `${import.meta.env.VITE_FLAGSMITH_API_URL}`,
              }}
              flagsmith={flagsmith}
            >
              <BrowserRouter>
                <DataProvider initialData={data}>
                  <App />
                </DataProvider>
              </BrowserRouter>
            </Flagsmith.FlagsmithProvider>
          </HelmetProvider>
        </RoleProvider>
        <Toaster />
      </Providers>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
