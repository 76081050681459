import {
  BikeIcon,
  CareersIcon,
  ColouredCalendar,
  DriveXIcon,
  FinanceIcon,
  HeadphoneIcon,
  Heart,
  KeyIcon,
  NoteIcon,
  NotepadIcon,
  WrenchIcon,
  PriceNote,
  UsersThreeIcon,
  WarningIcon,
} from "@/assets/Icons";
import { BookKeyIcon } from "lucide-react";
import { ALL_DMX_ROLES, CAREERS_URL, IS_CLIENT_SIDE } from "@/utils/constants";
import {
  DX_USER_SERVICE_PARTS_CLICKED,
  DX_USER_LEADS_CLICKED,
  DX_USER_GRIEVANCES_CLICKED,
} from "@/utils/mixpanel/DMXEvents/dx_hamburger_clicked_events";
import { track } from "@/utils/mixpanel/actions";

interface userDataType {
  icon: any;
  text: string;
  value: string;
}

export const userData: userDataType[] = [
  { icon: <ColouredCalendar />, text: "Booking", value: "buy" },
  { icon: <NotepadIcon />, text: "Sell Requests", value: "sell" },
  { icon: <Heart />, text: "Wishlist", value: "wishlist" },
  { icon: <HeadphoneIcon />, text: "Help & Support", value: "contactUs" },
];

export interface moreOptionsType {
  icon: any;
  text: string;
  link: string;
  enabledFor?: Roles[];
  hide?: (store: any) => boolean;
  action?: (store: any, user: any) => void;
}

export const moreOptions: moreOptionsType[] = [
  // { icon: <FinanceIcon />, text: "DriveX Finance" },
  {
    icon: <NoteIcon />,
    text: "Blogs",
    link: "/blogs",
  },
  {
    icon: <BookKeyIcon color="#919191" size={16} />,
    text: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    icon: <FinanceIcon />,
    text: "Terms of Service",
    link: "/terms",
  },
  { icon: <DriveXIcon />, text: "About DriveX", link: "/about-us" },
  { icon: <CareersIcon />, text: "Careers", link: CAREERS_URL },
];

const focusedIconStyle = { oneStopColor: "#E63C32", twoStopColor: "#F47823" };

const getCOCOStore = (store: any) => store === "coco";
const role = IS_CLIENT_SIDE && localStorage.getItem("role");

// Utility function for navigation with tracking in mixpanel event
const navigateUrl = ({
  eventName,
  targetUrl,
  user,
  store,
}: {
  eventName: string;
  targetUrl: any;
  user: any;
  store: any;
}) => {
  if (targetUrl) {
    // Track the event in MixPanel
    track(eventName, {
      name: user?.userName,
      phone_number: user?.phoneNumber,
      role: role,
      device_type: navigator.userAgent,
      store_name: store?.store_name,
      store_id: store?.store_id,
      store_location: store?.store_name,
      time: new Date().getTime(),
    });
    window.open(targetUrl, "_blank");
  }
};

export const DmxMoreOptions: moreOptionsType[] = [
  {
    icon: (focused: boolean) => (
      <BikeIcon
        size={18}
        oneStopColor="#919191"
        twoStopColor="#919191"
        url="sidebar_paint_bike"
        {...(focused && focusedIconStyle)}
      />
    ),
    text: "Floor stock",
    link: "/dealer/vehicles",
    enabledFor: ALL_DMX_ROLES,
  },
  {
    icon: (focused: boolean) => (
      <PriceNote
        size={18}
        oneStopColor="#919191"
        twoStopColor="#919191"
        url="dmx_sold_vehicles"
        {...(focused && focusedIconStyle)}
      />
    ),
    text: "Sold Vehicles",
    link: "/dealer/sold-vehicles",
    enabledFor: ALL_DMX_ROLES,
  },
  {
    icon: (focused: boolean) => (
      <WrenchIcon
        size={18}
        oneStopColor="#919191"
        twoStopColor="#919191"
        url="dmx_service_parts"
        {...(focused && focusedIconStyle)}
      />
    ),
    text: "Services & Parts",
    enabledFor: ["Dealer", "ServiceManager"],
    link: "",
    hide: (data) => getCOCOStore(data?.partner_type),
    action: ({ store, user }) => {
      const dmsBaseUrl = import.meta.env.VITE_DMS_BASE_URL;
      const dmsAuthToken =
        IS_CLIENT_SIDE &&
        JSON.parse(localStorage.getItem("dms_auth_token") || "null");

      const targetUrl = dmsAuthToken?.token
        ? `${dmsBaseUrl}/?authToken=${dmsAuthToken.token}`
        : undefined;
      navigateUrl({
        eventName: DX_USER_SERVICE_PARTS_CLICKED,
        targetUrl,
        user,
        store,
      });
    },
  },
  {
    icon: (focused: boolean) => (
      <UsersThreeIcon
        size={18}
        oneStopColor="#919191"
        twoStopColor="#919191"
        url="dmx_leads"
        {...(focused && focusedIconStyle)}
      />
    ),
    text: "Leads",
    link: "",
    enabledFor: ALL_DMX_ROLES,
    action: ({ store, user, dealerLeadsUrl }) => {
      navigateUrl({
        eventName: DX_USER_LEADS_CLICKED,
        targetUrl: dealerLeadsUrl,
        user,
        store,
      });
    },
  },
  {
    icon: (focused: boolean) => (
      <WarningIcon size={18} {...(focused && focusedIconStyle)} />
    ),
    text: "Grievances",
    enabledFor: ALL_DMX_ROLES,
    link: "",
    action: ({ store, user }) => {
      const targetUrl =
        "https://docs.google.com/forms/d/e/1FAIpQLSfCQZQQeSEnlc5fuBZgHMIa631LXKOOVYoec067gnliPpl6ww/viewform";
      navigateUrl({
        eventName: DX_USER_GRIEVANCES_CLICKED,
        targetUrl,
        user,
        store,
      });
    },
  },
];

interface BuySellType {
  icon: React.ReactNode;
  heading: string;
  text: string;
}

export const BuySellCTA: BuySellType[] = [
  {
    icon: (
      <WrenchIcon
        size={14}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="sidebar_paint_wrench"
      />
    ),
    heading: "Service",
    text: "Take your bike for a spa",
  },
  {
    icon: (
      <BikeIcon
        size={16}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="sidebar_paint_bike"
      />
    ),
    heading: "Buy",
    text: "Quality two-wheelers",
  },
  {
    icon: (
      <KeyIcon
        size={16}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="sidebar_paint_key"
      />
    ),
    heading: "Sell",
    text: "Get instant estimates",
  },
];

export function getSidebarClickedButton(text: string) {
  switch (text) {
    case "buy":
      return "/profile";
    case "wishlist":
      return "/wishlist";
    case "contactUs":
      return "/contactus";
  }
}
