import { AlertToast, CrossIcon } from "@/assets/Icons";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { formatToIndianNumber } from "@/utils/numerics";
import SelectYear from "./SelectYear";
import { ownershipOptions } from "../data";
import { useSearchParams } from "react-router-dom";

interface IProps {
  vehicleReceivedData: any;
  saveVehicleDetails: {
    kmDriven: string;
    manufactureYear: string;
    purchase_price: string;
    salePrice: string;
    profit: string;
    ownership: number;
  };
  setIsVehicleDetailsDialogOpen: (value: boolean) => void;
  handleSaveVehicleDetails: () => void;
  handleSelectValueChange: (value: string) => void;
  handleOwnerClick: (index: number) => void;
  updateVehicleDetails: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: {
    [key: string]: boolean | string | { [key: string]: boolean | string };
  };
}

const AddVehicleDetails = ({
  vehicleReceivedData,
  saveVehicleDetails,
  setIsVehicleDetailsDialogOpen,
  handleSaveVehicleDetails,
  handleSelectValueChange,
  handleOwnerClick,
  updateVehicleDetails,
  errors,
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!vehicleReceivedData || !saveVehicleDetails) return null;

  const invalidSalePrice = errors.salePrice === "Invalid Sale Price";
  const invalidPurchasePrice =
    errors.purchasePrice === "Invalid Purchase Price";

  const isCOCOStore = vehicleReceivedData?.store_type === "coco";
  const allowedOwnerships = [1, 2, 3];

  const isDisabled = !(
    Object.entries(saveVehicleDetails).every(
      ([key, value]) =>
        (isCOCOStore && key === "purchase_price") ||
        key === "profit" ||
        (value && value !== ""),
    ) &&
    allowedOwnerships.includes(saveVehicleDetails?.ownership) &&
    !Object.values(errors).some(Boolean)
  );

  const handleBackButtonClick = () => {
    setIsVehicleDetailsDialogOpen(false);
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col gap-4 w-full h-full overflow-y-auto">
      <div className="flex justify-between items-center gap-4 p-4 border-b border-neutral20">
        <div className="flex flex-col gap-1">
          <p className="text-base font-semibold truncate w-[15rem]">
            {vehicleReceivedData?.vehicleName}
          </p>
          <p className="text-xs font-medium text-[#4C4C4C]">
            {vehicleReceivedData?.registrationNumber}•
            {vehicleReceivedData?.kmDriven?.displayValue} KMs•
            {vehicleReceivedData?.manufactureYear}
          </p>
        </div>
        <span
          className="cursor-pointer w-fit"
          onClick={() => handleBackButtonClick()}
        >
          <CrossIcon size={20} oneStopColor="#000" twoStopColor="#000" />
        </span>
      </div>

      <div className="flex flex-col gap-6 p-4">
        {/* KMs Driven */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">KMs Driven</p>
          <Input
            className={cn(
              "bg-neutral30 text-base font-semibold py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 autofill:shadow-neutral30 selection:bg-neutral30",
              errors.kmDriven && "bg-red100",
            )}
            value={formatToIndianNumber(saveVehicleDetails?.kmDriven)}
            name="kmDriven"
            maxLength={10}
            onChange={updateVehicleDetails}
          />
          {typeof errors.kmDriven === "string" && (
            <p className="text-red600 text-sm font-semibold">
              {errors.kmDriven}
            </p>
          )}
        </div>

        {/* Year of Manufacturing */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">Year of Manufacturing</p>
          <SelectYear
            saveVehicleDetails={saveVehicleDetails}
            handleSelectValueChange={handleSelectValueChange}
          />
        </div>

        {/* Ownership */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">Ownership</p>
          <div className="flex gap-2">
            {ownershipOptions.map((ownership, index) => (
              <div
                className={cn(
                  "border border-neutral100 rounded-md p-2 px-3 cursor-pointer",
                  index + 1 === saveVehicleDetails?.ownership &&
                    "border-primaryA1",
                )}
                key={index}
                onClick={() => handleOwnerClick(index)}
              >
                <p
                  className={cn(
                    "text-neutral300 text-sm",
                    index + 1 === saveVehicleDetails?.ownership &&
                      "text-primaryA1",
                  )}
                >
                  {ownership.ownership}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Purchase price */}
        {!isCOCOStore && (
          <div className="flex flex-col gap-3">
            <p className="text-sm font-semibold">Purchase price</p>
            <Input
              className={cn(
                "flex justify-between items-center pr-4 bg-neutral30 rounded-lg",
                invalidPurchasePrice && "border border-red100 bg-[#FDECEB]",
              )}
              placeholder={saveVehicleDetails?.purchase_price}
              value={formatToIndianNumber(saveVehicleDetails?.purchase_price)}
              name="purchase_price"
              onChange={updateVehicleDetails}
            />
            {invalidPurchasePrice && (
              <p className="text-red600 text-sm font-semibold">
                Price cannot be increased by more than 5000
              </p>
            )}
          </div>
        )}

        {/* Sale price */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">Sale price</p>
          <div
            className={cn(
              "flex justify-between items-center pr-4 bg-neutral30 rounded-lg",
              invalidSalePrice && "border border-red100 bg-[#FDECEB]",
            )}
          >
            <Input
              className="bg-transparent text-base font-semibold py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 autofill:shadow-neutral30 w-fit"
              placeholder={saveVehicleDetails?.salePrice}
              value={formatToIndianNumber(saveVehicleDetails?.salePrice)}
              name="salePrice"
              maxLength={10}
              onChange={updateVehicleDetails}
              disabled={isCOCOStore ? true : false}
            />
            {invalidSalePrice && <AlertToast size={20} fillColor={"#D1372E"} />}
            {!invalidSalePrice &&
              !isCOCOStore &&
              saveVehicleDetails?.profit &&
              parseInt(saveVehicleDetails?.salePrice?.replaceAll(",", "")) >
                0 && (
                <span className="text-[#4A833D] text-[10px] font-semibold bg-green200 border border-[#5FA74E] px-1 py-0.5 rounded-sm">
                  + {saveVehicleDetails?.profit}% Profit
                </span>
              )}
          </div>
          {parseInt(saveVehicleDetails?.purchase_price?.replaceAll(",", "")) >
          parseInt(saveVehicleDetails?.salePrice?.replaceAll(",", "")) ? (
            <p className="text-red600 text-sm font-semibold">
              Sale price cannot be less than purchase price
            </p>
          ) : (
            <span className="flex items-center gap-2 text-xs text-[#912D7D] bg-[#F4EAF2] font-semibold p-1 w-full rounded-md">
              <AlertToast size={16} fillColor={"#912D7D"} /> Sale price required
              to list on website
            </span>
          )}
        </div>
      </div>

      <div className="flex justify-between gap-4 p-4 mt-auto border-t border-neutral100">
        <Button
          onClick={() => handleSaveVehicleDetails()}
          variant="outline"
          className="bg-primaryA2 border-orange-500 py-6 text-base font-semibold text-white w-full hover:bg-primaryA2 hover:text-white"
          disabled={isDisabled}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddVehicleDetails;
