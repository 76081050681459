import { Button } from "@/components/ui/button";
import DataUnavailable from "./FetchedVehicleStates/DataUnavailable";
import { UnavailableData } from "./FetchedVehicleStates/data";
import DataAvailable from "./FetchedVehicleStates/DataAvailable";
import InputWithIcon from "@/components/InputWithIcon";
import { cn } from "@/lib/utils";
import { Options } from "react-lottie";
import searchAnimation from "@/assets/lottie/search.json";
import { useLottie } from "@/hooks/useLottie";

interface IProps {
  isDataFetched: {
    brand: string;
    model: string;
    manufactured_year: string;
    number_of_owners: string;
    engine_number: string;
    chassis_number: string;
  };
  apiResponseMsg: {
    isVehicleAvailable: string;
    reason: string;
  };
  registrationNumber: string;
  isValidVehicleNumber: string;
  isFetchingFofoDetails: boolean;
  handleClose: () => void;
  handleDataFound: () => void;
  handleDataNotFound: () => void;
  handleEditDetails: () => void;
  handleFetchVehicleDetails: () => void;
  renderSuccessOrErrorIcon: (successOrError: string) => React.ReactNode;
  handleChangeRegistrationNumber: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const FetchVehicleDetails = ({
  isDataFetched,
  apiResponseMsg,
  handleClose,
  handleDataFound,
  handleEditDetails,
  handleDataNotFound,
  registrationNumber,
  isValidVehicleNumber,
  isFetchingFofoDetails,
  renderSuccessOrErrorIcon,
  handleFetchVehicleDetails,
  handleChangeRegistrationNumber,
}: IProps) => {
  const { Lottie } = useLottie();

  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: searchAnimation,
  };

  const isDataAvailable = apiResponseMsg?.isVehicleAvailable !== "";

  const renderFetchingResult = (status: string) => {
    const details = UnavailableData(apiResponseMsg?.reason);

    switch (status) {
      case "available":
        return (
          <DataAvailable
            productResult={isDataFetched}
            handleDataFound={handleDataFound}
            handleEditDetails={handleEditDetails}
          />
        );
      case "unavailable":
        return (
          <DataUnavailable
            header={details?.header(handleClose)}
            body={details?.body(isDataFetched as any)}
            callToAction={
              details && details?.callToAction
                ? details?.callToAction(handleDataNotFound)
                : undefined
            }
          />
        );
    }
  };

  return (
    <div className="flex flex-col gap-4 p-4 md:p-0 bg-white rounded-lg md:h-full">
      <p className="text-sm font-semibold">Enter registration number</p>

      <div className="flex flex-col gap-2">
        <InputWithIcon
          type="text"
          name="registrationNumber"
          maxLength={10}
          handleChange={(e) => handleChangeRegistrationNumber(e)}
          value={registrationNumber.toUpperCase()}
          parentClassName={cn(
            isValidVehicleNumber === "invalid" && "bg-[#FDECEB]",
          )}
          className={cn(
            "bg-neutral30 py-6 border-none rounded-sm focus-visible:ring-0 focus-visible:ring-offset-0 placeholder:text-neutral300 placeholder:text-base",
            isValidVehicleNumber === "invalid" && "bg-[#FDECEB]",
          )}
          placeholder="eg. TN 58 AH 34XXX"
          icon={renderSuccessOrErrorIcon(isValidVehicleNumber)}
        />

        {isValidVehicleNumber === "invalid" && (
          <p className="text-sm font-medium text-red-500">
            Invalid registration number
          </p>
        )}
      </div>

      {!isDataAvailable && (
        <Button
          onClick={handleFetchVehicleDetails}
          disabled={isFetchingFofoDetails}
          variant="outline"
          className="text-white text-base bg-primaryA2 md:hidden border-none py-6 rounded-sm hover:text-white hover:bg-primaryA2"
        >
          Fetch Details
        </Button>
      )}

      {!isDataAvailable && !isFetchingFofoDetails && (
        <div className="flex flex-col gap-1">
          <p className="text-sm font-semibold">
            DriveX acquired vehicles not to be added
          </p>
          <p className="text-sm font-medium text-neutral300">
            All the vehicles procured from DriveX will be added directly to the
            website
          </p>
        </div>
      )}

      {isFetchingFofoDetails && Lottie && (
        <div className="flex flex-col justify-center items-center bg-[#F8F8F8] p-3 rounded-sm">
          <Lottie options={defaultOptions} height={150} width={200} />
          <p className="text-sm font-semibold text-center mr-2">
            Searching for vehicle details <br /> on Vaahan
          </p>
        </div>
      )}

      {!isDataAvailable && (
        <Button
          onClick={handleFetchVehicleDetails}
          disabled={isFetchingFofoDetails}
          variant="outline"
          className="text-white text-base bg-primaryA2 hidden md:block md:mt-auto md:h-[2.8rem] border-none rounded-sm hover:text-white hover:bg-primaryA2"
        >
          Fetch Details
        </Button>
      )}

      {isDataAvailable &&
        isDataFetched &&
        renderFetchingResult(apiResponseMsg?.isVehicleAvailable)}
    </div>
  );
};

export default FetchVehicleDetails;
