import { UploadIcon } from "@/assets/Icons";
import { MAX_FILE_SIZE } from "@/utils/constants";
import { deepCopy } from "@/utils/object";

export const formFields = [
  {
    name: "selling_price",
    label: "Selling Price",
    type: "text",
    placeholder: "Enter Selling Price",
    required: true,
    description: "Final Sale Price(Includes GST)",
    disabled: "coco",
  },
  {
    name: "booking_amount",
    label: "Booking Amount",
    type: "text",
    placeholder: "- ₹",
    required: true,
    stepType: "pricing",
  },
  {
    name: "discount",
    label: "Discount",
    type: "text",
    placeholder: "- ₹",
    required: false,
    stepType: "pricing",
  },
  {
    name: "hypothecation",
    label: "Hypothecation",
    type: "text",
    placeholder: "+ ₹",
    required: false,
    stepType: "pricing",
    maxLength: 5,
  },
  {
    name: "notary",
    label: "Notary",
    type: "text",
    placeholder: "+ ₹",
    required: false,
    stepType: "pricing",
    maxLength: 5,
  },
  {
    name: "transit",
    label: "Transit",
    type: "text",
    placeholder: "+ ₹",
    required: false,
    stepType: "pricing",
    maxLength: 5,
  },
  {
    label: "Proof Of Payment 1",
    name: "payment 1",
    type: "custom",
    stepType: "payment",
    required: true,
    fields: [
      {
        name: "amount",
        type: "text",
        placeholder: "Amount",
        required: true,
      },
      {
        name: "mode_of_payment",
        type: "select",
        placeholder: "Select Mode Of Payment",
        required: true,
        options: [
          { value: "cash_on_delivery", label: "Cash" },
          { value: "upi", label: "UPI" },
          { value: "finance", label: "Finance" },
          { value: "credit_card", label: "Credit Card" },
          { value: "debit_card", label: "Debit Card" },
          { value: "NEFT_RTGS_IMPS", label: "NEFT/RTGS/IMPS" },
          { value: "bank_draft", label: "Bank Draft" },
          { value: "cheque", label: "Cheque" },
          { value: "exchange", label: "Exchange" },
        ],
      },
      {
        name: "payment_reference_id",
        label: "Payment Reference ID",
        type: "text",
        placeholder: "Enter Payment Reference ID",
        required: false,
        maxLength: 20,
      },
      {
        name: "proof_of_payment",
        type: "file",
        placeholder: (
          <div className="flex flex-col gap-1 flex-center">
            <UploadIcon />
            <p className="text-sm">Choose a file</p>
            <p className="text-xs text-[#A9ACB4]">
              PNG, JPEG and PDF formats, up to {MAX_FILE_SIZE}MB
            </p>
          </div>
        ) as unknown as string,
        required: true,
        hide: {
          field: "mode_of_payment",
          value: "cash_on_delivery",
        },
      },
    ],
  },
  {
    name: "customer_name",
    label: "Customer Name",
    type: "text",
    placeholder: "Enter Customer Name",
    required: true,
    maxLength: 30,
    stepType: "customer_details",
  },
  {
    name: "customer_email",
    label: "Customer Email",
    type: "text",
    placeholder: "Enter Customer Email",
    required: false,
    stepType: "customer_details",
  },
  {
    name: "customer_pan_or_aadhaar",
    label: "Customer Aadhaar/PAN",
    type: "text",
    placeholder: "Enter Customer Aadhaar or PAN Number",
    required: true,
    maxLength: 12,
    stepType: "customer_details",
  },
  {
    name: "customer_address",
    label: "Customer Address",
    type: "textarea",
    placeholder: "Enter Customer Address",
    required: true,
    maxLength: 500,
    stepType: "customer_details",
  },
  {
    name: "customer_phone_number",
    label: "Customer Phone Number",
    type: "text",
    placeholder: "Enter Phone Number",
    required: true,
    description: "An OTP will be sent to this number",
    prefill: "+91",
    maxLength: 10,
    stepType: "customer_details",
    disabled: "RESERVED",
  },
];

export const financierData = {
  name: "finance_details",
  type: "select",
  placeholder: "Financier",
  required: false,
  options: [
    { value: "finance_idfc_first", label: "IDFC First" },
    { value: "finance_tvs_credit", label: "TVS Credit" },
    { value: "finance_sri_chakra", label: "Sri Chakra" },
    { value: "finance_bike_bazaar", label: "Bike Bazaar" },
    { value: "finance_shrinithi_capital", label: "Shrinithi Capital" },
    { value: "finance_shree_balaji_finance", label: "Shree Balaji Finance" },
    { value: "finance_bajaj_finance", label: "Bajaj Finance" },
    { value: "others", label: "Others" },
  ],
};

export const editVehicleFormFields = [
  {
    name: "sellingPrice",
    label: "Selling Price",
    type: "number",
    placeholder: "Enter Selling Price",
    required: true,
    disabled: "coco",
  },
  {
    name: "kmDriven",
    label: "KMs Driven",
    type: "number",
    placeholder: "Enter KMs Driven",
    required: true,
  },
];

export const mode_of_payment: any = deepCopy(formFields)?.[6].fields?.find(
  (field: { name: string }) => field.name === "mode_of_payment",
);

export const payment_mode = deepCopy(formFields)?.[6];

export const sellReserveFormSteps = [
  {
    title: "Customer Details",
    type: "customer_details",
  },
  {
    title: "Pricing",
    type: "pricing",
  },
  {
    title: "Payment",
    type: "payment",
  },
  {
    title: "Documents",
    type: "documents",
  },
];
