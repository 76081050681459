import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { track } from "@/utils/mixpanel/actions";
import { LOCATION_CITY_CLICKED } from "@/utils/mixpanel/Events/location_clicked_events";
import { useSelector } from "react-redux";

type state = React.Dispatch<React.SetStateAction<any>>;

interface IProps {
  locationData: {
    location: string;
    img?: string;
  }[];
  dispatch: state;
  setLocation: state;
  setOpenLocation: state;
}

type locationItemType = { img?: string; location: string };

const PopularCities = ({
  locationData,
  dispatch,
  setLocation,
  setOpenLocation,
}: IProps) => {
  const user = useSelector((state: any) => state.user.currentUser);

  const handleClick = (locationItem: locationItemType) => {
    track(LOCATION_CITY_CLICKED, {
      city_name: locationItem.location,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    dispatch(setLocation(locationItem.location));
    setOpenLocation(false);
  };

  return (
    <div className="flex flex-col h-fit gap-3">
      <p className="text-sm text-neutral300">Popular cities</p>

      <div className="flex flex-wrap flex-1 gap-4 justify-left h-fit">
        {locationData.map(
          (locationItem, index) =>
            locationItem.img && (
              <div
                onClick={() => handleClick(locationItem)}
                className="flex flex-col h-fit w-[30%] lg:w-[15%] border border-lightgray text-sm rounded-md items-center p-4 gap-2"
                key={index}
              >
                <Avatar>
                  <AvatarImage src={locationItem.img} alt="@shadcn" />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>

                <p>{locationItem.location.replaceAll("-", " ")}</p>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default PopularCities;
