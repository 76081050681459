import Image from "../ui/image";
import { ShareIcon } from "@/assets/Icons";
import { formatUTCDate } from "@/utils/date";
import { handleShare } from "@/utils/share";
import CardComponent from "../CardComponent";
import { track } from "@/utils/mixpanel/actions";
import {
  MEDIA_CARD_CLICKED,
  MEDIA_SHARE_CLICKED,
} from "@/utils/mixpanel/Events/media_events";
import { useSelector } from "react-redux";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { EngineOil } from "@/utils/constants";

function MediaCard({ media }: { media: IMedia }) {
  const handleMediaCardClick = () => {
    if (media?.attributes?.article_url) {
      window.open(media.attributes.article_url, "_blank");
      track(MEDIA_CARD_CLICKED, {
        page: "Media",
        city: city,
        media_url: media?.attributes?.article_url,
        media_title: media?.attributes?.title,
        ...(user && { name: user?.userName, phone: user?.phoneNumber }),
      });
    }
  };
  const user = useSelector((state: any) => state.user.currentUser);

  const { city } = useCurrentCity();

  const publishedDate = formatUTCDate(
    media?.attributes?.published_date,
  ).replace(/ (\d{4})/, ", $1");

  const handleMediaShare = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation(); // Prevents triggering the card click
    track(MEDIA_SHARE_CLICKED, {
      page: "Media",
      city: city,
      media_url: media?.attributes?.article_url,
      media_title: media?.attributes?.title,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
    handleShare({
      text: "Hey! Have you seen the latest article about DriveX? Check out the link above to learn more!",
      url: media?.attributes?.article_url,
    });
  };

  const isVideo =
    media?.attributes?.cover?.data?.attributes?.mime?.includes("video");

  return (
    <CardComponent
      onClick={handleMediaCardClick}
      className="mb-3 flex flex-col justify-between h-full"
    >
      <div>
        <div className="relative">
          {isVideo ? (
            <video
              src={media?.attributes?.cover?.data?.attributes?.url ?? EngineOil}
              className="w-full h-[175px] object-cover object-top"
              autoPlay
              muted
              playsInline
              onTimeUpdate={(e) => {
                if ((e.target as HTMLVideoElement).currentTime >= 2) {
                  (e.target as HTMLVideoElement).currentTime = 0;
                  (e.target as HTMLVideoElement).play();
                }
              }}
            />
          ) : (
            <Image
              transform="f-webp"
              src={
                media?.attributes?.cover?.data?.attributes?.url ??
                "https://ik.imagekit.io/drivex/og_image_productlist.jpg"
              }
              alt={`${media.attributes.title} cover`}
              className="w-full h-[175px] object-cover object-top"
              width={"100%"}
              height={175}
            />
          )}
          <span
            className="bg-white p-2.5 rounded-md absolute top-2.5 right-2.5"
            onClick={handleMediaShare}
          >
            <ShareIcon />
          </span>
        </div>
        <div className="flex items-center gap-2  sm:pl-3 pl-4 pt-4">
          <div className="w-4 h-4 sm:w-6 sm:h-6 md:w-4 md:h-4 rounded-full overflow-hidden flex-shrink-0 ">
            <Image
              transform="f-webp"
              src={
                media?.attributes?.article_source_icon?.data[0]?.attributes
                  ?.url ??
                "https://ik.imagekit.io/drivex/og_image_productlist.jpg"
              }
              alt={`${media?.attributes?.title} cover`}
              className="w-full h-full object-cover"
              width={1}
              height={1}
            />
          </div>
          <span className="text-sm">{media?.attributes?.article_source}</span>
        </div>
        <h5 className="text-lg text-neutral900 font-semibold p-4 pb-0 pt-2 flex-grow ">
          {media?.attributes?.title}
        </h5>
      </div>

      {/* Date section placed at the bottom */}
      <div className="p-4 text-left pt-0 text-neutral300 text-sm">
        <span>{publishedDate}</span>
      </div>
    </CardComponent>
  );
}

export default MediaCard;
