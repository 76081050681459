import { CameraIcon } from "@/assets/Icons";
import { cn } from "@/lib/utils";
import { fallbackImage } from "@/utils/fallbackImage";
import React from "react";

const VehicleCard = ({
  vehicle,
  index,
  totalCards,
  showImagePendingNotification = false,
  onCTAClick,
}: {
  vehicle: any;
  index: number;
  totalCards: number;
  showImagePendingNotification?: boolean;
  onCTAClick?: () => void;
}) => {
  // Calculate the offset for stacked appearance
  const offset = `${index * 10}px`;

  return (
    <div
      className="absolute w-full flex flex-col gap-2 rounded-lg bg-white p-3 shadow-[0_0_10px_rgba(0,0,0,0.1),_0_8px_5px_rgba(0,0,0,0.1)] select-none"
      style={{
        top: offset,
        width: `calc(100% - ${index * 20}px)`,
        zIndex: totalCards - index,
      }}
      onClick={onCTAClick}
    >
      {showImagePendingNotification && (
        <div className="flex items-center gap-1 bg-[#FFDADA] px-2 py-1 rounded-sm w-fit">
          <CameraIcon />
          <p className="text-xs font-semibold">Photo Upload Pending</p>
        </div>
      )}

      <div className="flex gap-2">
        {!showImagePendingNotification && (
          <img
            src={vehicle?.imagePaths[0] ?? fallbackImage(vehicle?.vehicleType)}
            alt={vehicle?.vehicleName}
            className="w-[6rem] h-[4rem] object-cover rounded-md"
          />
        )}

        <div
          className={cn(
            "flex flex-col justify-evenly",
            showImagePendingNotification && "gap-2",
          )}
        >
          <p className="text-base font-medium text-[#2F2F2F] line-clamp-1">
            {vehicle?.vehicleName}
          </p>
          <p className="text-neutral300 text-xs">
            {vehicle?.registrationNumber}
            {vehicle?.kmDriven?.displayValue &&
              `•${vehicle?.kmDriven?.displayValue} KMs`}
            {vehicle?.manufactureYear && `•${vehicle?.manufactureYear}`}
          </p>
        </div>
      </div>
    </div>
  );
};

const StackedCards = ({
  data,
  showImagePendingNotification = false,
  onCTAClick,
}: {
  data: any[];
  showImagePendingNotification?: boolean;
  onCTAClick?: () => void;
}) => {
  // Calculate total height based on number of cards
  const containerHeight = `${3 * 2 + 80}px`;
  const isDataMoreThanTwo = data.length > 2;

  return (
    <>
      <div className="py-2">
        <div
          className={cn(
            "relative flex flex-col items-center",
            isDataMoreThanTwo || showImagePendingNotification ? "mb-8" : "mb-4",
            isDataMoreThanTwo && showImagePendingNotification && "mb-12",
          )}
          style={{ height: containerHeight }}
        >
          {data.slice(0, 3).map((vehicle, index) => (
            <VehicleCard
              showImagePendingNotification={showImagePendingNotification}
              key={vehicle.vehicleId}
              vehicle={vehicle}
              index={index}
              totalCards={data.length}
              onCTAClick={onCTAClick}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default StackedCards;
