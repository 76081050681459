import { cn } from "@/lib/utils";
import { useCallback } from "react";

function SelectVariant({
  vehicleListing,
  setVehicleListing,
  setSearchParams,
  type,
  variants,
}: any) {
  const handleVariantClick = useCallback(
    (variant: string) => {
      setSearchParams({ step: "year", type });
      setVehicleListing({
        ...vehicleListing,
        steps: {
          ...vehicleListing.steps,
          variant,
        },
      });
    },
    [type, vehicleListing],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {variants.map((variant: any) => {
        return (
          <div
            key={variant}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              vehicleListing.steps.variant === variant
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleVariantClick(String(variant))}
          >
            {variant.split("::")[0]}
          </div>
        );
      })}
    </div>
  );
}

export default SelectVariant;
