import { EditIcon, TickIconFilled, UploadIcon } from "@/assets/Icons";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { formatToIndianNumber } from "@/utils/numerics";
import { useRef, useState } from "react";
import { validateFileSize, MAX_FILE_SIZE } from "@/utils/constants";

const SelectKMDriven = ({
  vehicleListing,
  setVehicleListing,
  setErrors,
  errors,
}: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isImageUploaded, setIsImageUploaded] = useState("");
  const [kmDrivenData, setKmDrivenData] = useState({
    value: vehicleListing?.steps?.km_driven?.value || "",
    image: vehicleListing?.steps?.km_driven?.image || "",
  });

  const registrationNumber = vehicleListing?.registration_number;
  const kmDrivenValue =
    errors?.km_driven_value === "Invalid KMs" ? true : false;
  const kmDrivenImage =
    errors?.km_driven_image === "Invalid Image" ? true : false;

  const handleKmDrivenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    setErrors({ ...errors, km_driven_value: "" });

    setKmDrivenData({ ...kmDrivenData, value: value });

    setVehicleListing({
      ...vehicleListing,
      steps: {
        ...vehicleListing.steps,
        km_driven: { ...vehicleListing.steps.km_driven, value: value },
      },
    });
  };

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    const file = files[0];
    if (!validateFileSize(file, MAX_FILE_SIZE)) {
      setErrors({
        ...errors,
        km_driven_image: `File size must be less than ${MAX_FILE_SIZE}MB`,
      });
      return;
    }

    setErrors({ ...errors, km_driven_image: "" });

    const today = new Date();
    const getFullDate = today.toLocaleDateString("en-IN", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });

    const modifiedFile = new File(
      [file],
      `${registrationNumber}_${getFullDate}_odo`,
      {
        type: file.type,
      },
    );

    setIsImageUploaded(modifiedFile?.name);
    setKmDrivenData({ ...kmDrivenData, image: modifiedFile });

    setVehicleListing({
      ...vehicleListing,
      steps: {
        ...vehicleListing.steps,
        km_driven: { ...vehicleListing.steps.km_driven, image: modifiedFile },
      },
    });
  };

  const handleUpload = () => {
    if (!inputRef || !inputRef.current) return;

    inputRef.current?.click();
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <p className="text-sm font-semibold">
          KMs Driven <span className="text-red-500">*</span>
        </p>
        <Input
          className={cn(
            "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
            kmDrivenValue && "bg-red-100",
          )}
          maxLength={7}
          placeholder="Enter KMs Driven"
          value={formatToIndianNumber(kmDrivenData.value)}
          onChange={handleKmDrivenChange}
        />
        {errors?.km_driven_value && (
          <p className="text-sm font-medium text-red-500">
            {errors?.km_driven_value}
          </p>
        )}
      </div>

      <div className="flex flex-col gap-3">
        <p className="text-sm font-semibold">
          Upload Odometer Image{" "}
          <span className="text-[#A6A6A6]">(optional)</span>
        </p>

        <div
          onClick={handleUpload}
          className={cn(
            "flex flex-col items-center justify-center gap-2 bg-neutral30 rounded-lg py-6",
            isImageUploaded !== "" && "items-start py-4 px-2",
            kmDrivenImage && "bg-red-100",
          )}
        >
          <Input
            className="hidden"
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={handleOnInputChange}
          />
          {isImageUploaded === "" ? (
            <>
              <UploadIcon size={24} />
              <div className="flex flex-col items-center gap-1">
                <p className="text-sm font-semibold">Choose a file</p>
                <p className="text-[#A9ACB4] text-xs">
                  JPEG, PNG and HEIC formats, up to {MAX_FILE_SIZE}MB
                </p>
              </div>
            </>
          ) : (
            <div className="flex justify-between items-center gap-2 w-full">
              <p className="flex items-center gap-2">
                <TickIconFilled
                  oneStopColor="#5FA74E"
                  twoStopColor="#5FA74E"
                  url="fileUploaded"
                />
                {isImageUploaded}
              </p>
              <EditIcon size={16} fill="#D1372E" />
            </div>
          )}
        </div>
        {errors?.km_driven_image && (
          <p className="text-sm font-medium text-red-500">
            {errors?.km_driven_image}
          </p>
        )}

        <p className="text-neutral300 font-medium text-sm">
          This will be used to verify the details by the{" "}
          <br className="md:hidden" /> DriveX team
        </p>
      </div>
    </div>
  );
};

export default SelectKMDriven;
