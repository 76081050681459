import FlowStepper from "@/components/FlowStepper";
import { useEffect, useState } from "react";
import {
  getFofoVehicleListingSteps,
  initialDataFoundState,
  initialDataNotFoundState,
  IVehicleListing,
  parseSearchParams,
  SearchParams,
  Title,
  validatePricing,
  validateVehicleAdditionalDetails,
  VehicleListingSteps,
  VehicleListingStepsType,
} from "./data";
import { ChevronLeft as LeftIcon, LoaderIcon } from "lucide-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useVehicleListingFlow from "./useVehicleListingFlow";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { useSelector } from "react-redux";
import {
  useGetPreSignedURLMutation,
  useSubmitFOFOVehicleMutation,
} from "@/store/services/Dmx/storeVehicles";
import { getOwnerShipStatus } from "@/common/product";
import { useToast } from "@/components/ui/use-toast";
import { useRoleAccess } from "@/hooks/useRoleAccess";
import { track } from "@/utils/mixpanel/actions";
import { DX_SELF_PROCURED_VEHICLE_ADDED } from "@/utils/mixpanel/DMXEvents/dx_self_procured_events";

const SelfProcuredFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasAccess } = useRoleAccess();
  const { toast } = useToast();
  const { isMobile } = useScreenDetector();
  const store = useSelector((state: any) => state.store.store);
  const user = useSelector((state: any) => state.user.currentUser);
  const role = localStorage.getItem("role") || "";
  const [submitFOFOVehicle] = useSubmitFOFOVehicleMutation({});
  const [getPreSignedUrl] = useGetPreSignedURLMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [automaticFlowData, setAutomaticFlowData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const type = searchParams.get("type") as VehicleListingStepsType;
  const currentStep = searchParams.get("step") as VehicleListingSteps;
  const vehicleListingSteps = getFofoVehicleListingSteps({ type });
  const setSearchParamsWithType = (params: SearchParams) => {
    setSearchParams(params);
  };

  const [vehicleListing, setVehicleListing] = useState<IVehicleListing>(
    type === "manual" || type === null
      ? initialDataNotFoundState
      : initialDataFoundState,
  );

  const locationState = location.state as {
    manualFlowData?: { registrationNumber: string; brand?: string };
    automaticFlowData?: {
      brand: string;
      model: string;
      variant: string | null;
      manufactured_year: string;
      number_of_owners: number;
      registration_number: string;
      engine_number: string;
      chassis_number: string;
      color: string;
    };
  };
  // Receiving Registration Number from Manual Flow
  const manualFlowData = locationState?.manualFlowData;

  // Receiving Vehicle Details from Automatic Flow
  const automaticFlow = locationState?.automaticFlowData;

  const stepWithMultipleInputs = [
    "vehicle_additional_details",
    "km_driven",
    "pricing",
  ];

  useEffect(() => {
    const isManualFlowWithoutData = type === "manual" && !manualFlowData;
    const isAutomaticFlowWithoutData = type === "automatic" && !automaticFlow;

    if (
      (isManualFlowWithoutData || isAutomaticFlowWithoutData) &&
      hasAccess(["Dealer", "CRE"])
    ) {
      navigate("/dealer/vehicles?add-vehicle=fofo", { replace: true });
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (manualFlowData) {
      setVehicleListing({
        ...vehicleListing,
        registration_number: manualFlowData.registrationNumber,
        ...(manualFlowData.brand && {
          steps: {
            ...vehicleListing.steps,
            brand: manualFlowData.brand,
          },
        }),
      });
    }
  }, [manualFlowData]);

  useEffect(() => {
    if (automaticFlow) {
      setAutomaticFlowData(automaticFlow);

      setVehicleListing({
        ...vehicleListing,
        registration_number: automaticFlow?.registration_number,
      });
    }
  }, [automaticFlow]);

  useEffect(() => {
    parseSearchParams({
      currentStep,
      type,
      navigate,
    });
  }, [currentStep, type, vehicleListing]);

  const validateAutomaticSteps = (currentStep: VehicleListingSteps) => {
    switch (currentStep) {
      case "vehicle_additional_details":
        return Object.values(
          vehicleListing.steps.vehicle_additional_details || {},
        ).every((value) => value !== "")
          ? true
          : false;
      case "km_driven":
        return vehicleListing.steps.km_driven?.value !== "" ? true : false;
      case "pricing":
        return Object.values(vehicleListing.steps.pricing || {}).every(
          (value) => value !== "",
        )
          ? true
          : false;

      default:
        return false;
    }
  };

  const isCurrentStepComplete = (currentStep: VehicleListingSteps) => {
    if (stepWithMultipleInputs.includes(currentStep)) {
      return validateAutomaticSteps(currentStep);
    }

    return vehicleListing.steps[currentStep] !== "" ? true : false;
  };

  const { renderCurrentStep, setSubmitClicked, setErrors, errors } =
    useVehicleListingFlow({
      vehicleListing,
      setVehicleListing,
      setSearchParams,
      currentStep,
      type,
    });

  const getPayloadBasedOnType = (type: VehicleListingStepsType) => {
    switch (type) {
      case "automatic":
        return {
          data: {
            registration_number: automaticFlowData?.registration_number,
            kms_driven: vehicleListing?.steps?.km_driven?.value,
            brand: automaticFlowData?.brand || "",
            model: automaticFlowData?.model || "",
            variant: automaticFlowData?.variant || "",
            manufactured_year: automaticFlowData?.manufactured_year || "",
            estimated_sale_price: vehicleListing?.steps?.pricing?.selling_price,
            number_of_owners: automaticFlowData?.number_of_owners,
            dealer_procured_price:
              vehicleListing?.steps?.pricing?.purchase_price,
            refurbishment_price:
              vehicleListing?.steps?.pricing?.refurbishment_cost,
            odometer_image: vehicleListing?.steps?.km_driven?.image,
            engine_number: automaticFlowData?.engine_number,
            chassis_number: automaticFlowData?.chassis_number,
            color: automaticFlowData?.color,
            partner_id: store?.store_id,
            partner_type: store.partner_type,
          },
        };
      case "manual":
        return {
          data: {
            registration_number: vehicleListing?.registration_number,
            kms_driven: vehicleListing?.steps?.km_driven?.value,
            brand: vehicleListing?.steps?.brand || "",
            model: vehicleListing?.steps?.model || "",
            variant: vehicleListing?.steps?.variant?.split("::")[0] || "",
            category: vehicleListing?.steps?.variant?.split("::")[1],
            manufactured_year: vehicleListing?.steps?.year || "",
            estimated_sale_price: vehicleListing?.steps?.pricing?.selling_price,
            number_of_owners: vehicleListing?.steps?.no_of_owner,
            dealer_procured_price:
              vehicleListing?.steps?.pricing?.purchase_price,
            refurbishment_price:
              vehicleListing?.steps?.pricing?.refurbishment_cost,
            odometer_image: vehicleListing?.steps?.km_driven?.image,
            engine_number:
              vehicleListing?.steps?.vehicle_additional_details?.engine_no,
            chassis_number:
              vehicleListing?.steps?.vehicle_additional_details?.chassis_no,
            color: vehicleListing?.steps?.color,
            partner_id: store?.store_id,
            partner_type: store.partner_type,
          },
        };
      default:
        return {};
    }
  };

  const getCTAText = () => {
    switch (currentStep) {
      case "vehicle_additional_details":
        return "Next";
      case "km_driven":
        return "Next";
      case "pricing":
        return "Confirm Details";
    }
  };

  const handleBackClick = () => {
    navigate("/dealer/vehicles?add-vehicle=fofo", { replace: true });
  };

  const handlePillClick = (step: { type: VehicleListingSteps }) => {
    if (!isCurrentStepComplete(step.type)) return;

    setSearchParamsWithType({ step: step.type, type });
    setVehicleListing({
      ...vehicleListing,
      steps: {
        ...vehicleListing.steps,
      },
      ...(type === "automatic" && {
        registration_number: vehicleListing.registration_number,
      }),
    });
  };

  const invokeS3Url = async (s3Url: string, method: string, data?: any) => {
    try {
      const response = await fetch(s3Url, {
        method: method,
        body: data,
        headers: {
          "Content-Type": "binary/octet-stream",
        },
      });

      return response;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
    }
  };

  const handleVehicleDetailsCTAClick = () => {
    const vehicleAdditionalDetails = vehicleListing?.steps
      ?.vehicle_additional_details || {
      engine_no: "",
      chassis_no: "",
    };

    if (validateVehicleAdditionalDetails(vehicleAdditionalDetails, setErrors)) {
      return;
    }

    setSearchParamsWithType({ step: "km_driven", type });
  };

  const handleKmDrivenCTAClick = async () => {
    const kmDriven = vehicleListing?.steps?.km_driven;
    const formattedImageName = (kmDriven?.image?.name || "").replaceAll(
      "/",
      "-",
    );

    let hasErrors = false;

    if (kmDriven?.value === "") {
      setErrors({
        ...errors,
        km_driven_value: "Invalid KMs",
      });
      hasErrors = true;
    }

    if (hasErrors) return;

    setIsLoading(true);

    if (kmDriven?.image) {
      const data: any = await getPreSignedUrl({
        data: {
          file_names: [`${formattedImageName}`],
          doc_type: "verification_images",
          prefix: "",
          operation: "put",
        },
      });

      if (data) {
        const s3Url = data?.data?.data[0].url;
        setVehicleListing({
          ...vehicleListing,
          steps: {
            ...vehicleListing.steps,
            km_driven: { ...vehicleListing?.steps?.km_driven, image: s3Url },
          },
        });

        if (s3Url) {
          await invokeS3Url(s3Url, "PUT", kmDriven?.image);
        }
      }
    }

    setIsLoading(false);

    setSearchParamsWithType({ step: "pricing", type });
  };

  const handlePricingCTAClick = () => {
    const pricing = vehicleListing?.steps?.pricing || {
      purchase_price: "",
      refurbishment_cost: "",
      selling_price: "",
    };

    setSubmitClicked(true);

    if (validatePricing(pricing, setErrors)) return;

    addFOFOVehicle();
  };

  const addFOFOVehicle = async () => {
    setIsLoading(true);
    const payload = getPayloadBasedOnType(type);
    const res = await submitFOFOVehicle(payload);

    if (res?.data?.status === "OK") {
      const VehicleId = res?.data?.data?.vehicle_id;

      track(DX_SELF_PROCURED_VEHICLE_ADDED, {
        user_id: user?.id,
        role: role,
        vehicle_name: `${payload?.data?.brand} ${payload?.data?.model}`,
        registration_number: payload?.data?.registration_number,
        km_driven: payload?.data?.kms_driven,
        varient: payload?.data?.variant,
        manufacture_year: payload?.data?.manufactured_year,
        selling_price: payload?.data?.estimated_sale_price,
        ownership: payload?.data?.number_of_owners,
        purchase_price: payload?.data?.dealer_procured_price,
        refurbishment_price: payload?.data?.refurbishment_price,
        engine_number: payload?.data?.engine_number,
        chassis_number: payload?.data?.chassis_number,
        color: payload?.data?.color,
        vehicle_id: VehicleId,
        inventory_type: "fofo_own",
        store_name: store?.store_name,
        store_id: store?.store_id,
        // Sending Image Status Hardcoded As The Vehicle Added Through This Flow Will Always Be in Pending Status
        Image_Status: "Pending",
      });

      navigate(`/dealer/vehicles/${VehicleId}`, { replace: true });
      setIsLoading(false);
      return;
    }

    if (res.error && "status" in res.error && res.error.status === 409) {
      toast({
        title: "Vehicle already exists",
        variant: "destructive",
        duration: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleCTAClick = () => {
    if (currentStep === "vehicle_additional_details") {
      handleVehicleDetailsCTAClick();
      return;
    }

    if (currentStep === "km_driven") {
      handleKmDrivenCTAClick();
      return;
    }

    if (currentStep === "pricing") {
      handlePricingCTAClick();
    }
  };

  const showCTA = () => {
    if (stepWithMultipleInputs.includes(currentStep)) {
      return (
        <div className="bg-white p-4 md:max-w-screen-md md:mx-auto md:w-full md:rounded-md shadow-md">
          <Button
            onClick={handleCTAClick}
            disabled={isLoading}
            variant="outline"
            className="flex-center gap-2 bg-primaryA2 text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white border border-orange-500"
          >
            {getCTAText()}{" "}
            {isLoading && <LoaderIcon className="animate-spin" />}
          </Button>
        </div>
      );
    }
  };

  return (
    <FlowStepper
      showNavbar={isMobile ? false : true}
      headerContent={
        <div
          className={cn(
            "flex justify-between items-center py-4 px-4 md:px-0 md:pb-0 max-w-screen-md mx-auto w-full bg-white md:bg-neutral30",
            isMobile ? "mt-0" : "mt-20",
          )}
        >
          <div className="flex gap-2 cursor-pointer" onClick={handleBackClick}>
            <LeftIcon />
            <p className="text-base text-neutral900">Add Vehicle</p>
          </div>

          <p className="text-sm text-neutral300">{store.store_name}</p>
        </div>
      }
      onPillClick={(step) =>
        handlePillClick(step as { type: VehicleListingSteps })
      }
      customPillStyles="md:mt-4"
      customChildPillStyles="md:py-3"
      isStepComplete={(step: string) =>
        isCurrentStepComplete(step as VehicleListingSteps)
      }
      steps={vehicleListingSteps}
      activeStep={currentStep}
      vehicleDetails={
        <div className="flex flex-col px-4 md:px-0 pt-4 max-w-3xl w-full mx-auto">
          <div className="flex flex-col gap-1 bg-white p-3 w-fit md:w-full rounded-md">
            <p className="text-base font-medium">
              {automaticFlowData?.model || vehicleListing?.steps?.brand}{" "}
              {vehicleListing?.steps?.model}{" "}
              {vehicleListing?.steps?.variant?.split("::")[0]}
            </p>
            <p className="text-sm font-medium text-[#3B3B3B]">
              {automaticFlowData?.registration_number ||
                vehicleListing?.registration_number}
              {(automaticFlowData?.manufactured_year &&
                `•${automaticFlowData?.manufactured_year}`) ||
                (vehicleListing?.steps?.year &&
                  `•${vehicleListing?.steps?.year}`)}
              {(automaticFlowData?.number_of_owners &&
                `•${getOwnerShipStatus(automaticFlowData?.number_of_owners)}`) ||
                (vehicleListing?.steps?.no_of_owner &&
                  `•${getOwnerShipStatus(
                    parseInt(vehicleListing?.steps?.no_of_owner || ""),
                  )}`)}
              {vehicleListing?.steps?.km_driven?.value &&
                `• ${Number(
                  vehicleListing?.steps?.km_driven?.value || "",
                ).toLocaleString("en-IN")} KMs`}
            </p>
          </div>
        </div>
      }
      stepTitles={Title}
      renderActiveStep={renderCurrentStep}
      CTA={showCTA()}
    />
  );
};

export default SelfProcuredFlow;
