import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Common function to get headers
const getAuthHeaders = () => ({
  Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
});

const ApiBaseUrl = import.meta.env.VITE_STRAPI_API_BASE_URL;

export const strapiApi = createApi({
  reducerPath: "strapiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiBaseUrl,
  }),
  endpoints: (builder) => ({
    getAllDealerLeads: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/dealer-leads-mappings`,
          params: {
            ...params,
          },
          headers: getAuthHeaders(),
        };
      },
    }),
  }),
});

export const { useGetAllDealerLeadsQuery } = strapiApi;
