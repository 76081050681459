import { X as CloseIcon } from "lucide-react";
import { DestructiveToast, SingleDocumentIcon } from "@/assets/Icons";
import { getOwnerShipStatus } from "@/common/product";
import { Button } from "@/components/ui/button";
import VehicleMiniCard from "@/components/Dmx/VehicleMiniCard";

interface vehicleDetailType {
  vehicle_id: string;
  registration_number: string;
  brand: string;
  model: string;
  variant: string;
  manufactured_year: string;
  kms_driven: number;
  number_of_owners: number;
}

export const UnavailableData = (data: string) => {
  switch (data) {
    case "VEHICLE_NOT_FOUND":
      return {
        header: (handleClose: () => void) => (
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
              <SingleDocumentIcon size={20} fill="#CF9D3C" />
              <p className="text-base font-semibold">
                Details not available on Vaahan
              </p>
            </div>
            <span
              className="cursor-pointer rounded-full bg-black p-[3px]"
              onClick={handleClose}
            >
              <CloseIcon size={14} color="#FFF4DF" />
            </span>
          </div>
        ),
        body: () => (
          <p className="text-sm font-medium text-neutral300">
            Please add all the vehicle details manually to list it on the
            website
          </p>
        ),
        callToAction: (onClick: () => void) => (
          <Button
            variant="outline"
            className="text-white text-base bg-primaryA2 border-none py-6 md:mt-auto rounded-sm hover:text-white hover:bg-primaryA2"
            onClick={onClick}
          >
            Add Vehicle Details
          </Button>
        ),
      };

    case "VEHICLE_INVENTORY_EXISTS":
      return {
        header: (handleClose: () => void) => (
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
              <DestructiveToast size={20} color="#CF9D3C" />
              <p className="text-base font-semibold">
                Existing Vehicle Detected
              </p>
            </div>
            <span
              className="cursor-pointer rounded-full bg-black p-[3px]"
              onClick={handleClose}
            >
              <CloseIcon size={14} color="#FFF4DF" />
            </span>
          </div>
        ),
        body: (vehicleDetails: vehicleDetailType) => (
          <>
            <p className="text-sm font-medium text-neutral300">
              You already have this vehicle on your floor stock
            </p>
            <div className="flex flex-col gap-2 p-4 bg-white rounded-sm">
              <p className="text-base font-semibold">{vehicleDetails?.model}</p>
              <p className="text-xs font-medium text-neutral300">
                {vehicleDetails?.registration_number}•
                {vehicleDetails?.kms_driven?.toLocaleString("en-In")} KMs•
                {getOwnerShipStatus(vehicleDetails?.number_of_owners)}•
                {vehicleDetails?.manufactured_year}
              </p>
            </div>
          </>
        ),
      };

    case "VEHICLE_SOLD":
      return {
        header: (handleClose: () => void) => (
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
              <DestructiveToast size={20} color="#CF9D3C" />
              <p className="text-base font-semibold">Sold Vehicle Detected</p>
            </div>
            <span
              className="cursor-pointer rounded-full bg-black p-[3px]"
              onClick={handleClose}
            >
              <CloseIcon size={14} color="#FFF4DF" />
            </span>
          </div>
        ),
        body: (vehicleDetails: vehicleDetailType) => (
          <>
            <p className="text-sm font-medium text-neutral300">
              Vehicle has already been sold and cannot be listed again on the
              website
            </p>
            <div className="flex flex-col gap-1 p-4 bg-white rounded-sm">
              <p className="text-base font-semibold">{vehicleDetails?.model}</p>
              <p className="text-xs font-medium text-neutral300">
                {vehicleDetails?.registration_number}•
                {vehicleDetails?.kms_driven?.toLocaleString("en-In")} KMs•
                {getOwnerShipStatus(vehicleDetails?.number_of_owners)}•
                {vehicleDetails?.manufactured_year}
              </p>
            </div>
          </>
        ),
      };

    case "VEHICLE_IN_TRANSIT":
      return {
        header: (handleClose: () => void) => (
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
              <DestructiveToast size={20} color="#CF9D3C" />
              <p className="text-base font-semibold">
                In transit DriveX Vehicle
              </p>
            </div>
            <span
              className="cursor-pointer rounded-full bg-black p-[3px]"
              onClick={handleClose}
            >
              <CloseIcon size={14} color="#FFF4DF" />
            </span>
          </div>
        ),
        body: (vehicleDetails: vehicleDetailType) => (
          <>
            <p className="text-sm font-medium text-neutral300">
              You haven&apos;t received this vehicle yet as it is on the way to
              your store. This will be added automatically to your floor stock
              on DMX
            </p>
            <div className="flex flex-col gap-1 p-4 bg-white rounded-sm">
              <p className="text-base font-semibold">{vehicleDetails?.model}</p>
              <p className="text-xs font-medium text-neutral300">
                {vehicleDetails?.registration_number}•
                {vehicleDetails?.kms_driven?.toLocaleString("en-In")} KMs•
                {getOwnerShipStatus(vehicleDetails?.number_of_owners)}•
                {vehicleDetails?.manufactured_year}
              </p>
            </div>
          </>
        ),
      };

    case "VEHICLE_DELIVERED":
      return {
        header: (handleClose: () => void) => (
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
              <DestructiveToast size={20} color="#CF9D3C" />
              <p className="text-base font-semibold">
                DriveX Delivered Vehicle
              </p>
            </div>
            <span
              className="cursor-pointer rounded-full bg-black p-[3px]"
              onClick={handleClose}
            >
              <CloseIcon size={14} color="#FFF4DF" />
            </span>
          </div>
        ),
        body: (vehicleDetails: vehicleDetailType) => (
          <>
            <p className="text-sm font-medium text-neutral300">
              You have received this vehicle from DriveX and have to add this to
              your floor stock
            </p>
            <div className="flex flex-col gap-1 p-4 bg-white rounded-sm">
              <VehicleMiniCard
                data={{
                  vehicleId: vehicleDetails?.vehicle_id,
                  vehicleName: vehicleDetails?.model,
                  registrationNumber: vehicleDetails?.registration_number,
                  manufactureYear: vehicleDetails?.manufactured_year,
                  kmDriven: {
                    displayValue: String(vehicleDetails?.kms_driven),
                  },
                }}
                className="border-none pb-0"
                showCTA={false}
              />
            </div>
          </>
        ),
      };

    case "VEHICLE_AVAILABLE_WITH_DIFFERENT_DEALER":
      return {
        header: (handleClose: () => void) => (
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
              <DestructiveToast size={20} color="#CF9D3C" />
              <p className="text-base font-semibold">
                Vehicle at another store
              </p>
            </div>
            <span
              className="cursor-pointer rounded-full bg-black p-[3px]"
              onClick={handleClose}
            >
              <CloseIcon size={14} color="#FFF4DF" />
            </span>
          </div>
        ),
        body: (vehicleDetails: vehicleDetailType) => (
          <>
            <p className="text-sm font-medium text-neutral300">
              This vehicle is already available at another store. Please check
              the details or contact support for assistance
            </p>
            <div className="flex flex-col gap-1 p-4 bg-white rounded-sm">
              <p className="text-base font-semibold">{vehicleDetails?.model}</p>
              <p className="text-xs font-medium text-neutral300">
                {vehicleDetails?.registration_number}•
                {vehicleDetails?.kms_driven?.toLocaleString("en-In")} KMs•
                {getOwnerShipStatus(vehicleDetails?.number_of_owners)}•
                {vehicleDetails?.manufactured_year}
              </p>
            </div>
          </>
        ),
      };

    case "VEHICLE_NOT_A_TWO_WHEELER":
      return {
        header: (handleClose: () => void) => (
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex items-center gap-2">
              <DestructiveToast size={20} color="#CF9D3C" />
              <p className="text-base font-semibold">Not a two-wheeler</p>
            </div>
            <span
              className="cursor-pointer rounded-full bg-black p-[3px]"
              onClick={handleClose}
            >
              <CloseIcon size={14} color="#FFF4DF" />
            </span>
          </div>
        ),
        body: () => (
          <>
            <p className="text-sm font-medium text-neutral300">
              It looks like the vehicle you&apos;re adding isn&apos;t a
              two-wheeler. Please add a two-wheeler to proceed.
            </p>
          </>
        ),
      };

    default:
      return null;
  }
};
