import Navbar from "@/components/Navbar";
import JoinTheMobility from "./JoinTheMobility";
import EligibilityCriteria from "./EligibilityCriteria";
import { useGetDealerTestimonialsQuery } from "@/store/services/becomeADealer";
import { DealersPageFAQsData } from "./data";
import Testimonials from "@/components/Testimonials/Testimonials";
import DealerOnboarding from "./DealerOnboarding";
import FAQs from "@/components/FAQs";
import { track } from "@/utils/mixpanel/actions";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useSelector } from "react-redux";
import SubmitQuery from "../AboutUs/SubmitQuery";
import Footer from "@/components/Footer";
import { useRef, useState } from "react";
import VideoPopup from "@/components/VideoPopup";

const Dealer = () => {
  const { city } = useCurrentCity();

  const user = useSelector((state: any) => state.user.currentUser);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const DealersFormRef = useRef<HTMLDivElement>(null);
  const EligibilityCriteriaRef = useRef<HTMLDivElement>(null);

  const { data: testimonialsData } = useGetDealerTestimonialsQuery({
    params: {
      sort: "orderIndex",
      "populate[0]": "image",
    },
  });

  const videoSource =
    "https://www.youtube.com/embed/6luQHB7kZG4?autoplay=1&rel=0";

  const handleApplyNowClick = () => {
    DealersFormRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleCheckEligibilityClick = () => {
    EligibilityCriteriaRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: "Become a Dealer Page",
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  const handleVideoClick = () => {
    setIsModalOpen(true);
  };
  return (
    <>
      <div className="flex flex-col gap-4 bg-white">
        <Navbar
          backgroundColor={"bg-neutral900"}
          showLocation={false}
          showSearchbar={false}
          showLinks={true}
        />

        {/* Join The Mobility */}
        <div className="p-4 pt-[90px] bg-neutral900">
          <JoinTheMobility
            handleApplyNowClick={handleApplyNowClick}
            handleVideoClick={handleVideoClick}
            handleCheckEligibilityClick={handleCheckEligibilityClick}
          />
        </div>

        <VideoPopup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          videoSource={videoSource}
        />

        {/* Eligibility Criteria */}
        <div
          className="flex flex-col gap-4 p-4 bg-white"
          ref={EligibilityCriteriaRef}
        >
          <EligibilityCriteria handleApplyNowClick={handleApplyNowClick} />
        </div>

        {/* Testimonials */}
        {testimonialsData?.data?.length > 0 && (
          <div className="content-div bg-white flex flex-col gap-6 p-4 my-5">
            <Testimonials
              data={testimonialsData}
              heading={
                <>
                  Road to Reach{" "}
                  <span className="text-primaryA2">Your Goals</span>{" "}
                </>
              }
            />
          </div>
        )}
      </div>

      <div className="flex flex-col gap-4 bg-neutral30 py-4">
        {/* Dealer Onboarding Form */}
        <div className="lg:bg-neutral900" ref={DealersFormRef}>
          <DealerOnboarding />
        </div>

        {/* Submit Query */}
        <div className="p-4">
          <SubmitQuery />
        </div>

        {/* FAQs */}
        <div className="content-div flex flex-col gap-6 p-4">
          <FAQs content={DealersPageFAQsData} handleFAQClick={handleFAQClick} />
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>
    </>
  );
};

export default Dealer;
