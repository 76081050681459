import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { formatToIndianNumber } from "@/utils/numerics";
import React, { useEffect, useRef, useState } from "react";

const SelectPricing = ({
  vehicleListing,
  setVehicleListing,
  setSubmitClicked,
  submitClicked,
  setErrors,
  errors,
}: any) => {
  const purchasePriceRef = useRef<HTMLInputElement>(null);
  const refurbishmentCostRef = useRef<HTMLInputElement>(null);
  const sellingPriceRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState({
    purchase_price: vehicleListing?.steps?.pricing?.purchase_price || "",
    refurbishment_cost:
      vehicleListing?.steps?.pricing?.refurbishment_cost || "",
    selling_price: vehicleListing?.steps?.pricing?.selling_price || "",
  });

  const errorRefs = {
    purchase_price: purchasePriceRef,
    refurbishment_cost: refurbishmentCostRef,
    selling_price: sellingPriceRef,
  };

  useEffect(() => {
    if (!submitClicked) return;

    const errorField = Object.keys(errors).find((key) => errors[key]);
    if (errorField) {
      errorRefs[errorField as keyof typeof errorRefs].current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [submitClicked]);

  const invalidPurchasePrice =
    errors.purchase_price && errors.purchase_price !== "";
  const invalidRefurbishmentCost =
    errors.refurbishment_cost && errors.refurbishment_cost !== "";
  const invalidSellingPrice =
    errors.selling_price && errors.selling_price !== "";

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/g, "");

    setSubmitClicked(false);
    setErrors({ ...errors, [name]: "" });
    setFormData({ ...formData, [name]: formattedValue });

    setVehicleListing({
      ...vehicleListing,
      steps: {
        ...vehicleListing.steps,
        pricing: { ...formData, [name]: formattedValue },
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3">
        <p className="text-sm font-semibold">
          Vehicle Purchase Price <span className="text-red-500">*</span>
        </p>
        <div
          className={cn(
            "flex items-center bg-neutral30 py-1 pl-4 rounded-md",
            invalidPurchasePrice && "bg-red-100",
          )}
        >
          <p className="text-base font-medium text-neutral300">₹</p>
          <Input
            ref={purchasePriceRef}
            name="purchase_price"
            className={cn(
              "bg-neutral30 border-none px-2 focus-visible:ring-0 focus-visible:ring-offset-0",
              invalidPurchasePrice && "bg-red-100",
            )}
            maxLength={10}
            value={formatToIndianNumber(formData.purchase_price)}
            onChange={handleInputChange}
          />
        </div>
        {errors.purchase_price && (
          <p className="text-sm font-medium text-red-500">
            {errors.purchase_price}
          </p>
        )}
        <p className="text-sm font-medium text-neutral300">
          Add the price at which you acquired the <br className="md:hidden" />{" "}
          vehicle
        </p>
      </div>

      <div className="flex flex-col gap-3">
        <p className="text-sm font-semibold">
          Cost of Refurbishment <span className="text-red-500">*</span>
        </p>
        <div
          className={cn(
            "flex items-center bg-neutral30 py-1 pl-4 rounded-md",
            invalidRefurbishmentCost && "bg-red-100",
          )}
        >
          <p className="text-base font-medium text-neutral300">₹</p>
          <Input
            ref={refurbishmentCostRef}
            name="refurbishment_cost"
            className={cn(
              "bg-neutral30 border-none px-2 focus-visible:ring-0 focus-visible:ring-offset-0",
              invalidRefurbishmentCost && "bg-red-100",
            )}
            maxLength={10}
            value={formatToIndianNumber(formData.refurbishment_cost)}
            onChange={handleInputChange}
          />
        </div>
        {errors.refurbishment_cost && (
          <p className="text-sm font-medium text-red-500">
            {errors.refurbishment_cost}
          </p>
        )}
        <p className="text-sm font-medium text-neutral300">
          Add the amount it cost you to refurbish the{" "}
          <br className="md:hidden" /> vehicle
        </p>
      </div>

      <div className="flex flex-col gap-3">
        <p className="text-sm font-semibold">
          Selling Price <span className="text-red-500">*</span>
        </p>
        <div
          className={cn(
            "flex items-center bg-neutral30 py-1 pl-4 rounded-md",
            invalidSellingPrice && "bg-red-100",
          )}
        >
          <p className="text-base font-medium text-neutral300">₹</p>
          <Input
            ref={sellingPriceRef}
            name="selling_price"
            className={cn(
              "bg-neutral30 border-none px-2 focus-visible:ring-0 focus-visible:ring-offset-0",
              invalidSellingPrice && "bg-red-100",
            )}
            maxLength={10}
            value={formatToIndianNumber(formData.selling_price)}
            onChange={handleInputChange}
          />
        </div>
        {errors.selling_price && (
          <p className="text-sm font-medium text-red-500">
            {errors.selling_price}
          </p>
        )}
        <ul className="text-sm font-medium text-neutral300 list-disc pl-6">
          <li>This price will be shown on the website.</li>
          <li>You can edit the price afterwards</li>
          <li>Add the price inclusive of GST</li>
        </ul>
      </div>
    </div>
  );
};

export default SelectPricing;
